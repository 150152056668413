import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {CashFlow} from '../../data-type/input/CashFlow';
import {ValueType} from '../../data-type/input/ValueType';
import {ScenarioInput, ScenarioType} from '../../data-type/input/ScenarioInput';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DataService} from '../../service/data.service';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {Eventstamp} from '../../data-type/output/Eventstamp';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-arbeitspensum',
  templateUrl: './arbeitspensum.component.html',
  styleUrls: ['../scenario.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.5s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('0.3s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class ArbeitspensumComponent implements OnInit {
  @ViewChild('valueButton', {static: false}) valueButton: ElementRef;
  @ViewChild('percentButton', {static: false}) percentButton: ElementRef;

  /*Data inputs & Outputs*/

  arbeitspensumData: CashFlow = {
    date: null,
    value: 0,
    valueType: ValueType.ABSOLUTE,
    atPension: false
  };
  scenario: ScenarioInput = {
    type: ScenarioType.PensumReduction,
    date: null,
    id: null,
    cashflows: []
  };
  withdrawal: CashFlow = {
    date: null,
    value: 0,
    valueType: ValueType.ABSOLUTE,
    atPension: false,
    atPartPension: true
  };

  /*HTML Elements*/

  enablePartialRetirement = false;
  earlyRetirementBool = false;
  /*Checks if early Retirement has already been set */
  earlyRetirementAge = this.dataService.getData().pensionAge;
  /*Displays age, if earlyRetirementhas been selected in EinkaufComponent*/
  advancedSettings = false;
  partialRetirement = false;
  salaryChange = false;
  tpDateValid: boolean;
  latestPensum: number;
  secondTP = false;

  minDate: Date;
  maxDate: Date;

  /*Errors*/
  dateIsValid: boolean;
  datecheckerror = false;
  errorInformationDate = 'init';

  /*Backend Communication*/
  testValidationList: Eventstamp[];

  /*Variables*/
  pensionYears: number[] = [];
  maxKapitalBezug: number;
  isPercentage = false;
  maxSliderValue: number;
  pending: boolean;
  withdrawalPossible = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataService: DataService,
    public dialogMethods: DialogMethodsService) {
  }


  ngOnInit() {
    /*Sets the available Dates to pick from in the Datepicker*/
    this.latestPensum = this.dataService.latestPensum[this.dataService.latestPensum.length - 1];
    this.arbeitspensumData.pensum = this.latestPensum;

    const minDateArr: string[] = this.dataService.getHighestDate().toString().split('-');
    if (this.dataService.tpCount > 0) {
      /*only one TP per year*/
      const scenarioArr = this.dataService.getScenarios();
      for (const i of scenarioArr) {
        const checkArr: string[] = i.cashflows[0].date.toString().split('-');
        if (+checkArr[0] === +minDateArr[0] && (i.type === ScenarioType.PartRetirement || i.type === ScenarioType.PartRetirementBezug)) {
          this.secondTP = true;
          break;
        }
      }
    }
    this.minDate = new Date(+minDateArr[0], +minDateArr[1] - 1, +minDateArr[2]);
    const maxDateArr: string[] = this.dataService.getData().dateOfBirth.toString().split('-');
    this.maxDate = new Date(+maxDateArr[0] + +this.dataService.getPensionAge(), +maxDateArr[1] - 1, 0);

    // only one arbeitspensumszenario per year
    if (this.dataService.latestTPdate.length > 0 && this.dataService.latestTPdate[this.dataService.latestTPdate.length - 1] != null) {
      const year = parseInt(this.dataService.latestTPdate[this.dataService.latestTPdate.length - 1].split('-')[0], 10);
      this.minDate = new Date(year + 1, 0, 1);
    }
    if (this.dataService.getData().pensionAge < 65) {
      this.earlyRetirementBool = true;
    }

    if (this.earlyRetirementAge !== null) {
      for (let i = this.earlyRetirementAge; i < 71; i++) {
        this.pensionYears.push(i);
      }
    }

    this.dataService.testValidationList.subscribe((output: any) => {
      this.updateMaxValue(output);
    });
  }

  fixValidation() {
    /*not sure why this needs to be here*/
    if (!this.arbeitspensumData.salaryChange) {
      this.arbeitspensumData.salaryChange = 0;
    }
  }

  updateMaxValue(validationList): void {
    /*updates the maximum withdrawable amount of money, activated by "checkDate" through "checkforPartialPension",
      "dataService.seTestScenario, "getTestValidationList" and the testValidation or by reopen on init */
    this.testValidationList = validationList.eventstamps;
    this.maxKapitalBezug = Math.floor(-this.testValidationList[this.dataService.cashflowCount].absoluteMaxValue);
    if (this.maxKapitalBezug < 0) {
      this.errorInformationDate = 'noCapital';
      this.datecheckerror = true;
      this.maxKapitalBezug = 0;
    }
    this.changeMode(this.isPercentage);
  }

  checkForPartialPension(): void {
    /*checks if a partial Retirement is possible and sends testBezug to the backend to get the maximum withdrawable amount of money*/
    if (this.arbeitspensumData.pensum > 100) {
      this.arbeitspensumData.pensum = 100;
    }
    const prevPensum = this.latestPensum;
    if (prevPensum - +this.arbeitspensumData.pensum >= 20 && this.tpDateValid === true
      && this.arbeitspensumData.pensum >= 30 && this.dataService.tpCount <= 2) {
      this.enablePartialRetirement = true;
      const relativePensum = (this.arbeitspensumData.pensum) / prevPensum;
      this.withdrawalPossible = prevPensum - +this.arbeitspensumData.pensum >= 30;
      const testBezug: ScenarioInput = {
        type: ScenarioType.PartRetirementBezug,
        date: this.arbeitspensumData.date,
        id: null,
        cashflows: [{
          date: this.arbeitspensumData.date,
          value: -1,
          valueType: ValueType.ABSOLUTE,
          atPension: false,
          pensum: relativePensum,
        }]
      };
      this.dataService.setTestScenario(testBezug);

    } else {
      this.enablePartialRetirement = false;
    }
  }

  isValid(input: string): boolean {
    /*execute core validations*/
    const coreValidations = this.dataService.dateValidator(input);
    /*returns [dateError: bool, errorInfo: string, dateArr: number[], highestDateArr: number[], dateOfBirtArr: number[], arguable: bool]*/
    let isValid: boolean = coreValidations[0];
    this.errorInformationDate = coreValidations[1];
    const dateArr: number[] = coreValidations[2];
    const highestDateArr: number[] = coreValidations[3];
    const birthDateArr: number[] = coreValidations[4];
    const arguable: boolean = coreValidations[5];


    /*custom Validations*/
    const date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
    const pensionDate = this.maxDate;
    const tpDate = new Date(birthDateArr[0] + 58, birthDateArr[1] - 1, birthDateArr[2] - 1);

    if (arguable === true) {
      if (date > pensionDate) {
        this.errorInformationDate = 'maxDate';
        isValid = false;
      }
      if (dateArr[0] > highestDateArr[0] && this.secondTP === true) {
        this.secondTP = false;
        isValid = true;
      }
      this.tpDateValid = tpDate < date;
    }

    return isValid;
  }

  logData() {
    this.dataService.latestPensum.push(+this.arbeitspensumData.pensum);
    this.arbeitspensumData.pensum = this.arbeitspensumData.pensum / this.latestPensum;

    if (this.salaryChange === false) {
      this.arbeitspensumData.salaryChange = 0;
    }
    if (this.partialRetirement === true) {
      this.scenario.type = ScenarioType.PartRetirement;
      this.arbeitspensumData.value = -1;
      this.arbeitspensumData.valueType = ValueType.PERCENTAGE;
      if (this.isPercentage) {
        this.withdrawal.valueType = ValueType.PERCENTAGE;
      }
      this.withdrawal.atPartPension = true;
    } else {
      this.arbeitspensumData.value = 0;
    }
    this.scenario.cashflows[0] = this.arbeitspensumData;
    this.scenario.date = this.arbeitspensumData.date;
    if (Math.abs(this.withdrawal.value) !== 0) {
      this.scenario.type = ScenarioType.PartRetirementBezug;
      if (this.isPercentage) {
        this.withdrawal.value = -(this.dataService.correctNumbers(this.withdrawal.value) / 100) * this.maxKapitalBezug;
      } else {
        this.withdrawal.value = -this.dataService.correctNumbers(this.withdrawal.value);
      }
      this.withdrawal.date = this.arbeitspensumData.date;
      this.withdrawal.valueType = ValueType.ABSOLUTE;
      this.scenario.cashflows[1] = {
        value: this.withdrawal.value,
        date: this.withdrawal.date,
        valueType: this.withdrawal.valueType,
        atPartPension: this.withdrawal.atPartPension,
        atPension: this.withdrawal.atPension
      };
    }
    if (this.scenario.type === ScenarioType.PartRetirement || this.scenario.type === ScenarioType.PartRetirementBezug) {
      this.dataService.tpCount++;
    }
    this.dataService.setScenario(this.scenario);
  }

  checkDate(e) {
    this.pending = true;
    if (!this.isValid(e.value)) {
      this.dateIsValid = false;
      this.datecheckerror = true;
    } else {
      this.datecheckerror = false;
      this.dateIsValid = true;
    }
    if (this.arbeitspensumData.pensum) {
      this.checkForPartialPension();
    }
  }

  changeMode(mode: boolean) {
    if (this.isPercentage !== mode) {
      this.isPercentage = mode;
      if (mode) {
        this.withdrawal.value = Math.round(this.withdrawal.value * 100 / this.maxKapitalBezug);
        this.maxSliderValue = 100;
        return;
      } else {
        this.withdrawal.value = Math.round((this.withdrawal.value / 100) * this.maxKapitalBezug);
        this.maxSliderValue = this.maxKapitalBezug;
        return;
      }
    }
  }

  changeValue() {
    this.isPercentage = !this.isPercentage;
  }

  pitch(e) {
    this.arbeitspensumData.pensum = e;
    this.checkForPartialPension();
  }
}
