import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {PlatformLocation} from '@angular/common';
import {CashFlow} from '../../data-type/input/CashFlow';
import {ValueType} from '../../data-type/input/ValueType';
import {DataService} from '../../service/data.service';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {ScenarioInput, ScenarioType} from '../../data-type/input/ScenarioInput';
import {PensionPlan} from '../../data-type/input/PensionPlan';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['../scenario.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.5s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('0.3s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class PlanComponent implements OnInit {
  french = false;
  plans: String[] = ['Standard', 'Plus', 'Surplus'];
  years: number[] = [0, 1, 2];
  selectedPlan;
  selectedYear;

  planChange: CashFlow = {
    value: 0,
    date: null,
    valueType: ValueType.ABSOLUTE,
    atPension: false,
    plan: null
  };

  latestPlan = this.dataService.latestPlan[this.dataService.latestPlan.length - 1];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private platformLocation: PlatformLocation,
              public dataService: DataService,
              public dialogMethodsService: DialogMethodsService) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
  }

  ngOnInit() {
    if (this.latestPlan === PensionPlan.STANDARD_BEFORE || this.latestPlan === PensionPlan.STANDARD_AFTER) {
      this.plans = ['Standard Plus', 'Standard Surplus'];
    } else if (this.latestPlan === PensionPlan.PLUS_BEFORE || this.latestPlan === PensionPlan.PLUS_AFTER) {
      this.plans = ['Standard', 'Standard Surplus'];
    } else {
      this.plans = ['Standard', 'Standard Plus'];
    }
    const latestYear = parseInt(this.dataService.getHighestDate().split('-')[0], 10) + 1;
    const latestAge = this.dialogMethodsService.calculateAgeAtDate(
      this.dataService.getData().dateOfBirth, this.dataService.getHighestDate()
    );
    const pensionAge = this.dataService.getPensionAge();
    this.years = [];
    let i;
    for (i = 0; i < pensionAge - latestAge + 1; i++) {
      this.years.push(latestYear + i);
    }

  }

  logdata() {
    const date = new Date(this.selectedYear, 0, 1);
    const scenario: ScenarioInput = {
      type: ScenarioType.PlanChange,
      date: date,
      id: null,
      cashflows: []
    };
    let plan: PensionPlan;
    if (this.latestPlan.toString().endsWith('BEFORE')) {
      if (this.selectedPlan.toString().endsWith('Surplus')) {
        plan = PensionPlan.SURPLUS_BEFORE;
      } else if (this.selectedPlan.toString().endsWith('Plus')) {
        plan = PensionPlan.PLUS_BEFORE;
      } else {
        plan = PensionPlan.STANDARD_BEFORE;
      }
    } else {
      if (this.selectedPlan.toString().endsWith('Surplus')) {
        plan = PensionPlan.SURPLUS_AFTER;
      } else if (this.selectedPlan.toString().endsWith('Plus')) {
        plan = PensionPlan.PLUS_AFTER;
      } else {
        plan = PensionPlan.STANDARD_AFTER;
      }
    }
    this.planChange.date = date;
    this.planChange.plan = plan;
    this.dataService.latestPlan.push(plan);

    scenario.cashflows[0] = this.planChange;
    this.dataService.setScenario(scenario);
  }

}
