import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material';
import {EinkaufComponent} from '../einkauf/einkauf.component';
import {WEFComponent} from '../wef/wef.component';
import {ArbeitspensumComponent} from '../arbeitspensum/arbeitspensum.component';
import {PensionierungComponent} from '../pensionierung/pensionierung.component';
import {DataService} from '../../service/data.service';
import {PlanComponent} from '../plan/plan.component';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-scenario-selection',
  templateUrl: './scenario-selection.component.html',
  styleUrls: ['./scenario-selection.component.css', '../dialog.css'],
})
export class ScenarioSelectionComponent implements OnInit {
  french = false;
  disabledEinkaufText: string;
  disabledArbeitText: string;
  disabledPensionierungText: string;
  disabledWEFText: string;
  disabledPlanText: string;
  disabledEinkauf = false;
  disabledArbeit = false;
  disabledPensionierung = false;
  disabledWEF = false;
  disabledPlan = false;
  @ViewChild('set2', {static: false}) set2: ElementRef;
  @ViewChild('set6', {static: false}) set6: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public matDialog: MatDialog,
              public dataService: DataService,
              private platformLocation: PlatformLocation
  ) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
  }

  ngOnInit(): void {
    this.disabledEinkauf = this.dataService.saldoWEF < 0;
    if (this.disabledEinkauf) {
      this.disabledEinkaufText = 'Sie haben einen WEF-Vorbezug getätigt und können somit keine Einkäufe vornehmen.' +
        ' Damit Sie einen Einkauf vornehmen können, müssen Sie alle WEF-Vorbezüge löschen oder WEF-Rückzahlungen tätigen.';
      if (this.french) {
        this.disabledEinkaufText = 'Vous avez effectué un retrait anticipé d\'APL et ne pouvez donc effectuer aucun achat. ' +
          'Pour effectuer un achat, vous devez supprimer tous les retraits d\'APL ou effectuer des remboursements d\'APL.';
      }
    }
    if (this.dataService.eventList[0]) {
      for (let i = 0; i < this.dataService.eventList.length; i++) {
        if (this.dataService.eventList[i].scenario[0].type === 5) {
          this.disabledEinkauf = true;
          this.disabledArbeit = true;
          this.disabledPensionierung = true;
          this.disabledWEF = true;
          this.disabledPlan = true;

          const textGerman = 'Dieses Modul kann nicht mehr ausgewählt werden. Löschen Sie alle bereits ' +
            'erfassten - später stattfindenden Massnahmen, um dieses Eingabefeld zu bearbeiten.';
          const textFrench = 'Ce module ne peut plus être sélectionné. Pour modifier cette zone de saisie,' +
            ' supprimez toutes les actions déjà programmées ainsi que celles qui seront effectuées ultérieurement.';

          if (!this.french) {

            this.disabledEinkaufText = textGerman;
            this.disabledArbeitText = textGerman;
            this.disabledPensionierungText = 'Sie haben bereits den Zeitpunkt für Ihre vorzeitigen Pensionierung, den ' +
              'Rentenaufschub sowie den Kapitalbezug angegeben. Dieses Szenario können Sie somit nicht noch einmal durchspielen.';
            this.disabledWEFText = textGerman;
            this.disabledPlanText = textGerman;
          } else {
            this.disabledEinkaufText = textFrench;
            this.disabledArbeitText = textFrench;
            this.disabledPensionierungText = 'Vous avez déjà saisi la date de votre retraite anticipée,' +
              ' de l\'ajournement de votre rente et de votre retrait en capital. Vous ne pouvez donc plus exécuter ce scénario.';
            this.disabledWEFText = textFrench;
            this.disabledPlanText = textFrench;
          }
          i = this.dataService.eventList.length + 1;
        } else {
          if (this.dataService.saldoWEF === 0) {
            this.disabledEinkauf = false;
            this.disabledEinkaufText = null;
          }
          this.disabledArbeit = false;
          this.disabledPensionierung = false;
          this.disabledWEF = false;
          this.disabledArbeitText = null;
          this.disabledPensionierungText = null;
          this.disabledWEFText = null;
        }
      }
    }
  }

  openEinkauf() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      reopen: false,
      scenario: null
    };
    this.matDialog.open(EinkaufComponent, dialogConfig);
  }

  openWEF() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      reopen: false,
      scenario: null
    };
    this.matDialog.open(WEFComponent, dialogConfig);
  }

  openArbeit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      reopen: false,
      scenario: null
    };
    this.matDialog.open(ArbeitspensumComponent, dialogConfig);
  }

  openPensionierung() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      reopen: false,
      scenario: null
    };
    this.matDialog.open(PensionierungComponent, dialogConfig);
  }

  openPlan() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      reopen: false,
      scenario: null
    };
    this.matDialog.open(PlanComponent, dialogConfig);
  }
}
