<div class="dialog">
  <div class="container">
    <h2 mat-dialog-title>
      <span *ngIf="wefMode === 'withdrawal'" i18n="dialog title|The title for this dialog@@wefTitle">WEF</span>
      <span *ngIf="wefMode === 'buyin'" i18n="alternate dialog title|The alternate title for this dialog@@wefTitleAlt">WEF-Rückzahlung</span>
      <button mat-button mat-dialog-close class="rightBound">
        <i class="material-icons rightBound">clear</i>
      </button>
    </h2>
    <hr>
    <span i18n="dialog description|The description for this dialog@@wefDescription">Sie haben die Möglichkeit, privates Wohneigentum mit Ihrem Sparguthaben bei der Pensionskasse zu finanzieren (WEF-Vorbezug). Dadurch reduzieren sich Ihre Altersleistungen dauerhaft. Bitte füllen Sie alle Felder aus und klicken Sie auf «<b>Speichern</b>». </span>


    <form #wefForm="ngForm">
      <table>
        <tr class="mat-row" *ngIf="saldoWEF < 0">
          <td class="mat-cell" i18n="dialog wefWhichOption|first question in wef dialog@@wefWhichOption">Was möchten Sie
            tun?
          </td>
          <td class="mat-cell"></td>
          <td class="mat-cell">
          </td>
          <td class="mat-cell" align="right">
            <mat-radio-group [(ngModel)]="wefMode" name="wefMode" ngModel>
              <mat-radio-button value="withdrawal" color="primary"
                                (click)="sliderValueAtExtremes('withdrawal'); dateCheck()"
                                i18n="dialog wefVorbezug|first option in wef@@wefVorbezug">Vorbezug
              </mat-radio-button>
              <mat-radio-button value="buyin" color="primary" (click)="sliderValueAtExtremes('buyin'); dateCheck()"
                                i18n="dialog wefRückzahlung|second option in wef@@wefRückzahlung">
                Rückzahlung
              </mat-radio-button>
            </mat-radio-group>
          </td>
        </tr>
        <tr class="mat-row" *ngIf="wefMode" [@inOutAnimation]>
          <td>
            <span *ngIf="wefMode === 'withdrawal'"
                  i18n="dialog wefWhenVorbezug|second question in wef dialog@@wefWhenVorbezug">
              Wann möchten Sie den Vorbezug tätigen?
            </span>
            <span *ngIf="wefMode === 'buyin'"
                  i18n="dialog wefWhenRückzahlung|third question in wef dialog@@wefWhenRückzahlung">
              Wann möchten Sie die Rückzahlung tätigen?
            </span>
          </td>
          <td class="mat-cell">
            <mat-icon *ngIf="wefMode === 'withdrawal'" class="coloredIcon"
                      i18n-matTooltip="wef tooltipUpToThreeYearsBefore|first optional tooltip in wef@@wefTooltipUpToThreeYearsBefore"
                      matTooltip="Sie können bis drei Jahre vor der ordentlichen Pensionierung Ihr Spargutgaben für den Erwerb von Wohneigentum verwenden. Leistungen, die innerhalb von drei Jahren nach einem Einkauf als Kapitalleistungen (inkl. WEF-Vorbezug) bezogen werden, können zu steuerlichen Konsequenzen führen. Die Verantwortung für die Abklärung der steuerlichen Konsequenzen von Einkäufen und Kapitalbezügen liegt beim Versicherten.">
              info
            </mat-icon>
            <mat-icon *ngIf="wefMode === 'buyin'" class="coloredIcon"
                      i18n-matTooltip="wef tooltipUpToThreeYearsBefore|first optional tooltip in wef@@wefTooltipUpToThreeYearsBeforeTwo"
                      matTooltip="Reglementarische Bestimmungen zur WEF-Rückzahlung finden Sie auf der Homepage der PK Siemens. Eine Rückzahlung kann bis zum Alter 62 getätigt werden.">
              info
            </mat-icon>
          </td>
          <td class="mat-cell" *ngIf="wefMode == 'withdrawal'" [ngSwitch]="errorInformationDate">
            <mat-icon *ngIf="datecheckerror && wefMode === 'withdrawal'" matTooltip="{{errorInformationDate}}"
                      class="warningButton">
              warning
            </mat-icon>
            <mat-icon *ngSwitchCase="'maxDate'"
                      i18n-matTooltip="wef tooltipMinThreeYearsBefore|third optional tooltip in wef@@wefTooltipMinThreeYearsBefore"
                      matTooltip="WEF-Bezug muss mindestens 3 Jahre vor der Pensionierung stattfinden."
                      class="warningButton">
              warning
            </mat-icon>
            <mat-icon *ngSwitchCase="'5yearsRule'"
                      i18n-matTooltip="wef tooltipMinFiveYears|fourth optional tooltip in wef@@wefTooltipMinFiveYears"
                      matTooltip="Das gewählte Datum muss mehr als fünf Jahre zum bereits vorgenommenen WEF-Vorbezug betragen. Bitte passen Sie den Zeitpunkt entsprechend an."
                      class="warningButton">
              warning
            </mat-icon>
            <mat-icon *ngSwitchCase="'chronology'"
                      i18n-matTooltip="wef tooltipChronologicalHurt|fifth optional tooltip in wef@@wefTooltipChronologicalHurt"
                      matTooltip="Der Zeitpunkt Ihrer gewünschten BVG-Massnahme verletzt die chronologische Reihenfolge. Passen Sie entweder das Datum dieser Massnahme an oder löschen Sie alle bereits erfassten – später stattfindenden – Szenarien."
                      class="warningButton">
              warning
            </mat-icon>
            <mat-icon *ngIf="WEFError"
                      i18n-matTooltip="wef tooltipNoMoney|sixth optional tooltip in wef@@wefTooltipNoMoney"
                      matTooltip="Zu wenig Kapital für WEF vorhanden." class="warningButton">
              warning
            </mat-icon>
          </td>

          <td class="mat-cell" *ngIf="wefMode == 'buyin'">

          </td>
          <td class="mat-cell" align="right">
            <mat-form-field *ngIf="wefMode == 'withdrawal'">
              <input matInput name="wefDatum" #ref (dateChange)="dateCheck(ref)" [max]="maxDate" [min]="minDateW"
                     [matDatepicker]="secondPickerW" [(ngModel)]="wefData.date"
                     i18n-placeholder="datepicker|datepicker in wef@@datePicker1WEF" placeholder="dd.mm.yyyy" required>
              <mat-datepicker-toggle matSuffix [for]="secondPickerW"></mat-datepicker-toggle>
              <mat-datepicker #secondPickerW></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="wefMode == 'buyin'">
              <input matInput name="wefDatum" #ref (dateChange)="dateCheck(ref)" [max]="maxDate" [min]="minDateB"
                     [matDatepicker]="secondPickerB" [(ngModel)]="wefData.date"
                     i18n-placeholder="datepicker|datepicker in wef@@datePicker2WEF" placeholder="dd.mm.yyyy" required>
              <mat-datepicker-toggle matSuffix [for]="secondPickerB"></mat-datepicker-toggle>
              <mat-datepicker #secondPickerB></mat-datepicker>
            </mat-form-field>
          </td>
        </tr>


        <tr class="mat-row" *ngIf="ageAtInsuranceCardDate > 50 && wefMode === 'withdrawal'" [@inOutAnimation]>
          <td class="mat-cell" i18n="dialog wefVestedBenefits|fourth question in wef dialog@@wefVestedBenefits">
            Wie gross ist der zur Verfügung stehende Betrag für WEF?
          </td>
          <td class="mat-cell">
            <mat-icon class="coloredIcon"
                      i18n-matTooltip="einkauf tooltipNormalPensionAge|second tooltip in einkauf@@einkaufTooltipNormalPensionAge"
                      matTooltip="Das ordentliche Pensionierungsalter ist 65 Jahre. Sie können sich jedoch für eine vorzeitige Pensionierung bis zum Alter 58 entscheiden.
                    Reglementarische Bestimmungen über den Zeitpunkt der Pensionierung finden Sie auf der Homepage der PK Siemens.">
              info
            </mat-icon>
          </td>
          <td class="mat-cell">
            <mat-icon i18n-matTooltip="wef tooltipWritten|first obligatory tooltip in wef@@wefTooltipWritten"
                      matTooltip="Diesen Wert finden Sie auf dem Vorsorgeausweis unter Punkt 2a."
                      class="coloredIcon"
            >
              info
            </mat-icon>
          </td>
          <td class="mat-cell" align="right">
            <input matInput placeholder="CHF" name="vestedBenefit" (blur)="this.dialogMethods.thousandSeparator($event)"
                   (keyup)="this.dialogMethods.thousandSeparator($event)" [ngModel]="vestedBenefit"
                   [required]="ageAtInsuranceCardDate > 50 && wefMode === 'withdrawal'"
                   (ngModelChange)="pitchFZL($event)" pattern="[0-9',.]{0,}">
          </td>
        </tr>

        <tr class="mat-row"
            *ngIf="(maxWEF !== 20000 || (saldoWEF < 0 && wefMode === 'buyin')) && datecheckerror === false && !WEFError"
            [@inOutAnimation]>
          <td class="mat-cell">
            <span *ngIf="wefMode === 'withdrawal'"
                  i18n="dialog wefVorbezugAmount|fifth question in wef dialog@@wefVorbezugAmount">
              Wie hoch soll der Vorbezug sein?
            </span>
            <span *ngIf="wefMode === 'buyin'"
                  i18n="dialog wefRückzahlungAmount|sixth question in wef dialog@@wefRückzahlungAmount">
              Wie hoch soll die Rückzahlung sein?
            </span>
          </td>
          <td class="mat-cell">
            <mat-icon *ngIf="wefMode === 'buyin'"
                      i18n-matTooltip="wef tooltipUpToFifty|seventh optional tooltip in wef@@wefTooltipUpToFifty"
                      matTooltip="Bis Alter 50: Sie können Ihr gesamtes Sparguthaben vorbeziehen. Ab Alter 50: Sie können höchstens das Sparguthaben im Alter 50 beziehen, oder die Hälfte der aktuellen Austrittsleistung. Mindestbetrag CHF 20'000."
                      class="coloredIcon"
            >
              info
            </mat-icon>
            <mat-icon *ngIf="wefMode === 'withdrawal'"
                      i18n-matTooltip="wef tooltip Werte Vorsorgeausweis|hinweis vorsorgeausweis@@hinweisVorsorgeausweisWEF"
                      matTooltip="Bitte beachten Sie, dass der WEF-Bezug im Rentenrechner stichtaggenau berechnet wird, während im Vorsorgeausweis Werte per 1. Januar ausgewiesen werden."
                      class="coloredIcon"
            >
              info
            </mat-icon>
          </td>
          <td class="mat-cell">
          </td>
          <td class="mat-cell" align="right">
            <app-slider *ngIf="wefMode === 'withdrawal'" [min]="minWEF" [max]="maxWEF" [(value)]="wefData.value"
                        [isPercentage]="isPercentage"></app-slider>
            <app-slider *ngIf="wefMode === 'buyin'" [min]="minWEF" [max]="maxWEFbuyin" [(value)]="wefData.value"
                        [isPercentage]="isPercentage"></app-slider>
          </td>
        </tr>

      </table>

    </form>


    <app-save-button [disabledCondition]="!wefForm.valid || datecheckerror || WEFError || !dateChecked"
                     [form]="wefForm"
                     (firedEvent)="logdata()"
    ></app-save-button>


  </div>
</div>
