import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DataService} from '../../service/data.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css', '../dialog.css']
})
export class ErrorMessageComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService) {
  }

}
