import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../service/data.service';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.css']
})
export class SaveButtonComponent implements OnInit {

  @Input() disabledCondition = false;
  @Input() form;
  @Input() dialog = true;

  @Output() firedEvent = new EventEmitter();

  constructor(
    public dataService: DataService
  ) {
  }

  ngOnInit() {
  }

  fireEvent() {
    this.firedEvent.emit();
  }

}
