import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DataService} from '../../service/data.service';
import {CashFlow} from '../../data-type/input/CashFlow';
import {ValueType} from '../../data-type/input/ValueType';
import {ScenarioInput, ScenarioType} from 'src/app/data-type/input/ScenarioInput';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {Eventstamp} from '../../data-type/output/Eventstamp';
import {animate, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-pensionierung',
  templateUrl: './pensionierung.component.html',
  styleUrls: ['../scenario.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.5s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('0.3s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class PensionierungComponent implements OnInit {
  @ViewChild('valueButton', {static: false}) 'valueButton': ElementRef;
  @ViewChild('percentButton', {static: false}) 'percentButton': ElementRef;
  scenario: ScenarioInput = {
    type: ScenarioType.Retirement,
    date: null,
    id: null,
    cashflows: []
  };
  pensionError = false;
  earlyRetirement = false;
  pensionAge: number;
  highestAge = this.dialogMethods.calculateAgeAtDate(this.dataService.getData().dateOfBirth, this.dataService.highestDate) < 58 ?
    58 : this.dialogMethods.calculateAgeAtDate(this.dataService.getData().dateOfBirth, this.dataService.highestDate);
  pensionYears: number[] = [];
  kapitalBezug: boolean;
  selector = null;
  maxKapitalBezug: number = null;
  minimumValue = 0;
  isPercentage = false;
  // hack below, because required property doesnt seem to work (was required even with required set to "false")
  maxSliderKapitalBezug: number = null;
  pensionierungsDataCashflow: CashFlow = {
    date: null,
    value: 0,
    valueType: ValueType.ABSOLUTE,
    atPension: true
  };
  continue = false;
  testValidationList: Eventstamp[];
  bezugError = false;
  errorInformationBezug: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService, public dialogMethods: DialogMethodsService) {
  }

  ngOnInit() {
    this.pensionAge = JSON.parse(JSON.stringify(this.dataService.getPensionAge()));
    this.checkPensionAge();
    this.kapitalBezugValidation();
    this.maxSliderKapitalBezug = 100;
    for (let i = this.highestAge; i <= 70; i++) {
      this.pensionYears.push(i);
    }

    this.dataService.testValidationList.subscribe(output => {
      this.updateMaxValue(output);
    });
  }

  logdata() {
    this.pensionierungsDataCashflow.value = this.dataService.correctNumbers(this.pensionierungsDataCashflow.value);
    if (this.isPercentage) {
      this.pensionierungsDataCashflow.valueType = ValueType.PERCENTAGE;
      this.pensionierungsDataCashflow.value = -this.pensionierungsDataCashflow.value / 100;
    } else {
      this.pensionierungsDataCashflow.valueType = ValueType.ABSOLUTE;
      this.pensionierungsDataCashflow.value = -this.pensionierungsDataCashflow.value;
    }

    this.scenario.date = this.calculateDate(this.pensionAge);
    this.pensionierungsDataCashflow.date = this.calculateDate(this.pensionAge);
    this.scenario.cashflows = [{ // create new cashflow, else values might change
      atPension: this.pensionierungsDataCashflow.atPension,
      value: this.pensionierungsDataCashflow.value,
      date: this.pensionierungsDataCashflow.date,
      valueType: this.pensionierungsDataCashflow.valueType
    }];

    this.dataService.setPensionAge(this.pensionAge);
    this.dataService.setScenario(this.scenario);
  }

  kapitalBezugValidation() {
    const testCF: CashFlow[] = [{
      date: this.calculateDate(this.pensionAge),
      value: -1,
      valueType: ValueType.ABSOLUTE,
      atPension: true
    }];
    const testScenario: ScenarioInput = {
      type: ScenarioType.Retirement,
      date: testCF[0].date,
      cashflows: testCF,
      id: null
    };
    this.dataService.setTestScenario(testScenario, this.pensionAge);
  }

  updateMaxValue(validationList) {
    this.testValidationList = validationList.eventstamps;
    this.maxKapitalBezug = -Math.floor(this.testValidationList[this.dataService.cashflowCount].absoluteMaxValue);
    if (this.maxKapitalBezug <= 0) {
      this.errorInformationBezug = 'Kein Kapital für Bezug vorhanden';
      this.bezugError = true;
      this.maxKapitalBezug = 0;
    }
  }

  checkPensionAge() {
    if (this.pensionAge < 65) {
      this.earlyRetirement = true;
      this.continue = true;
    } else {
      this.earlyRetirement = false;
      this.continue = false;
    }
  }

  calculateDate(age: number) {
    if (typeof age !== 'number') {
      age = parseInt(age, 10);
    }
    const date = this.dialogMethods.correctDate(this.dataService.getData().dateOfBirth);
    const year = parseInt(date.split('-')[0], 10) + age;
    return year.toString() + date.substring(4, 8) + '01';
  }

  changeValue() {
    this.isPercentage = !this.isPercentage;
  }
}
