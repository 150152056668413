import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogMethodsService {

  constructor() {
  }

  public thousandSeparator(e): void {
    e.srcElement.value = this.thousandSeparatorWithReturn(e.srcElement.value);
  }

  // From Roger... needs refactoring .. we can modularize these methods!
  public thousandSeparatorWithReturn(number) {
    // test performance, but automatically removes all non numbers (\D means "non digit")
    // replace(/\'/g, '') replaces ALL the ' characters (g = regex "global")
    let numberAsString = number.toString().replace(/\D+/g, '');
    while (numberAsString.charAt(0) === '0' && numberAsString.length > 1) {
      numberAsString = numberAsString.substr(1);
    }
    for (let i = 0; i < Math.floor((numberAsString.length - 1) / 3); i++) {
      const index = -(4 * i + 3);
      if (Math.abs(index) < numberAsString.length) {
        numberAsString = numberAsString.slice(0, index) + '\'' + numberAsString.slice(index, numberAsString.length);
      }
    }
    if (numberAsString === 'NaN') {
      return '0';
    }
    return numberAsString;
  }

  public projectionInterestCorrection(e) {
    let numberAsString = e.srcElement.value.toString().replace(/[^.0123456789]/g, '');
    const stringAsArray = numberAsString.split('.');
    if (stringAsArray[1] && stringAsArray[1].length > 2) {
      numberAsString = numberAsString.slice(0, -(stringAsArray[1].length - 2));
    }
    if (numberAsString > 10) {
      e.srcElement.value = numberAsString;
      return false;
    }
    e.srcElement.value = numberAsString;
    return true;
  }

  formatTable(input): string {
    input = Math.floor(input);
    input = input.toString();
    for (let i = 0; i < Math.floor((input.length - 1) / 3); i++) {
      const index = -(4 * i + 3);
      if (Math.abs(index) < input.length) {
        input = input.slice(0, index) + '\'' + input.slice(index, input.length);
      }
    }
    return input;
  }

  formatPercentForDisplay(input: number): string {
    const string = Math.round(input * 100) / 100;
    return string.toString();
  }


  correctDate(date): String {
    if (date) {
      if (typeof date === 'string' && date.indexOf('.') !== -1) {
        const dateArray = date.split('.');
        if (dateArray[1].length < 2) {
          dateArray[1] = '0' + dateArray[1];
        }
        if (dateArray[0].length < 2) {
          dateArray[0] = '0' + dateArray[0];
        }
        date = dateArray[2] + dateArray[1] + dateArray[0];
        return date;
      } else if (typeof date === 'string' && date.indexOf('-') !== -1) {
        return date;
      } else if (date.toString().split('-').length !== 3) {
        const input = date.toString().split(' ');
        let dayValue;
        let monthValue;
        const yearValue = input[3];
        switch (input[1]) {
          case 'Jan':
            monthValue = '01';
            break;
          case 'Feb':
            monthValue = '02';
            break;
          case 'Mar':
            monthValue = '03';
            break;
          case 'Apr':
            monthValue = '04';
            break;
          case 'May':
            monthValue = '05';
            break;
          case 'Jun':
            monthValue = '06';
            break;
          case 'Jul':
            monthValue = '07';
            break;
          case 'Aug':
            monthValue = '08';
            break;
          case 'Sep':
            monthValue = '09';
            break;
          case 'Oct':
            monthValue = '10';
            break;
          case 'Nov':
            monthValue = '11';
            break;
          case 'Dec':
            monthValue = '12';
            break;
        }
        if (input[2]) {
          if (input[2].length === 1) {
            dayValue = '0' + input[2];
          } else {
            dayValue = input[2];
          }
        } else {
        }
        date = yearValue + '-' + monthValue + '-' + dayValue;
        return date;
      } else {
        return '';
      }
    }
  }

  calculateAgeAtDate(dateOfBirth, date): number {
    date = this.correctDate(date);
    dateOfBirth = this.correctDate(dateOfBirth);
    if (dateOfBirth) {
      return parseInt(date.substring(0, 4), 10) - parseInt(dateOfBirth.substring(0, 4), 10);
    }
  }

  formatPercent(number: number): number {
    return Math.round(number * 100) / 100;
  }

  toNumber(string: string): number {
    return parseFloat(string.replace('\'', ''));
  }

  dateBefore1966(date): Boolean { // used for singlePremium validation
    if (date == null) {
      return false;
    }
    date = this.correctDate(date);
    return parseInt(date.toString().split('-')[0], 10) < 1966;
  }
}
