<div class="dialog">
  <h2 mat-dialog-title class="header" i18n="dialog title|The title for this dialog@@grunddatenTitle">Grunddaten
    <button mat-flat-button mat-dialog-close class="rightBound closeButton" (click)="undoInit()">
      <i class="material-icons buttonIcons">
        clear
      </i>
    </button>
  </h2>
  <form #createForm="ngForm">

    <hr>
    <mat-dialog-content i18n="dialog description|The description for this dialog@@grunddatenDescription">
      Zur Berechnung Ihrer individuellen Vorsorgeleistungen bei der PK Siemens werden persönliche Angaben benötigt.
      Erfassen Sie bitte dazu alle unten aufgeführten Eingabefelder.
    </mat-dialog-content>
    <br>
    <div class="scrollableContent">
      <mat-dialog-content class="entryField">
        <div class="leftBound" i18n="dialog grunddatenBDate|first question in grunddaten dialog@@grunddatenBDate">
          Geburtsdatum
        </div>
        <div class="displayTable">
        </div>
        <mat-form-field class="rightBound correctionMargins" [ngSwitch]="errorInformationDate">
          <i *ngSwitchCase="'minAge'" #warning
             i18n-matTooltip="grunddaten tooltipMinEightteen|second optinoal tooltip in grunddaten@@grunddatenTooltipMinEightteen"
             matTooltip="Sie müssen mindestens 18 Jahre alt sein um diese Simulation benützen zu können."
             class="material-icons warning">
            warning
          </i>
          <i *ngSwitchCase="'maxAge'" #warning
             i18n-matTooltip="grunddaten tooltipMaxSixtyfive|third optinoal tooltip in grunddaten@@grunddatenTooltipMaxSixtyfive"
             matTooltip="Sie dürfen maximal 65 Jahre alt sein um diese Simulation benützen zu können."
             class="material-icons warning">
            warning
          </i>
          <input matInput required ngModel [min]="minDate" [max]="maxDate" id='dateOfBirth'
                 [(ngModel)]='backendInput.dateOfBirth' name='dateOfBirth' #dateOfBirth="ngModel"
                 (dateChange)='dateCheck()' [matDatepicker]="picker"
                 i18n-placeholder="datepicker|datepicker in grunddaten@@datePicker1Grunddaten" placeholder="dd.mm.yyyy">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="multi-year" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
      </mat-dialog-content>

      <mat-divider></mat-divider>
      <mat-dialog-content class="entryField">
        <div class="leftBound" i18n="dialog grunddatenEntry|second question in grunddaten dialog@@grunddatenEntry">
          Eintritt vor 01.07.2017
        </div>
        <div class="iconDiv">
          <i class="material-icons buttonIcons rotated"
             i18n-matTooltip="grunddaten tooltipBefore2017|first obligatory tooltip in grunddaten@@grunddatenTooltipBefore2017"
             matTooltip="Waren Sie schon vor dem 01.07.2017 bei der PK Siemens versichert?">error</i>
        </div>
        <mat-radio-group class="rightBound balancingRadio" name="joiningBefore" [(ngModel)]="joiningBefore" ngModel
                         required>
          <mat-radio-button class="yes" color="primary" value="true" i18n="ja|ja@@homeJa">Ja</mat-radio-button>
          <mat-radio-button color="primary" value="false" i18n="nein|nein@@homeNein">Nein</mat-radio-button>
        </mat-radio-group>
      </mat-dialog-content>

      <mat-divider></mat-divider>

      <mat-dialog-content class="entryField">
        <div class="leftBound"
             i18n="dialog grunddatenEmploymentRate|third question in grunddaten dialog@@grunddatenEmploymentRate">
          Beschäftigungsgrad
        </div>
        <div class="iconDiv sliderIcon">
          <i class="material-icons buttonIcons rotated"
             i18n-matTooltip="grunddaten tooltipPensum|second obligatory tooltip in grunddaten@@grunddatenTooltipPensum"
             matTooltip="Bitte geben Sie hier Ihren Beschäftigungsgrad ein.">error</i>
        </div>
        <app-slider [min]="0" [max]="100" [(value)]="backendInput.pensum" [isPercentage]="true"></app-slider>
      </mat-dialog-content>

      <mat-divider></mat-divider>

      <mat-dialog-content class="entryField">
        <div class="leftBound" i18n="dialog grunddatenDeadline|ninth question in grunddaten dialog@@grunddatenDeadline">
          Stichtag Vorsorgeausweis
        </div>
        <div class="extendedInfo iconDiv">
          <i class="material-icons buttonIcons rotated"
             i18n-matTooltip="grunddaten tooltipWhatDate|ninth obligatory tooltip in grunddaten@@grunddatenTooltipWhatDate"
             matTooltip="An welchem Datum wurde Ihr aktuellster Vorsorgeausweis ausgestellt? ">error</i>
        </div>
        <mat-form-field class="rightBound correctionMargins">
          <input [(ngModel)]="backendInput.insuranceCardDate" matInput [matDatepicker]="bleb"
                 i18n-placeholder="datepicker|datepicker in einkauf@@datePicker2Einkauf"
                 placeholder="dd.mm.yyyy" name="insuranceCardDate" ngModel [required]="isExpanded">
          <mat-datepicker-toggle matSuffix [for]="bleb"></mat-datepicker-toggle>
          <mat-datepicker #bleb startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </mat-dialog-content>

      <mat-divider></mat-divider>

      <mat-dialog-content class="entryField">
        <div class="leftBound"
             i18n="dialog grunddatenAHVSalary|fourth question in grunddaten dialog@@grunddatenAHVSalary">
          Jahreslohn
        </div>
        <div class="iconDiv">
          <i i18n="JahreslohnIcon" class="rotated buttonIcons material-icons"
             i18n-matTooltip="home tooltipSalary|third obligatory tooltip in home@@homeTooltipSalary"
             matTooltip="Als Jahreslohn gilt der durch den Arbeitgeber gemeldete Jahreslohn (= Jahresgehalt und Jahreszielbonus).">error</i>
          <div *ngIf="salarycheckerror" class="fileDownload">
            <i #warning
               i18n-matTooltip="grunddaten tooltipSalary|third obligatory tooltip in grunddaten@@grunddatenTooltipSalary"
               matTooltip="Ihr Anrechenbarer (nicht versicherter Lohn) muss grösser als 21'510 CHF sein."
               class="material-icons buttonIcons warning doubleIcons_secondIcon">warning</i>
          </div>
        </div>
        <mat-form-field class="rightBound rightElement correctionMargins">
          <input matInput placeholder="CHF" name="annualSalary" required pattern="[0-9']{0,}"
                 (change)='salaryCheck($event)' (blur)="this.dialogMethods.thousandSeparator($event)"
                 (keyup)="this.dialogMethods.thousandSeparator($event)" [(ngModel)]="backendInput.annualSalary">
        </mat-form-field>
      </mat-dialog-content>

      <mat-divider></mat-divider>


      <mat-dialog-content class="entryField">
        <div class="leftBound" i18n="dialog grunddatenSaved|fifth question in grunddaten dialog@@grunddatenSaved">
          Sparguthaben
        </div>
        <div class="iconDiv">
          <i class="material-icons buttonIcons rotated"
             i18n-matTooltip="grunddaten tooltipFindSaved|fourth obligatory tooltip in grunddaten@@grunddatenTooltipFindSaved"
             matTooltip="Sie finden Ihr aktuelles Sparguthaben auf Ihrem persönlichen Vorsorgeausweis unter «Freizügigkeitsleistung».">error</i>
        </div>
        <mat-form-field class="rightBound rightElement correctionMargins">
          <input matInput placeholder="CHF" name="retirementAsset" (blur)="this.dialogMethods.thousandSeparator($event)"
                 (keyup)="this.dialogMethods.thousandSeparator($event)"
                 [(ngModel)]="backendInput.currentRetirementAsset" required pattern="[0-9',.]{0,}">
        </mat-form-field>
      </mat-dialog-content>


      <mat-divider></mat-divider>


      <mat-dialog-content class="entryField">
        <div class="leftBound" i18n="dialog grunddatenPlan|sixth question in grunddaten dialog@@grunddatenPlan">
          Sparplan
        </div>
        <div class="iconDiv">
          <i class="material-icons buttonIcons rotated"
             i18n-matTooltip="grunddaten tooltipFindCurrentSaved|fifth obligatory tooltip in grunddaten@@grunddatenTooltipFindCurrentSaved"
             matTooltip="Sie finden Ihren aktuellen Sparplan auf Ihrem persönlichen Vorsorgeausweis. Zur Wahl stehen die drei Sparpläne Standard, Plus und Surplus.">error</i>
        </div>
        <mat-form-field class="rightBound rightElement correctionMargins">
          <mat-select [(ngModel)]="pensionPlan" ngModel name="pensionPlan"
                      i18n-placeholder="grunddaten placeholderOptions|placeholder with three options@@grunddatenPlaceholderOptions"
                      placeholder="Standard/Plus/Surplus"
                      disableRipple required>
            <mat-option value="STANDARD" i18n="dialog grunddatenFirstPlan|plan option@@grunddatenFirstPlan">Standard
            </mat-option>
            <mat-option value="PLUS" i18n="dialog grunddatenSeconPlan|plan option@@grunddatenSecondPlan">Standard Plus
            </mat-option>
            <mat-option value="SURPLUS" i18n="dialog grunddatenThirdPlan|plan option@@grunddatenThirdPlan">Standard
              Surplus
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-divider></mat-divider>


      <mat-dialog-content class="entryField"
                          *ngIf="joiningBefore != null && joiningBefore == 'true' && dialogMethods.dateBefore1966(backendInput.dateOfBirth)">
        <div class="leftBound"
             i18n="dialog grunddatenSingleIn|seventh question in grunddaten dialog@@grunddatenSingleIn">
          ungekürzte Einmaleinlage
        </div>
        <div class="iconDiv">
          <i class="material-icons buttonIcons rotated"
             i18n-matTooltip="grunddaten tooltipInfosSingleIn|seventh obligatory tooltip in grunddaten@@grunddatenTooltipInfosSingleIn"
             matTooltip="Dieser Wert kann bei der Geschäftsstelle erfragt werden.">error</i>
        </div>
        <mat-form-field class="rightBound rightElement correctionMargins">
          <input matInput placeholder="CHF" name="singlePremium" (blur)="this.dialogMethods.thousandSeparator($event)"
                 (keyup)="this.dialogMethods.thousandSeparator($event)"
                 [(ngModel)]="backendInput.singlePremium" required pattern="[0-9',.]{0,}">
        </mat-form-field>
      </mat-dialog-content>

      <mat-divider
        *ngIf="joiningBefore != null && joiningBefore == 'true' && dialogMethods.dateBefore1966(backendInput.dateOfBirth)"></mat-divider>


      <mat-dialog-content class="entryField"
                          i18n="additionalInformation|additional information in grunddaten dialog@@grunddatenAdditional">
        Erweiterte Eingaben
        <mat-checkbox color="primary" class="advancedInputButton" (change)="showDialog()"
                      [required]="isExpanded"></mat-checkbox>
      </mat-dialog-content>


      <mat-accordion id="bonusInfo" class="bonusInfo">
        <mat-expansion-panel id="bonusInfoInsides" expanded="{{isExpanded}}" hideToggle
                             class="mat-elevation-z0 expansion">

          <mat-dialog-content class="entryField">
            <div class="leftBound"
                 i18n="dialog grunddatenProjection|tenth question in grunddaten dialog@@grunddatenProjection">
              Projektionszins
            </div>
            <div class="extendedInfo">
              <i class="material-icons buttonIcons rotated"
                 i18n-matTooltip="grunddaten tooltipEstimatedInterest|tenth obligatory tooltip in grunddaten@@grunddatenTooltipEstimatedInterest"
                 matTooltip="Mit diesem (angenommenen) Zinssatz wird das vorhandene Sparguthaben mit den Sparbeiträgen auf das (ordentliche) Pensionierungsalter hochgerechnet. Die Höhe des effektiven Zinssatzes wird jährlich durch den Stiftungsrat festgelegt.">error</i>
            </div>
            <mat-form-field class="rightBound correctionMargins">
              <i *ngIf="projectionError" #warning
                 i18n-matTooltip="grunddaten tooltipProjection|eleventh obligatory tooltip in grunddaten@@grunddatenTooltipProjection"
                 matTooltip="Der Projektionszins kann nicht grösser als 10% sein"
                 class="material-icons warning">
                warning
              </i>
              <input matInput [(ngModel)]="backendInput.projectionInterest" name="projectionInterest"
                     (keyup)="projectionInterestCorrection($event)" ngModel [required]="isExpanded">
              <span matSuffix>%</span>
            </mat-form-field>
          </mat-dialog-content>


          <mat-divider></mat-divider>


          <mat-dialog-content class="entryField">
            <div class="leftBound"
                 i18n="dialog grunddatenCareer|eleventh question in grunddaten dialog@@grunddatenCareer">
              Lohnkarriere
            </div>
            <div class="extendedInfo">
              <i class="material-icons buttonIcons rotated"
                 i18n-matTooltip="grunddaten tooltipSalaryInterest|twelveth obligatory tooltip in grunddaten@@grunddatenTooltipSalaryInterest"
                 matTooltip="Angenommene Lohnkarriere zur Fortschreibung des Lohneinkommens. 1% p.a. entspricht einer jährlichen Erhöhung des Einkommens um 1%.">error</i>
            </div>
            <mat-form-field class="rightBound correctionMargins">
              <mat-select [(ngModel)]="salaryGrowth" ngModel name="salaryGrowth" placeholder="0-3%" disableRipple
                          [required]="isExpanded">
                <mat-option value="0.0">0%</mat-option>
                <mat-option value="0.01">1%</mat-option>
                <mat-option value="0.02">2%</mat-option>
                <mat-option value="0.03">3%</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-dialog-content>


          <mat-divider></mat-divider>


        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>

  <app-save-button
    [disabledCondition]="!createForm.valid || salarycheckerror || datecheckerror || percentcheckerror || projectionError && isExpanded"
    [form]="createForm"
    (firedEvent)="logdata()"
  ></app-save-button>
</div>
