import {ScenarioInput, ScenarioType} from '../../data-type/input/ScenarioInput';
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DataService} from '../../service/data.service';
import {ValueType} from 'src/app/data-type/input/ValueType';
import {CashFlow} from '../../data-type/input/CashFlow';
import {Eventstamp} from '../../data-type/output/Eventstamp';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-einkauf',
  templateUrl: './einkauf.component.html',
  styleUrls: ['../scenario.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.5s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('0.3s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class EinkaufComponent implements OnInit {

  scenario: ScenarioInput = {
    type: null,
    date: null,
    id: null,
    cashflows: []
  };
  einkaufData: CashFlow = {
    value: 0,
    date: null,
    valueType: ValueType.ABSOLUTE,
    atPension: false
  };
  @ViewChild('warning', {static: false}) warning: ElementRef;
  @ViewChild('einkaufDataSlider', {static: false}) einkaufDataSlider: ElementRef;
  dateCheckError: boolean;
  maxEinkaufError = false;
  value = 0;
  testValidationList: Eventstamp[];
  maxEinkauf;
  earlyRetirementAge: number;
  earlyRetirement = false;
  date: string;
  secondERBI = false;
  errorInformationMaxEinkauf = 'default error';
  ages = [58, 59, 60, 61, 62, 63, 64];
  earlyRetirementBuyIn = false;
  firstEarlyRetirementBuyIn = true;
  maxEinkaufEarlyRetirement: number;
  einkaufEarlyRetirement: number;
  ageSet = false;
  minDate: Date;
  maxDate: Date;
  einkaufFP = false;
  einkaufPossible = true;

  /*To switch the Headers (HTML Line 3&4)*/


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dataService: DataService,
    public dialogMethods: DialogMethodsService,
  ) {
  }

  ngOnInit() {
    /*Initialize the min and max dates for the datepicker*/
    const minDateArr: string[] = this.dataService.getHighestDate().toString().split('-');
    this.minDate = new Date(+minDateArr[0], +minDateArr[1] - 1, +minDateArr[2]);
    const maxDateArr: string[] = this.dataService.getData().dateOfBirth.toString().split('-');
    this.maxDate = new Date(+maxDateArr[0] + +this.dataService.getPensionAge(), +maxDateArr[1], 0);

    this.dataService.validationList.subscribe((output: any) => {
    });
    this.dataService.testValidationList.subscribe((output: any) => {
      this.updateMaxValue(output);
    });
    /*this if-statement prevents the user from entering another Einkauf after having set an FP */
    if (this.dataService.getPensionAge() < 65) {
      this.einkaufFP = true;
      this.earlyRetirementAge = this.dataService.getPensionAge();
      this.earlyRetirement = true;
      this.firstEarlyRetirementBuyIn = false;
      this.earlyRetirementBuyIn = true;
      this.secondERBI = true;
    }
  }

  pitch(e) {
    this.einkaufData.value = this.dataService.correctNumbers(e);
    if (this.maxEinkauf === undefined && this.ageSet === true) {
      this.earlyRetirementBuyIn = true;
    } else {
      this.earlyRetirementBuyIn = this.dataService.correctNumbers(e) === this.maxEinkauf;
    }
  }

  pitchEarlyRetirement(e) {
    this.einkaufEarlyRetirement = this.dataService.correctNumbers(e);
  }

  changeHeader() {
    const that = this;
    /*timeout neccesary to avoid changedAfterItHasBeenChecked Error*/
    setTimeout(function () {
      that.einkaufFP = !that.einkaufFP;
    }, 0);
  }

  dateCheck(e?) {
    if (e === null) {
      this.dateCheckError = !this.isValid(this.einkaufData.date);
    } else if (!this.isValid(e.value)) {
      this.dateCheckError = true;
    } else {
      this.dateCheckError = false;
      this.updateValidationList();
      this.setAgeRange(e);
    }
  }

  setAgeRange(e) {
    if (e.value.length === 4) {
      e.value = '1.1.' + e.value;
    }
    let age = this.dialogMethods.calculateAgeAtDate(this.dataService.getData().dateOfBirth, e.value);
    if (age < 58) {
      age = 58;
    }
    this.ages = [];
    age += 1;
    for (age; age < 65; age++) {
      this.ages.push(age);
    }
  }

  isValid(input) {
    /*execute core validations*/
    const coreValidations = this.dataService.dateValidator(input);
    /*returns [dateError: bool, errorInfo: string, dateArr: number[], highestDateArr: number[], dateOfBirtArr: number[], arguable: bool]*/
    const isValid: boolean = coreValidations[0];
    this.errorInformationMaxEinkauf = coreValidations[1];

    /*Custom Validations*/

    return isValid;
  }

  logdata() {
    this.scenario.date = this.einkaufData.date;
    this.scenario.type = ScenarioType.BuyIn;
    if (this.secondERBI === true) {
      this.dataService.erbiCount++;
    }
    if (this.earlyRetirement === false) {
      this.dataService.setPensionAge(65);
    } else {
      this.dataService.setPensionAge(this.earlyRetirementAge);
    }
    if ((this.einkaufEarlyRetirement !== 0 && this.earlyRetirement === true) || !this.firstEarlyRetirementBuyIn) {
      this.einkaufData.value += this.einkaufEarlyRetirement;
      this.scenario.type = ScenarioType.EarlyRetirementBuyIn;
    }
    this.scenario.cashflows[0] = {
      atPension: this.einkaufData.atPension,
      value: this.einkaufData.value,
      date: this.einkaufData.date,
      valueType: this.einkaufData.valueType
    };
    this.dataService.setScenario(this.scenario);
  }

  updateMaxValue(validationList) {
    this.testValidationList = validationList.eventstamps;
    if (this.testValidationList[this.dataService.cashflowCount].eventstampType.toString() === 'MaximumBuyInEarlyRetirement') {
      this.maxEinkaufEarlyRetirement = Math.floor(this.testValidationList[this.dataService.cashflowCount].absoluteMaxValue)
        - this.einkaufData.value;
      if (this.maxEinkaufEarlyRetirement <= 0) {
        this.earlyRetirementBuyIn = true;
      }
      if (this.maxEinkaufEarlyRetirement <= 0) {
        this.errorInformationMaxEinkauf = 'maxBuyInReached';
        this.maxEinkaufError = true;
        this.maxEinkaufEarlyRetirement = 0;
      } else {
        this.maxEinkaufError = false;
      }
      this.einkaufPossible = this.maxEinkaufEarlyRetirement !== 0;
    } else {
      this.maxEinkauf = Math.floor(this.testValidationList[this.dataService.cashflowCount].absoluteMaxValue);
      if (this.maxEinkauf < 0) {
        this.errorInformationMaxEinkauf = 'maxBuyInReached';
        this.maxEinkaufError = true;
        this.maxEinkauf = 0;
      } else {
        this.maxEinkaufError = false;
      }
      this.einkaufPossible = this.maxEinkauf !== 0;
    }
  }

  updateValidationList(age?) {
    const testCF: CashFlow[] = [{
      date: this.dialogMethods.correctDate(this.einkaufData.date),
      value: 1,
      valueType: ValueType.ABSOLUTE,
      atPension: false
    }];

    if (age && age < 65) {
      testCF[0].value = this.maxEinkauf + 100;
    }
    const testScenario: ScenarioInput = {
      type: ScenarioType.BuyIn,
      date: testCF[0].date,
      id: null,
      cashflows: testCF
    };
    this.dataService.setTestScenario(testScenario, age);
  }

  setPensionAge(e) {
    this.updateValidationList(e);
    this.ageSet = true;
  }
}
