import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {DataService} from '../../service/data.service';
import {NgForm} from '@angular/forms';
import {FormIconNames, FormIconNamesFR} from './formIconNames';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-form-icon',
  templateUrl: './form-icon.component.html',
  styleUrls: ['./form-icon.component.css']
})
export class FormIconComponent implements OnChanges/*, DoCheck*/ {

  @Input() form: any; // TODO: find real class to form
  @ViewChild('formTooltip', {static: false}) formTooltip: ElementRef;
  errorInformation: string;
  clicked = false;
  oldDir: NgForm[] = [];
  dir: NgForm[] = [];
  french = false;

  constructor(
    private dataService: DataService,
    private platformLocation: PlatformLocation
  ) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
    const that = this;
    this.dataService.updateFormicon.subscribe(() => {
      this.dir = [];
      setTimeout(function () {
        that.form._directives.forEach(e => that.dir.push(e));
        if (that.dir != null && that.dir.length > 0) {
          that.updateFormIcon();
          that.clicked = true;
        }
      });
    });
  }

  updateFormIcon(): void {
    if (!this.french) {
      this.errorInformation = 'Fehlende oder inkorrekte Eingaben: ';
      for (const i of this.dir) {
        if (i.invalid) {
          this.errorInformation += FormIconNames[i.name] + ', ';
        }
      }
    } else {
      this.errorInformation = 'Entrées manquantes ou incorrectes: ';
      for (const i of this.dir) {
        if (i.invalid) {
          this.errorInformation += FormIconNamesFR[i.name] + ', ';
        }
      }
    }


  }

  ngOnChanges() {
    this.updateFormIcon();
  }
}
