import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material';
import {DisclaimerComponent} from '../disclaimer/disclaimer.component';
import {DataService} from '../../service/data.service';
import {BackendInput} from '../../data-type/input/BackendInput';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {PensionPlan} from '../../data-type/input/PensionPlan';
import {PlatformLocation} from '@angular/common';


@Component({
  selector: 'app-grunddaten',
  templateUrl: './grunddaten.component.html',
  styleUrls: ['./grunddaten.component.css', '../dialog.css'],
})
export class GrunddatenComponent implements OnInit {
  @ViewChild('einkaufButton', {static: false}) einkaufButton: ElementRef;
  @ViewChild('wefButton', {static: false}) wefButton: ElementRef;
  @ViewChild('arbeitButton', {static: false}) arbeitButton: ElementRef;
  @ViewChild('pensionierungButton', {static: false}) pensionierungButton: ElementRef;
  @ViewChild('pensumSlider', {static: false}) pensumSlider: ElementRef;
  datecheckerror = false;
  salarycheckerror = false;
  percentcheckerror = false;
  errorInformationDate: string;
  projectionError = false;
  set: number;
  joiningBefore: string;
  backendInput: BackendInput = new BackendInput();
  pensionPlan: string;
  salaryGrowth = '0.0';
  isExpanded = false;
  startDate = new Date(1960);
  maxDate: object;
  minDate: object;
  french = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public disclaimer: MatDialog,
              public dataService: DataService,
              public dialogMethods: DialogMethodsService,
              private platformLocation: PlatformLocation
  ) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
  }

  ngOnInit(): void {
    const today = new Date();
    const year = today.getFullYear();
    const day = today.getDate();
    const month = today.getMonth();
    this.maxDate = new Date(year - 18, month, day);
    this.minDate = new Date(year - 70, month, day);
    this.backendInput = JSON.parse(JSON.stringify(this.dataService.getData()));
    this.backendInput.pensum = this.backendInput.pensum * 100;
    this.backendInput.projectionInterest = this.backendInput.projectionInterest * 100;
    this.convertSalaryGrowthToString();
    this.convertPensionPlanReopen();
  }

  logdata() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.backendInput;
    const dialogRef = this.disclaimer.open(DisclaimerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.correctPensionPlan();
        if (!(this.joiningBefore != null && this.joiningBefore === 'true'
          && this.dialogMethods.dateBefore1966(this.backendInput.dateOfBirth))) {
          this.backendInput.singlePremium = 0;
        } else {
          this.backendInput.singlePremium = this.dialogMethods.thousandSeparatorWithReturn(this.backendInput.singlePremium);
        }
        this.backendInput.annualSalary = this.dialogMethods.thousandSeparatorWithReturn(this.backendInput.annualSalary);
        this.backendInput.currentRetirementAsset = this.dialogMethods.thousandSeparatorWithReturn(this.backendInput.currentRetirementAsset);
        this.backendInput.projectionInterest = this.backendInput.projectionInterest / 100;
        this.backendInput.pensum = this.backendInput.pensum / 100;
        this.backendInput.salaryGrowth = parseFloat(this.salaryGrowth);
        this.dataService.setData(this.backendInput);
      }
    });
  }

  undoInit() {
    this.backendInput.pensum = this.backendInput.pensum / 100;
    this.backendInput.projectionInterest = this.backendInput.projectionInterest / 100;
  }

  showDialog() {
    this.isExpanded = !this.isExpanded;
  }

  projectionInterestCorrection(e) {
    const that = this;
    that.projectionError = !that.dialogMethods.projectionInterestCorrection(e);
  }

  dateCheck() {
    this.datecheckerror = !this.isValid(this.backendInput.dateOfBirth);
  }

  isValid(input) {
    input = this.dialogMethods.correctDate(input);
    // separate Day, Year, Month
    const dateOfBirthArr = input.toString().split('-');
    const today = new Date();
    const age: any = this.dialogMethods.calculateAgeAtDate(this.backendInput.dateOfBirth, today);
    if (age < 18 || (age === 18 && today.getMonth() < +dateOfBirthArr[1] - 1) ||
      (age === 18 && today.getMonth() === +dateOfBirthArr[1] - 1 && today.getDate() < +dateOfBirthArr[2])) {
      this.errorInformationDate = 'minAge';
    } else if (age > 70 || (age === 70 && today.getMonth() > +dateOfBirthArr[1] - 1) ||
      (age === 70 && today.getMonth() === +dateOfBirthArr[1] - 1 && today.getDate() > +dateOfBirthArr[2])) {
      // wording ?
      this.errorInformationDate = 'maxAge';
      return false;
    } else {
      return true;
    }
  }

  salaryCheck(e) {
    const that = this;
    that.salarycheckerror = !that.isValidSalary(e.srcElement.value);
  }

  isValidSalary(input) {
    // separate Day, Year, Month
    const salary = parseInt(input.replace(/\D+/g, ''), 10);
    return salary >= 21330;
  }

  correctPensionPlan() {
    if (this.joiningBefore === 'true') {
      if (this.pensionPlan === 'PLUS') {
        this.backendInput.pensionPlan = PensionPlan.PLUS_BEFORE;
      }
      if (this.pensionPlan === 'STANDARD') {
        this.backendInput.pensionPlan = PensionPlan.STANDARD_BEFORE;
      }
      if (this.pensionPlan === 'SURPLUS') {
        this.backendInput.pensionPlan = PensionPlan.SURPLUS_BEFORE;
      }
    }
    if (this.joiningBefore === 'false') {
      if (this.pensionPlan.indexOf('PLUS') >= 0) {
        this.backendInput.pensionPlan = PensionPlan.PLUS_AFTER;
      }
      if (this.pensionPlan.indexOf('STANDARD') >= 0) {
        this.backendInput.pensionPlan = PensionPlan.STANDARD_AFTER;
      }
      if (this.pensionPlan.indexOf('SURPLUS') >= 0) {
        this.backendInput.pensionPlan = PensionPlan.SURPLUS_AFTER;
      }
    }
  }

  convertPensionPlanReopen() {
    if (this.backendInput.pensionPlan === PensionPlan.PLUS_BEFORE) {
      this.joiningBefore = 'true';
      this.pensionPlan = 'PLUS';
    } else if (this.backendInput.pensionPlan === PensionPlan.STANDARD_BEFORE) {
      this.joiningBefore = 'true';
      this.pensionPlan = 'STANDARD';
    } else if (this.backendInput.pensionPlan === PensionPlan.SURPLUS_BEFORE) {
      this.joiningBefore = 'true';
      this.pensionPlan = 'SURPLUS';
    }

    if (this.backendInput.pensionPlan === PensionPlan.PLUS_AFTER) {
      this.joiningBefore = 'false';
      this.pensionPlan = 'PLUS';
    }
    if (this.backendInput.pensionPlan === PensionPlan.STANDARD_AFTER) {
      this.joiningBefore = 'false';
      this.pensionPlan = 'STANDARD';
    }
    if (this.backendInput.pensionPlan === PensionPlan.SURPLUS_AFTER) {
      this.joiningBefore = 'false';
      this.pensionPlan = 'SURPLUS';
    }
  }

  convertSalaryGrowthToString() {
    if (this.backendInput.salaryGrowth === 0) {
      this.salaryGrowth = '0.0';
    } else if (this.backendInput.salaryGrowth === 0.01) {
      this.salaryGrowth = '0.01';
    } else if (this.backendInput.salaryGrowth === 0.02) {
      this.salaryGrowth = '0.02';
    } else if (this.backendInput.salaryGrowth === 0.03) {
      this.salaryGrowth = '0.03';
    }
  }

}
