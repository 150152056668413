<div class="dialog">
  <h2 mat-dialog-title i18n="dialog title|The title for this dialog@@disclaimerTitle">Disclaimer</h2>

  <hr>
  <mat-dialog-content i18n="dialog text|The text for this dialog@@disclaimerText">
    Für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen, Angaben oder
    Berechnungen wird keinerlei Gewähr übernommen.
    Haftungsansprüche gegenüber der Pensionskasse Siemens, welche sich auf Schäden materieller oder ideeller Art
    beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen,
    Angaben oder Berechnungen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen, Angaben oder
    Berechnungen verursacht wurden, sind grundsätzlich ausgeschlossen.
    Massgebend für die Berechnung von Leistungen seitens Pensionskasse (Einkauf, Renten, Kapitalbezug, etc.) ist einzig
    das Vorsorgereglement.
    <div class="indented" required>
      <label>
        Ich habe den Disclaimer gelesen und habe verstanden, dass die vorliegenden Informationen, Angaben oder
        Berechnungen Fehler aufweisen können.
        Ich akzeptiere, dass sämtliche Haftung gegenüber der Pensionskasse Siemens ausgeschlossen ist.
      </label>
    </div>

  </mat-dialog-content>
  <div>
    <button class="continueButton" mat-stroked-button [mat-dialog-close]="true" routerLink="vorsorge"
            routerLinkActive="active">
      <mat-icon>arrow_forward</mat-icon>
      <span i18n="disclaimer accept|Accept terms and conditions@@disclaimerAccept"> Ich akzeptiere.</span>
    </button>
  </div>
</div>
