<!--- validation Pensionierungscomponents -> TODO --->
<div class="dialog">
  <h2 mat-dialog-title i18n="dialog title|The title for this dialog@@pensionierungTitle">Pensionierung
    <button mat-button mat-dialog-close class="rightBound">
      <i class="material-icons rightBound">
        clear
      </i>
    </button>
  </h2>
  <hr>
  <span *ngIf="earlyRetirement === true"
        i18n="dialog description|The description for this dialog@@pensionierungDescription">Sie haben Einkäufe für eine vorzeitige Pensionierung getätigt und dabei den Zeitpunkt Ihrer Frühpensionierung auf das Alter <b>{{pensionAge}}</b> festgelegt. Das Ändern dieses Zeitpunkts ist hier nicht möglich, sondern muss im bereits erfassten Einkauf geändert werden. Jedoch können Sie hier einen Kapitalbezug bei der Frühpensionierung vornehmen.</span>
  <span *ngIf="earlyRetirement === false"
        i18n="dialog alternate description|The alternate description for this dialog@@pensionierungDescriptionAlt">
  Sie können den Zeitpunkt Ihrer Pensionierung flexibel wählen (frühestens im Alter 58). Das Sparguthaben kann teilweise oder ganz als Kapital bezogen werden. Bitte füllen Sie alle Felder aus und klicken Sie auf «<b>Speichern</b>».
</span>
  <br>
  <br>
  <br>

  <form #pensionierungsForm="ngForm">
    <table>
      <tr class="mat-row" *ngIf="!earlyRetirement">
        <td class="mat-cell" i18n="dialog pensionierungAge|first question in pensionierung dialog@@penionierungAge">
          Gewünschtes Pensionierungsalter?
        </td>
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell" align="right">
          <mat-form-field class="rightBound">
            <mat-select [(ngModel)]="pensionAge" name="pensionAge" pattern="[0-9]{1,2}" placeholder="-" disableRipple
                        ngModel [required]="!earlyRetirement" (valueChange)="continue = true">
              <mat-option *ngFor="let year of pensionYears" value="{{year}}" (click)="kapitalBezugValidation()">{{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr class="mat-row" *ngIf="continue === true" [@inOutAnimation]>
        <td class="mat-cell"
            i18n="dialog pensionierungWithdrawal|second question in pensionierung dialog@@penionierungWithdrawal">
          Wünschen Sie einen Kapitalbezug?
        </td>
        <td class="mat-cell">
          <mat-icon class="coloredIcon"
                    i18n-matTooltip="pensionierung tooltipServicesWithinThreeYears|first obligatory tooltip in pensionierung@@pensionierungTooltipServicesWithinThreeYears"
                    matTooltip="Leistungen, die innerhalb von drei Jahren nach einem Einkauf als Kapitalleistungen bezogen werden, können zu steuerlichen Konsequenzen führen. Die Verantwortung für die Abklärung der steuerlichen Konsequenzen von Einkäufen und Kapitalbezügen liegt beim Versicherten.">
            info
          </mat-icon>
        </td>
        <td class="mat-cell"></td>
        <td class="mat-cell" align="right">
          <mat-radio-group [(ngModel)]="kapitalBezug" ngModel [required]="continue === true" name="kapitalBezugbool">
            <mat-radio-button [value]="true" color="primary" i18n="ja|ja@@pensionierungJa">Ja</mat-radio-button>
            <mat-radio-button [value]="false" color="primary" i18n="nein|nein@@pensionierungNein">Nein
            </mat-radio-button>
          </mat-radio-group>
        </td>
      </tr>

      <tr class="mat-row" *ngIf="kapitalBezug === true" [@inOutAnimation]>
        <td class="mat-cell"
            i18n="dialog pensionierungWithdrawalAmount|third question in pensionierung dialog@@penionierungWithdrawalAmount">
          Höhe des Kapitalbezugs
        </td>
        <td class="mat-cell">
          <mat-icon class="coloredIcon"
                    i18n-matTooltip="pensionierung tooltipPercentOrAbsolute|second obligatory tooltip in pensionierung@@pensionierungTooltipPercentOrAbsolute"
                    matTooltip="Sie können die Höhe des Kapitalbezugs in CHF oder in % des Sparguthabens angeben. Das Kapital wird zum Zeitpunkt der Pensionierung ausbezahlt und vom dann vorhandenen Sparguthaben abgezogen.">
            info
          </mat-icon>
        </td>
        <td class="mat-cell">
          <mat-slide-toggle (click)="changeValue()" i18n="prozent slide |prozent slide toggle@@prozent">Prozent
          </mat-slide-toggle>
        </td>
        <td class="mat-cell" align="right">
          <app-slider [min]="minimumValue" [max]="maxKapitalBezug" [(value)]="pensionierungsDataCashflow.value"
                      [isPercentage]="isPercentage"></app-slider>
        </td>
      </tr>
    </table>
  </form>

  <app-save-button (firedEvent)="logdata()"
                   [disabledCondition]="!pensionierungsForm.valid || pensionError || !continue || (pensionierungsDataCashflow.value == 0 && kapitalBezug)"
                   [form]="pensionierungsForm"></app-save-button>


</div>
