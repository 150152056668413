import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

import {DataService} from '../../service/data.service';
import {CashFlow} from '../../data-type/input/CashFlow';
import {ValueType} from '../../data-type/input/ValueType';
import {Eventstamp} from '../../data-type/output/Eventstamp';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {ScenarioInput, ScenarioType} from '../../data-type/input/ScenarioInput';
import {animate, style, transition, trigger} from '@angular/animations';

enum WEFmode {
  Withdrawal = 'withdrawal',
  BuyIn = 'buyin'
}

@Component({
  selector: 'app-wef',
  templateUrl: './wef.component.html',
  styleUrls: ['../scenario.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.5s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('0.3s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class WEFComponent implements OnInit {

  wefData: CashFlow = {
    date: null,
    value: 20000,
    valueType: ValueType.ABSOLUTE,
    atPension: false
  };
  scenario: ScenarioInput = {
    date: null,
    type: ScenarioType.WEF,
    id: null,
    cashflows: null
  };
  value = 0;
  maxWEF = 20000;
  maxWEFbuyin: number;
  minWEF = 20000;
  isPercentage;
  datecheckerror: boolean;
  errorInformationDate = 'default error';
  ageAtInsuranceCardDate = this.dialogMethods.calculateAgeAtDate(this.dataService.getData().dateOfBirth,
    this.dataService.getData().insuranceCardDate);

  validationList: Eventstamp[];
  testValidationList: Eventstamp[];
  errorInformationWEF: string;
  WEFError = false;
  saldoWEF = this.dataService.saldoWEF;
  vestedBenefit: number;

  wefMode = WEFmode.Withdrawal;
  maxDate: Date;
  minDateW: Date;
  /*Minimum Date for Withdrawal*/
  minDateB: Date;

  dateChecked = false;

  /*Minimum Date for Buyin*/

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService,
              public dialogMethods: DialogMethodsService) {
  }

  ngOnInit() {
    /*Initialize the min and max dates for the datepicker*/
    const minDateArr: string[] = this.dataService.getHighestDate().toString().split('-');
    this.minDateB = new Date(+minDateArr[0], +minDateArr[1] - 1, +minDateArr[2]);
    if (this.saldoWEF !== 0) {
      /*Custom minDate for minDateW if there is already a withdrawal*/
      const scenarioArr: ScenarioInput[] = this.dataService.getScenarios();
      for (const i of scenarioArr) {
        const scenarioDate = new Date(i.date);
        if (i.type === ScenarioType.WEF && +minDateArr[0] - scenarioDate.getFullYear() < 5) {
          minDateArr[0] = (+minDateArr[0] + 5).toString();
          break;
        }
      }
    }
    this.minDateW = new Date(+minDateArr[0], +minDateArr[1] - 1, +minDateArr[2]);
    const maxDateArr: string[] = this.dataService.getData().dateOfBirth.toString().split('-');
    this.maxDate = new Date(+maxDateArr[0] + +this.dataService.getPensionAge() - 3, +maxDateArr[1] - 1, +maxDateArr[2]);

    this.dataService.validationList.subscribe((output: any) => {
      this.validationList = output;
    });
    this.dataService.testValidationList.subscribe((output: any) => {
      this.updateMaxValue(output);
    });
  }

  dateCheck(e?) {
    if (!e) {
      if (this.wefData.date) {
        const wefDate: Date = this.wefData.date;
        e = [wefDate.getFullYear(), wefDate.getMonth() + 1, wefDate.getDate()].reverse().join('.');
      } else {
        return;
      }
    } else {
      e = e.value;
    }
    if (!this.isValid(e)) {
      this.datecheckerror = true;
    } else {
      this.datecheckerror = false;
      if (!(this.ageAtInsuranceCardDate > 50 && this.wefMode === 'withdrawal') || this.vestedBenefit != null) {
        this.testScenario();
      }
      if (this.wefMode === 'buyin') {
        this.maxWEF = -this.dataService.saldoWEF;
      }
    }
    this.dateChecked = true;
  }

  testScenario() {
    const testCF: CashFlow[] = [{
      date: this.dialogMethods.correctDate(this.wefData.date),
      value: -1,
      valueType: ValueType.ABSOLUTE,
      atPension: false
    }];
    if (this.vestedBenefit) {
      testCF[0].vestedBenefit = this.vestedBenefit;
    }
    const testScenario: ScenarioInput = {
      type: ScenarioType.WEF,
      date: testCF[0].date,
      cashflows: testCF,
      id: null
    };

    this.dataService.setTestScenario(testScenario);
  }

  isValid(input) {
    /*execute core validations*/
    const coreValidations = this.dataService.dateValidator(input);
    /*returns [dateError: bool, errorInfo: string, dateArr: number[], highestDateArr: number[], dateOfBirtArr: number[], arguable: boolean*/
    let isValid: boolean = coreValidations[0];
    this.errorInformationDate = coreValidations[1];
    const dateArr: number[] = coreValidations[2];
    const dateOfBirthArr: number[] = coreValidations[4];
    const arguable: boolean = coreValidations[5];

    /*custom validation*/
    if (arguable === true) {
      const scenarioArr: ScenarioInput[] = this.dataService.getScenarios();
      const scenarioCount: number = this.dataService.getScenarioCount();
      const scenarioDateArr: number[] = [];
      const pensionDate = new Date(dateOfBirthArr[0] + this.dataService.getPensionAge(), dateOfBirthArr[1], 0);

      if (
        dateArr[0] > pensionDate.getFullYear() - 3 ||
        (dateArr[0] === pensionDate.getFullYear() - 3 && dateArr[1] > pensionDate.getMonth() + 1) ||
        (dateArr[0] === pensionDate.getFullYear() - 3 && dateArr[1] === pensionDate.getMonth() + 1 && dateArr[2] > pensionDate.getDate())) {
        /*Entered Date needs to be at least three years before retirement*/
        this.errorInformationDate = 'maxDate';
        isValid = false;
      }
      if (scenarioCount !== 0 && isValid && this.wefMode === WEFmode.Withdrawal) {
        /*Two WEF-withdrawals need to be at least 5 years apart*/
        for (const i of scenarioArr) {
          const scenarioDate: string[] = i.cashflows[0].date.toString().split('-');
          for (const j of scenarioDate) {
            scenarioDateArr.push(parseInt(j, 10));
          }
          if (i.type === ScenarioType.WEF && dateArr[0] - scenarioDateArr[0] <= 5) {
            this.errorInformationDate = '5yearsRule';
            isValid = false;
          }
        }
      }
    }

    return isValid;
  }

  sliderValueAtExtremes(mode: string) {
    // Fix for: When changing modes, slider could be out of range for the new mode and wouldn't show the correct value, also used in
    if (mode === 'withdrawal') {
      this.minWEF = 20000;
      this.wefMode = WEFmode.Withdrawal;
      if (this.wefData.value > -this.maxWEF) {
        this.wefData.value = -this.maxWEF;
      }
      if (this.wefData.value < this.minWEF) {
        this.wefData.value = this.minWEF;
      }
    } else {
      this.minWEF = 10000;
      this.maxWEFbuyin = -this.saldoWEF;
      if (Math.abs(this.saldoWEF) < 20000) {
        this.minWEF = -this.saldoWEF;
      }
      this.wefMode = WEFmode.BuyIn;
      if (this.wefData.value > -this.saldoWEF) {
        this.wefData.value = -this.saldoWEF;
      }
    }
  }

  logdata() {
    this.wefData.value = this.dataService.correctNumbers(this.wefData.value);
    if (this.wefMode === 'withdrawal') {
      this.wefData.value = -this.wefData.value;
      this.scenario.type = ScenarioType.WEF;
    } else {
      this.scenario.type = ScenarioType.WEFpayback;
    }
    this.scenario.cashflows = [{
      date: this.wefData.date,
      value: this.wefData.value,
      valueType: this.wefData.valueType,
      atPension: this.wefData.atPension
    }];
    this.scenario.date = this.wefData.date;
    this.dataService.setScenario(this.scenario);
    this.dataService.saldoWEF = this.saldoWEF + this.wefData.value;
  }

  updateMaxValue(validationList) {
    this.testValidationList = validationList.eventstamps;
    this.maxWEF = Math.floor(this.testValidationList[this.dataService.cashflowCount].absoluteMaxValue);
    if (Math.abs(this.maxWEF) < 20000 && this.wefMode === 'withdrawal') {
      this.WEFError = true;
      this.maxWEF = 0;
    } else {
      this.WEFError = false;
    }
  }

  pitchFZL(e) {
    this.vestedBenefit = e;
    this.dateCheck();
  }

}


