<div class="dialog">
  <h2 class="dialogTitle">
    <span i18n="plan dialog title|title for plan dialog@@planTitle">
      Planwechsel
    </span>
    <button mat-button mat-dialog-close class="rightBound">
      <i class="material-icons rightBound">
        clear
      </i>
    </button>
  </h2>
  <hr>
  <span i18n="plan dialog description|description and subtitle for the plan dialog@@planDescription">
    Bei der Pensionskasse Siemens stehen Ihnen drei Sparpläne zur Auswahl.
    Sie haben die Möglichkeit einen Planwechsel jeweils per Anfangs Jahr vorzunehmen.
    Bitte füllen Sie alle Felder aus und klicken Sie auf «<b>Speichern</b>».
  </span>

  <form #planForm="ngForm">
    <table>
      <tr class="mat-row">
        <td class="mat-cell" i18n="dialog planTime|input for time of plan change@@planDate">In welchem Jahr möchten Sie
          den Planwechsel vornehmen?
        </td>
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell" align="right">
          <mat-form-field>
            <mat-select ngModel [(ngModel)]="selectedYear" name="selectedYear" disableRipple required>
              <mat-option *ngFor="let year of years" value="{{year}}">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr class="mat-row" [@inOutAnimation]>
        <td class="mat-cell" i18n="dialog plan selectedPlan|input for selected plan of plan change@@selectedPlan">
          Welchen Sparplan möchten Sie neu wählen?
        </td>
        <td class="mat-cell">
        </td>
        <td class="mat-cell"></td>
        <td class="mat-cell" align="right">
          <mat-form-field>
            <mat-select ngModel name="selectedPlan" [(ngModel)]="selectedPlan" disableRipple required>
              <mat-option *ngFor="let plan of plans" value="{{plan}}">
                {{plan}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </form>


  <app-save-button (firedEvent)="logdata()" [disabledCondition]="!planForm.valid" [form]="planForm"></app-save-button>
</div>
