import {ScenarioType} from 'src/app/data-type/input/ScenarioInput';
import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DataService} from '../../service/data.service';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {ScenarioInput} from '../../data-type/input/ScenarioInput';


@Component({
  selector: 'app-scenario-info',
  templateUrl: './scenario-info.component.html',
  styleUrls: ['./scenario-info.component.css']
})
export class ScenarioInfoComponent implements OnInit, AfterViewInit {

  inputData: { names: Array<string>, scenarios: Array<{ date: object, type: ScenarioType, id: number }>, year: string };
  eventList: Array<{
    year: string, events: Array<{ icon: string, name: string, value?: any, half: number }>,
    scenario: Array<ScenarioInput>
  }> = Array();
  latestEvents: Array<any>; /*TODO: find real type*/

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dataService: DataService,
    public dialogMethods: DialogMethodsService) {
    this.inputData = data;
  }

  ngOnInit(): void {
    this.eventList = this.dataService.getList();
    this.latestEvents = this.eventList[this.eventList.length - 1].events;
  }

  ngAfterViewInit() {
    const ua = window.navigator.userAgent;        // TARGET IE ONLY
    const msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11./)) {
      for (let i = 0; i < document.getElementsByClassName('customIcon').length; i++) {
        for (let j = 0; j < document.getElementsByClassName('customIcon')[i].children.length; j++) {
          document.getElementsByClassName('customIcon')[i].children[j].setAttribute('style', 'margin-top: -40px');
        }
      }
    }
  }

  deleteLastEvent(): void {
    this.dataService.deleteScenario();
    // setTimeout(() => {                                // FOR GRAPH RESIZING
    //   window.dispatchEvent(new Event('resize'));
    // }, 60);
  }

}
