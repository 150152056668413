import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {DataService} from '../../service/data.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit, OnChanges {

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  @Input() min = 0;
  @Input() max = 100;
  @Input() isPercentage: boolean;
  @Input() required: boolean;
  @ViewChild('slider', {static: false}) slider: ElementRef;
  minimumPercentageValue: number;

  constructor(public dialogMethods: DialogMethodsService, public dataService: DataService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isPercentage && !changes.isPercentage.firstChange) {
      if (changes.isPercentage.currentValue) {
        const absoluteValue = Math.round(this.dataService.correctNumbers(this.value));
        const percentageValue = Math.round((absoluteValue) / (this.max) * 100.0);
        this.value = percentageValue.toString();
      } else {
        const percentageValue = this.dataService.correctNumbers(this.value) / 100.0;
        const absoluteValue = Math.round(percentageValue * (this.max));
        this.value = this.dialogMethods.thousandSeparatorWithReturn(absoluteValue);
      }
      // To avoid ExpressionChangedAfterItHasBeenCheckedError
      const that = this;
      setTimeout(() => {
        that.giveValueBack();
      }, 0);

    }
    if (changes.max) {
      this.max = Math.abs(changes.max.currentValue);
      this.minimumPercentageValue = Math.ceil(this.min / this.max * 100.0);
    }
    if (changes.min) {
      this.min = Math.abs(changes.min.currentValue);
      this.minimumPercentageValue = Math.ceil(this.min / this.max * 100.0);
    }
    setTimeout(() => {
      this.validateInput(this.value);
    }, 0);
    // To avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      if (changes.isPercentage) {
        this.formatInput(changes.isPercentage.currentValue);
      }
    }, 0);

  }

  formatInput(isPercentage) {
    const elementList = this.slider.nativeElement.children;
    let factorLength;
    let spanLength;
    if (isPercentage) {
      factorLength = 12;
      spanLength = 3;
    } else {
      if (isPercentage === false) {
        factorLength = 8.5;
        spanLength = this.max.toString().length;
      }
    }
    if (spanLength === undefined) {
      spanLength = 6;
    }
    if (factorLength === undefined) {
      factorLength = 8.5;
    }
    elementList[1].children[0].children[0].style.width = spanLength * factorLength + 'px';
    elementList[1].children[0].style.width = spanLength * factorLength + 'px';
    elementList[1].children[0].style.marginLeft = elementList[1].children[1].offsetWidth / 2 -
      spanLength * factorLength / 2 + 'px';
  }


  onSliderChange(event) {
    this.value = this.dialogMethods.thousandSeparatorWithReturn(event.value);
    this.giveValueBack();
  }

  onInputChange(event) {
    this.dialogMethods.thousandSeparator(event);
  }

  validateInput(number) {
    this.formatInput(this.isPercentage);
    number = this.dataService.correctNumbers(number);
    if (this.isPercentage) {
      if (number < this.minimumPercentageValue) {
        this.value = this.dialogMethods.thousandSeparatorWithReturn(this.minimumPercentageValue);
      } else if (number > 100) {
        this.value = this.dialogMethods.thousandSeparatorWithReturn(100);
      }
    } else {
      if (number < this.min) {
        this.value = this.dialogMethods.thousandSeparatorWithReturn(this.min);
      } else if (number > this.max) {
        this.value = this.dialogMethods.thousandSeparatorWithReturn(this.max);
      }
    }
    this.giveValueBack();
  }

  giveValueBack() {
    this.valueChange.emit(this.value);
  }

}
