import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ErrorMessageComponent} from '../dialog/error-message/error-message.component';
import {Router} from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  dialogRef;

  constructor(
    public errorMessage: MatDialog,
    public router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        if (this.dialogRef == null) {
          const dialogConfig = new MatDialogConfig();
          this.dialogRef = this.errorMessage.open(ErrorMessageComponent, dialogConfig);
          this.dialogRef.afterClosed().subscribe(value => {
            if (err.url == null || err.url.endsWith('/calculatePension')) {
              // this.router.navigate(['home']);
              // window.location.reload();
            }
            this.dialogRef = null;
          });
        }
        return throwError(err);
      }));
  }

}
