<div class="dialog">
  <h2 mat-dialog-title>
    <span *ngIf="!einkaufFP" i18n="dialog title|The title for this dialog@@einkaufTitle">Einkauf</span>
    <span *ngIf="einkaufFP" i18n="alternate dialog title|The alternate title for this dialog@@einkaufTitleAlt">Einkauf Frühpensionierung</span>
    <button mat-button mat-dialog-close class="rightBound">
      <mat-icon class="rightBound">
        clear
      </mat-icon>
    </button>
  </h2>
  <hr>
  <span *ngIf="!earlyRetirementBuyIn" i18n="dialog description|The description for this dialog@@einkaufDescription">Mit einem Einkauf in die Pensionskasse können Sie bestehende Vorsorgelücken ganz oder teilweise schliessen. Bitte füllen Sie alle Felder aus und klicken Sie auf «<b>Speichern</b>». </span>
  <span *ngIf="earlyRetirementBuyIn"
        i18n="dialog alternate description|The alternate description for this dialog@@einkaufDescriptionAlt">
    Sie haben mit den bereits erfassten Einkäufen die maximale Einkaufssumme einbezahlt. Es können grundsätzlich keine weiteren Einzahlungen mehr vorgenommen werden.
    <span *ngIf="maxEinkaufEarlyRetirement !== 0">Jedoch haben Sie die Möglichkeit Einkäufe für eine Frühpensionierung zu tätigen. </span>
  </span>


  <form #einkaufForm="ngForm">
    <table>
      <tr class="mat-row">
        <td class="mat-cell" i18n="dialog einkaufTime|first question in einkauf dialog@@einkaufTime">Wann möchten Sie
          den Einkauf tätigen?
        </td>
        <td class="mat-cell"></td>
        <td class="mat-cell" [ngSwitch]="errorInformationMaxEinkauf">
          <mat-icon *ngSwitchCase="'maxBuyInReached'"
                    i18n-matTooltip="einkauf tooltipAlreadyFull|first optional tooltip in einkauf@@einkaufTooltipAlreadyFull"
                    matTooltip="Bereits voll eingekauft." class="warningButton">
            warning
          </mat-icon>
          <mat-icon *ngSwitchCase="'maxDate'"
                    i18n-matTooltip="einkauf tooltipLatestChangeToPensum|second optional tooltip in einkauf@@einkaufTooltipLatestChangeToPensum"
                    matTooltip="Änderungen des Arbeitpensums müssen spätestens bis einen Monat vor Pensionierung stattfinden."
                    class="warningButton">
            warning
          </mat-icon>
          <mat-icon *ngSwitchCase="'chronology'"
                    i18n-matTooltip="einkauf tooltipInvalidScenarioDate|third optional tooltip in einkauf@@einkaufTooltipInvalidScenarioDate"
                    matTooltip="Der Zeitpunkt Ihrer gewünschten BVG-Massnahme verletzt die chronologische Reihenfolge. Passen Sie entweder das Datum dieser Massnahme an oder löschen Sie alle bereits erfassten – später stattfindenden – Szenarien."
                    class="warningButton">
            warning
          </mat-icon>
        </td>
        <td class="mat-cell" align="right">
          <mat-form-field>

            <input matInput [max]="maxDate" [min]="minDate" ngModel name="einkaufsDatum" #ref
                   (dateChange)="dateCheck(ref)" [matDatepicker]="secondPicker" [(ngModel)]="einkaufData.date"
                   i18n-placeholder="datepicker|datepicker in einkauf@@datePicker1Einkauf"
                   placeholder="dd.mm.yyyy" required>
            <mat-datepicker-toggle matSuffix [for]="secondPicker"></mat-datepicker-toggle>
            <mat-datepicker #secondPicker></mat-datepicker>
          </mat-form-field>
        </td>
      </tr>
      <tr class="mat-row"
          *ngIf="(maxEinkauf != null && !dateCheckError) && maxEinkaufError === false && einkaufPossible"
          [@inOutAnimation]>
        <td class="mat-cell" i18n="dialog einkaufAmount|second question in einkauf dialog@@einkaufAmount">
          Wie hoch soll der Einkaufsbetrag sein?
        </td>
        <td class="mat-cell">
          <mat-icon class="coloredIcon"
                    i18n-matTooltip="einkauf tooltipCheckHomePage|first tooltip in einkauf@@einkaufTooltipCheckHomePage"
                    matTooltip="Reglementarische Bestimmungen über die Höhe des Einkaufsbetrags finden Sie auf der Homepage der PK Siemens.
                    Bitte beachten Sie, dass der hier aufgeführte Maximalbetrag keine früheren betätigten WEF-Vorbezüge berücksichtigt.">
            info
          </mat-icon>
        </td>
        <td class="mat-cell"></td>
        <td class="mat-cell" align="right">
          <app-slider [min]="0" [max]="maxEinkauf" [value]="einkaufData.value" (valueChange)="pitch($event)"
                      [isPercentage]="false"
                      [required]="maxEinkauf != null && !dateCheckError && firstEarlyRetirementBuyIn && einkaufPossible"></app-slider>
        </td>
      </tr>


      <tr class="mat-row"
          *ngIf="earlyRetirementBuyIn && firstEarlyRetirementBuyIn && ages.length != 0 && maxEinkaufError === false"
          [@inOutAnimation]>
        <td class="mat-cell" i18n="dialog einkaufEarlyPension|third question in einkauf dialog@@einkaufEarlyPension">
          Möchten Sie sich frühpensionieren lassen, um weitere Einkäufe zu tätigen?
        </td>
        <td class="mat-cell">
          <mat-icon class="coloredIcon"
                    i18n-matTooltip="einkauf tooltipNormalPensionAge|second tooltip in einkauf@@einkaufTooltipNormalPensionAge"
                    matTooltip="Das ordentliche Pensionierungsalter ist 65 Jahre. Sie können sich jedoch für eine vorzeitige Pensionierung bis zum Alter 58 entscheiden.
                    Reglementarische Bestimmungen über den Zeitpunkt der Pensionierung finden Sie auf der Homepage der PK Siemens.">
            info
          </mat-icon>
        </td>
        <td class="mat-cell">
        </td>
        <td class="mat-cell" align="right">
          <mat-radio-group [(ngModel)]="earlyRetirement" name="earlyRetirement" ngModel>
            <mat-radio-button [value]="true" color="primary" i18n="ja|ja@@einkaufJa">Ja</mat-radio-button>
            <!--<span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>-->
            <mat-radio-button [value]="false" color="primary" i18n="nein|nein@@einkaufNein">Nein</mat-radio-button>
          </mat-radio-group>
        </td>
      </tr>

      <tr class="mat-row" *ngIf="earlyRetirement === true && earlyRetirementBuyIn && firstEarlyRetirementBuyIn"
          [@inOutAnimation]>
        <td class="mat-cell" i18n="dialog einkaufEarlyTime|fourth question in einkauf dialog@@einkaufEarlyTime">
          Wann möchten Sie sich fühpensionieren lassen?
        </td>
        <td class="mat-cell">
        </td>
        <td class="mat-cell">
        </td>
        <td class="mat-cell" align="right">
          <mat-label i18n="einkauf placeholderPensionAge|placeholder for pension age@@einkaufPlaceholderPensionAge">
            Pensionierungsalter
          </mat-label>
          <mat-select [(ngModel)]="earlyRetirementAge" ngModel name="pensionAge"
                      disableRipple (valueChange)="setPensionAge($event)"
                      [required]="earlyRetirement === true && earlyRetirementBuyIn && firstEarlyRetirementBuyIn">
            <mat-option *ngFor="let age of ages" [value]="age">
              {{age}}
            </mat-option>
          </mat-select>
        </td>
      </tr>

      <tr class="mat-row"
          *ngIf="((ageSet && earlyRetirement === true && earlyRetirementBuyIn) || (secondERBI === true && dateCheckError === false)) && einkaufPossible"
          [@inOutAnimation]>
        <td class="mat-cell" i18n="dialog einkaufEarlyAmount|fifth question in einkauf dialog@@einkaufEarlyAmount">
          Wie hoch soll der Einkaufsbetrag für die Frühpensionierung sein?
        </td>
        <td class="mat-cell">
          <mat-icon class="coloredIcon"
                    i18n-matTooltip="einkauf tooltipCheckHomePage|third tooltip in einkauf@@einkaufTooltipCheckHomePage2"
                    matTooltip="Reglementarische Bestimmungen über die Höhe des Einkaufsbetrags finden Sie auf der Homepage der PK Siemens.
                    Bitte beachten Sie, dass der hier aufgeführte Maximalbetrag keine früheren betätigten WEF-Vorbezüge berücksichtigt.">
            info
          </mat-icon>
        </td>
        <td class="mat-cell">
        </td>
        <td class="mat-cell" align="right">
          <app-slider class="secondSlider" [min]="0" [max]="maxEinkaufEarlyRetirement" [value]="einkaufEarlyRetirement"
                      (valueChange)="pitchEarlyRetirement($event)" [isPercentage]="false"
                      [required]="ageSet"></app-slider>
        </td>
      </tr>
    </table>
  </form>


  <app-save-button
    [disabledCondition]="(!einkaufForm.valid || einkaufData.value === 0|| maxEinkaufError === true) && this.secondERBI === false || (secondERBI === true && einkaufEarlyRetirement === 0 && this.earlyRetirement === true)"
    [form]="einkaufForm"
    (firedEvent)="logdata()"
  ></app-save-button>

</div>
