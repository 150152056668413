import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css', '../dialog.css']
})
export class VideoComponent implements OnInit {
  link = 'https://player.vimeo.com/video/325432578'; // TODO: replace with correct link
  linkFR = 'https://player.vimeo.com/video/326819260';
  safeUrl: SafeUrl;
  french = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public domSanitizer: DomSanitizer,
              private platformLocation: PlatformLocation) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
  }

  ngOnInit() {
    if (!this.french) {
      this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.link);
    } else {
      this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.linkFR);
    }
  }
}
