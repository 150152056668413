import {EventEmitter, Injectable} from '@angular/core';
import {BackendConnectorService} from './backend-connector.service';
import {BackendInput} from '../data-type/input/BackendInput';
import {BackendOutput} from '../data-type/output/BackendOutput';
import {CashFlow} from '../data-type/input/CashFlow';
import {ScenarioInput, ScenarioType} from '../data-type/input/ScenarioInput';
import {DialogMethodsService} from './dialog-methods.service';
import {HttpResponse} from '@angular/common/http';
import {PensionPlan} from '../data-type/input/PensionPlan';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public backendConnectorService: BackendConnectorService,
    public dialogMethods: DialogMethodsService) {
  }

  dataList: BackendInput;
  outputList: EventEmitter<BackendOutput> = new EventEmitter();
  validationList: EventEmitter<BackendOutput> = new EventEmitter();
  testValidationList: EventEmitter<BackendOutput> = new EventEmitter();
  scenarioCount = 0; // position where next scenario is placed
  cashflowCount = -1;
  scenario: ScenarioInput = {
    type: ScenarioType.PensumReduction,
    date: null,
    id: null,
    cashflows: null
  };
  testScenarios: ScenarioInput[];
  testPensionAge: number;
  today = new Date();
  highestDate: Date;
  scenarios: ScenarioInput[];
  cashflows: CashFlow[];
  saldoWEF = 0;
  latestPensum: number[] = [];
  latestPlan: PensionPlan[] = [];
  latestPensionAge: number[] = []; // contains values for each einkauf and each pensionierung
  latestTPdate: String[] = []; // contains the latest date of a partpension, if CF not partpension, empty string
  tpCount = 0;
  public erbiCount = 0;
  public IE: boolean;
  eventList: Array<{
    year: string, events: Array<{ icon: string, name: string, value?: any, half: number }>,
    scenario: Array<ScenarioInput>
  }> = Array();

  updateFormicon = new EventEmitter();

  correctNumbers(value): number {
    if (value != null) {
      value = value.toString().split('\'').join('');
      return Number(value);
    } else {
      return value;
    }
  }

  // set formatted EventList from vorsorge
  setList(list): void {
    this.eventList = list;
  }

  // access said list from scenario-info component
  getList() {
    return this.eventList;
  }

  getData() {
    return (this.dataList);
  }

  setData(input: BackendInput) {
    this.dataList = input;
    this.highestDate = input.insuranceCardDate;
    this.scenarios = [];
    // reset all values set during scenario adding
    this.scenarioCount = 0;
    this.saldoWEF = 0;
    this.latestPlan = [input.pensionPlan];
    this.latestPensum = [input.pensum * 100];
    this.latestPensionAge = [65];
    this.latestTPdate = [];
    this.getOutputList();
  }

  public getOutputList() {
    if (this.getData().cashFlows != null && this.getData().cashFlows.length > 0) {
    }
    const data = this.prepareData(this.getData(), this.getScenarios(), this.getScenarioCount());
    this.backendConnectorService.requestPensionCalculatorService(data).subscribe((output: any) => {
      this.outputList.emit(output);
    });
  }

  public getReportOutput(french: Boolean) {
    const data = this.prepareData(this.getData(), this.getScenarios(), this.getScenarioCount());
    if (french) {
      data.frenchReport = true;
    }
    this.backendConnectorService.requestReportService(data).subscribe(
      (output: HttpResponse<Blob>) => {
        const fileName = 'report.pdf';
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(output.body, fileName);
        } else {
          const fileURL = URL.createObjectURL(output.body);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(fileURL);
        }
      });
  }

  public getValidationList() {
    const data = this.prepareData(this.getData(), this.scenarios, this.scenarioCount);
    this.backendConnectorService.requestMaximumBuyInService(data)
      .subscribe((output: any) => {
        this.validationList.emit(output);
      });
  }

  public getTestValidationList() {
    let data = JSON.parse(JSON.stringify(this.getData()));
    data = this.prepareData(data, this.testScenarios, this.scenarioCount + 1);
    if (this.testPensionAge) {
      data.pensionAge = this.testPensionAge;
    } else {
      data.pensionAge = JSON.parse(JSON.stringify(this.getPensionAge()));
    }
    this.backendConnectorService.requestMaximumBuyInService(data)
      .subscribe((output: any) => {
        this.testValidationList.emit(output);
      });
    if (this.testScenarios[this.testScenarios.length - 1].type === 4 || this.testScenarios[this.testScenarios.length - 1].type === 4 ||
      this.testScenarios[this.testScenarios.length - 1].type === 5 || this.testScenarios[this.testScenarios.length - 1].type === 2) {
      this.testScenarios = this.testScenarios.splice(-1, 1);
    }
    this.testScenarios = this.testScenarios.splice(-1, 1); // todo: fix issue with several validations (always index 0 used)
    this.testPensionAge = null;
  }

  setScenario(input: ScenarioInput) {
    this.scenarios[this.scenarioCount] = input;
    this.scenarios[this.scenarioCount].id = this.scenarioCount;
    this.scenarioCount++;
    this.highestDate = new Date(input.date);
    this.highestDate.setDate(this.highestDate.getDate() + 1);
    if (input.type === ScenarioType.PartRetirement
      || input.type === ScenarioType.EarlyRetirementBuyIn
      || input.type === ScenarioType.PensumReduction) {
      this.latestTPdate.push(this.dialogMethods.correctDate(input.date));
    }
    this.getOutputList();
    this.getValidationList();
  }

  getHighestDate() {
    return this.dialogMethods.correctDate(this.highestDate);
  }

  setScenarios(scenarios: ScenarioInput[]) {
    this.scenarios = scenarios;
    this.setScenarioCount(scenarios.length);
    this.getOutputList();
    this.getValidationList();
  }

  getScenarios(): ScenarioInput[] {
    return this.scenarios;
  }

  setTestScenario(input: ScenarioInput, pensionAge?: number) {
    if (pensionAge) {
      this.testPensionAge = pensionAge;
    }
    input.id = this.scenarioCount;
    this.testScenarios = this.getScenarios();
    this.testScenarios[this.scenarioCount] = input;

    this.getTestValidationList();
  }

  getPensionAge(): number {
    if (this.latestPensionAge != null && this.latestPensionAge.length > 0) {
      return this.latestPensionAge[this.latestPensionAge.length - 1];
    } else {
      this.latestPensionAge = [65];
      return 65;
    }
  }

  setPensionAge(pensionAge: number) {
    if (typeof pensionAge === 'string') {
      pensionAge = parseInt(pensionAge, 10);
    }
    this.latestPensionAge.push(pensionAge);
  }

  getScenarioCount(): number {
    return this.scenarioCount;
  }

  setScenarioCount(scenarioCount) {
    this.scenarioCount = scenarioCount;
  }

  prepareData(outputList: BackendInput, scenarios: ScenarioInput[], scenarioCount: number): BackendInput {
    if (outputList) {
      outputList.pensionAge = this.getPensionAge();
      outputList.dateOfBirth = this.dialogMethods.correctDate(outputList.dateOfBirth);
      outputList.insuranceCardDate = this.dialogMethods.correctDate(outputList.insuranceCardDate);
      outputList.currentRetirementAsset = this.correctNumbers(outputList.currentRetirementAsset);
      outputList.annualSalary = this.correctNumbers(outputList.annualSalary);
      outputList.singlePremium = this.correctNumbers(outputList.singlePremium);
      outputList.cashFlows = this.prepareCashflows(scenarios);
      return outputList;
    } else {
    }
  }

  prepareCashflows(scenarios: ScenarioInput[]): CashFlow[] {
    const cashflows = [];
    if (scenarios) {
      this.cashflowCount = -1;
      for (const i of scenarios) {
        i.cashflows.forEach(cashflow => {
          cashflow.date = this.dialogMethods.correctDate(cashflow.date);
          cashflow.value = this.correctNumbers(cashflow.value);
          if (cashflow.salaryChange) {
            cashflow.salaryChange = this.correctNumbers(cashflow.salaryChange);
          }
          if (cashflow.vestedBenefit) {
            cashflow.vestedBenefit = this.correctNumbers(cashflow.vestedBenefit);
          }
          cashflows.push(cashflow);
          this.cashflowCount++;
        });
      }
    }
    return cashflows;
  }

  deleteScenario() {
    const scenarioArray = this.getScenarios();
    const latestScenario: ScenarioInput = scenarioArray[this.getScenarioCount() - 1];

    if (latestScenario.type === ScenarioType.PartRetirement || latestScenario.type === ScenarioType.PartRetirementBezug ||
      latestScenario.type === ScenarioType.PensumReduction) {
      /*If a Teilpensionierung id deleted, the tpCound needs resetting and the latest pensum needs to be removed*/
      if (latestScenario.type !== ScenarioType.PensumReduction) {
        this.tpCount--;
      }
      this.latestTPdate.pop();
      this.latestPensum.pop();
    } else if (latestScenario.type === ScenarioType.EarlyRetirementBuyIn || latestScenario.type === ScenarioType.EarlyRetirement) {
      if (this.erbiCount > 0) {
        /*If it's only a additinal EarlyRetirementBuyIn Scenario*/
        this.erbiCount--;
      }
      this.latestPensionAge.pop();
    } else if (latestScenario.type === ScenarioType.WEFpayback || latestScenario.type === ScenarioType.WEF) {
      this.saldoWEF -= latestScenario.cashflows[0].value;
    } else if (latestScenario.type === ScenarioType.Retirement) {
      this.latestPensionAge.pop();
    } else if (latestScenario.type === ScenarioType.PlanChange) {
      this.latestPlan.pop();
    }

    /*If the deleted Scenario is the only Scenario, special resetting is needed*/
    if (this.getScenarioCount() === 1) {
      this.highestDate = this.dataList.insuranceCardDate;
      this.setData(this.getData());
    } else {
      /*Reset Highest Date*/
      this.highestDate = scenarioArray[scenarioArray.length - 2].date;
    }

    scenarioArray.pop();
    if (this.getScenarios().length - scenarioArray.length === 1) {
    }

    /*set new ScenarioArray as old ScenarioArray*/
    this.setScenarios(scenarioArray);
  }

  dateValidator(input: string): Array<any> {
    let valid: boolean;
    let errorInformation: string;
    let arguable: boolean;
    /*arguable is an property which defines wheter or not valid can be overwritten within the custom validations*/

    /*create arrays with date in them for comparison, dateArr must be reversed to acheive YYYY/MM/DD format */
    const inputDate: string[] = input.split('.').reverse();
    const highestDate: string[] = this.dialogMethods.correctDate(this.getHighestDate()).split('-');
    const dateOfBirth: string[] = this.dialogMethods.correctDate(this.dataList.dateOfBirth).split('-');

    const dateArr: number[] = [];
    for (const i of inputDate) {
      dateArr.push(parseInt(i, 10));
    }
    /*the input may register as just the year, therefore we need to push the remaining values for month and day manually*/
    if (dateArr.length === 1) {
      dateArr.push(1);
      dateArr.push(1);
    }
    const highestDateArr: number[] = [];
    for (const i of highestDate) {
      highestDateArr.push(parseInt(i, 10));
    }
    const dateOfBirthArr: number[] = [];
    for (const i of dateOfBirth) {
      dateOfBirthArr.push(parseInt(i, 10));
    }

    /*Validation of entered date*/

    if (/*the earliest possible year is later than the entered year */
      (highestDateArr[0] > dateArr[0]) ||
      /*entered date has same year as earliest, but earlier month*/
      ((highestDateArr[0] === dateArr[0]) && (highestDateArr[1] > dateArr[1])) ||
      /*entered date has same year and month as earliest, but earlier day*/
      ((highestDateArr[0] === dateArr[0]) && (highestDateArr[1] === dateArr[1]) && (highestDateArr[2] > dateArr[2]))) {
      errorInformation = 'chronology';
      valid = false;
      arguable = true;

    } else if (dateArr[0] > dateOfBirthArr[0] + this.getPensionAge()) {
      /*enterd Date is after Retirement*/
      errorInformation = 'maxDate';
      valid = false;
      arguable = false;

    } else {
      errorInformation = '';
      valid = true;
      arguable = true;
    }
    return ([valid, errorInformation, dateArr, highestDateArr, dateOfBirthArr, arguable]);
  }

  formIcon() {
    this.updateFormicon.emit();
  }


  // set to start configuration, delete everything
}
