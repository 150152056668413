import {CashFlow} from './CashFlow';
import {PensionPlan} from './PensionPlan';
import {Gender} from './Gender';

export class BackendInput {
  dateOfBirth: any;
  annualSalary: number;
  currentRetirementAsset: number;
  singlePremium?: number;
  pensionPlan: PensionPlan;
  gender: Gender;
  pensionAge?: number;
  insuranceCardDate: any;
  projectionInterest?: number;
  projectionInterestFirstYear?: number;
  salaryGrowth?: number;
  pensum?: number;
  cashFlows?: CashFlow[];
  frenchReport?: boolean;

  constructor() {
  }
}
