import {Component, HostListener} from '@angular/core';
import {DateAdapter} from '@angular/material';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {PlatformLocation} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  data: any = {};
  title = 'pksgs';
  french = false;
  tooSmall = false;

  constructor(private dateAdapter: DateAdapter<Date>,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private platformLocation: PlatformLocation,
              private router: Router
  ) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
    if (this.french) {
      this.dateAdapter.setLocale('fr-CH');
    } else {
      this.dateAdapter.setLocale('de-CH');
    }
    this.matIconRegistry.addSvgIcon(
      'newMoney',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/coin-money-6.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'newOld',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/oldie-mac-oldface.svg')
    );
    this.tooSmall = window.innerWidth < 480;

    router.events.subscribe(
      value => {
        if (value instanceof NavigationEnd) {
          gtag('config', 'G-NTH4X77H8N',
            {
              page_path: value.urlAfterRedirects
            }
          );
        }
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.tooSmall = window.innerWidth < 480;
  }

}
