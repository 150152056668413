import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './display/home/home.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {DateAdapter, MatButtonModule, MatDialogModule, MatDividerModule, MatNativeDateModule} from '@angular/material';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VorsorgeComponent} from './display/vorsorge/vorsorge.component';
import {DisclaimerComponent} from './dialog/disclaimer/disclaimer.component';
import {EinkaufComponent} from './dialog/einkauf/einkauf.component';
import {WEFComponent} from './dialog/wef/wef.component';
import {InvalidComponent} from './display/invalid/invalid.component';
import {PensionierungComponent} from './dialog/pensionierung/pensionierung.component';
import {ArbeitspensumComponent} from './dialog/arbeitspensum/arbeitspensum.component';
import {GrunddatenComponent} from './dialog/grunddaten/grunddaten.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ScenarioSelectionComponent} from './dialog/scenario-selection/scenario-selection.component';
import {ScenarioInfoComponent} from './dialog/scenario-info/scenario-info.component';
import {SliderComponent} from './core/slider/slider.component';
import {FormIconComponent} from './core/form-icon/form-icon.component';
import {VideoComponent} from './dialog/video/video.component';
import {DateAdapterService} from './service/date-adapter.service';
import {PlanComponent} from './dialog/plan/plan.component';
import {RequestInterceptor} from './core/RequestInterceptor';
import {ErrorMessageComponent} from './dialog/error-message/error-message.component';
import {SaveButtonComponent} from './core/save-button/save-button.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const appRoutes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'vorsorge', component: VorsorgeComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', component: InvalidComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    InvalidComponent,
    HomeComponent,
    VorsorgeComponent,
    GrunddatenComponent,
    DisclaimerComponent,
    EinkaufComponent,
    PensionierungComponent,
    ArbeitspensumComponent,
    WEFComponent,
    ScenarioSelectionComponent,
    ScenarioInfoComponent,
    SliderComponent,
    FormIconComponent,
    VideoComponent,
    PlanComponent,
    ErrorMessageComponent,
    SaveButtonComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatIconModule,
    CommonModule,
    MatExpansionModule,
    MatSelectModule,
    MatInputModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    HttpClientModule,
    MatSlideToggleModule
  ],
  exports: [
    GrunddatenComponent,
    HomeComponent,
    HttpClientModule,
    MatExpansionModule,
    InvalidComponent,
    MatDialogModule,
    MatSliderModule
  ],
  providers: [
    {provide: DateAdapter, useClass: DateAdapterService},
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [GrunddatenComponent, VorsorgeComponent, DisclaimerComponent, InvalidComponent, EinkaufComponent,
    PensionierungComponent, ArbeitspensumComponent, WEFComponent, ScenarioSelectionComponent,
    ScenarioInfoComponent, VideoComponent, PlanComponent, ErrorMessageComponent]
})
export class AppModule {
}
