<!-- resize chart  -->
<div class="elementContainer" (window:resize)=ngAfterViewInit()>
  <div class="header">
    <div class="menuBar">
      <h2 class="title" i18n="vorsorge title|The title for this subpage@@vorsorgeTitle">
        Ihre persönliche Vorsorgesituation
      </h2>
      <div class="navigationButtons">
        <div class="container">
          <button class="button firstButton" (click)="openVideo()">
            <i class="hybridIcon material-icons icon align-icon" id="video">
              video_library
            </i>
            <p class="headerButtonText" i18n="vorsorge navigation|video button name@@vorsorgeVideo">
              Video
            </p>
          </button>
        </div>
        <div class="container">
          <button class="button secondButton" (click)="getReport()">
            <i class="hybridIcon material-icons icon align-icon" id="report">
              file_copy
            </i>
            <p class="headerButtonText" i18n="vorsorge navigation|pdf button name@@vorsorgePDF">
              PDF&nbsp;Report
            </p>
          </button>
        </div>
        <div class="container">
          <button
            i18n-matTooltip="vorsorge tooltipChangeGrunddaten|first obligatory tooltip in vorsorge@@vorsorgeTooltipChangeGrunddaten"
            matTooltip="Grunddaten mutieren (z.B. Beschäftigungsgrad, anrechenbarer Lohn, Altersguthaben, Sparplan, Projektionszins)."
            (click)="openGrunddaten()" class="button thirdButton" [disabled]="dataService.scenarioCount > 0">
            <i class="hybridIcon material-icons icon align-icon" id="create">
              create
            </i>
            <p class="headerButtonText" i18n="vorsorge navigation|grunddaten button name@@vorsorgeGrunddaten">
              Grunddaten
            </p>
          </button>
        </div>
        <div class="container">
          <button
            i18n-matTooltip="vorsorge tooltiSzenarioSelection| tooltip to Szenario Button@@vorsorgeTooltipSzenarioSelection"
            matTooltip="Nach der Eingabe eines Szenarios können die Grunddaten nicht mehr verändert werden. Löschen Sie alle Szenarien, um die Grunddaten zu bearbeiten."
            (click)="openScenarioSelection()" class="button fourthButton">
            <i class="hybridIcon material-icons icon align-icon" id="settings">
              settings
            </i>
            <p class="headerButtonText" i18n="vorsorge navigation|scenario button name@@vorsorgeScenario">
              Szenario
            </p>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="descriptiveText">
    <p i18n="vorsorge description|The description for this subpage@@vorsorgeDescription">
      Hier sehen Sie, wie sich Ihr Sparguthaben in Zukunft entwickelt und welche Vorsorgeleistungen Sie im Alter 65, bei
      Invalidität und Tod erwarten können.
      Klicken Sie auf das Feld «Szenario» oben rechts und simulieren Sie verschiedene Vorsorgeszenarien (Einkauf,
      Planwechsel,
      Vorbezug, neues Arbeitspensum oder Frühpensionierung).
    </p>
  </div>
  <button [style.display]="displayStandard1" mat-flat-button class="icon mini switchButton" (click)="switchContent(1)"
          id="switchbutton1" i18n="vorsorge switch|Switch to Details from Charts@@vorsorgeDetails">Detailansicht<i
    class='material-icons' id="detail-table">search</i></button>
  <button [style.display]="displayStandard2" mat-flat-button class="icon mini switchButton" (click)="switchContent(1)"
          id="switchbutton2" i18n="vorsorge switch|Switch to Charts from Details@@vorsorgeCharts">Chartansicht <i
    class='material-icons' id="line-chart">bar_chart</i></button>
  <div class="noWrap" #noWrap>
    <div class="lineChartContainer standard" #lineChartContainer>
      <button #infoDisappear [style.display]="displayStandard1" mat-icon-button
              i18n-matTooltip="vorsorge tooltipEvolutionSaved|second obligatory tooltip in vorsorge@@vorsorgeTooltipEvolution"
              matTooltip="Entwicklung Ihres Sparguthabens: Die Grafik zeigt, wie sich Ihr Sparguthaben über die Zeit entwickelt.
              Das Sparguthaben entspricht dem Kapital, das zum Zeitpunkt Ihrer ordentlichen Pensionierung in eine Altersrente umgewandelt wird (Summe aller Sparbeiträge, Einlagen und Einkäufe inkl. Verzinsung). "
              disabled class="infoIcon black chartInfo">
        <div class="iconTooltip firstTooltip" i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeSavedTooltip">
          Entwicklung Ihres Sparguthabens: Die Grafik zeigt, wie sich Ihr Sparguthaben über die Zeit entwickelt. Das
          Sparguthaben entspricht dem Kapital, das zum Zeitpunkt Ihrer ordentlichen Pensionierung in eine Altersrente
          umgewandelt wird (Summe aller Sparbeiträge, Einlagen und Einkäufe inkl. Verzinsung).
        </div>
        <i
          i18n-matTooltip="vorsorge tooltipEvolutionSavedExplanation|third obligatory tooltip in vorsorge@@vorsorgeTooltipEvolutionSavedExplanation"
          matTooltip="Entwicklung Ihres Sparguthabens: Die Grafik zeigt, wie sich Ihr Sparguthaben über die Zeit entwickelt.
              Das Sparguthaben entspricht dem Kapital, das zum Zeitpunkt Ihrer ordentlichen Pensionierung in eine Altersrente umgewandelt wird (Summe aller Sparbeiträge, Einlagen und Einkäufe inkl. Verzinsung). "
          class="material-icons infoIcon black rotated">
          error
        </i>
      </button>
      <h3 class="chartTitle" [style.display]="displayStandard1" #chartlegend1
          i18n="vorsorge subtitle|The subtitle for this element@@vorsorgeSavedEvolution">
        Entwicklung Ihres Sparguthabens
      </h3>
      <h3 class="chartTitle noDisplay" [style.display]="displayStandard2" #tablelegend
          i18n="vorsorge menuTitle|Active element menu@@vorsorgeDetailsTitle">
        Detailansicht
      </h3>
      <div [style.display]="displayStandard1" class="chart" id="chart1" #chart1>
      </div>
      <!--required -->
      <div id="table1" [style.display]="displayStandard2" #table1>
        <table class="tableM">
          <tr>
            <th class="bold textLeft" i18n="vorsorge year|Current year@@vorsorgeCurrentYear">Aktuelles
              Jahr {{dataService.today.getFullYear()}}</th>
            <th class="td-spacing"></th>
            <th class="bold textLeft"></th>
          </tr>
          <tr>
            <td class="lefttable"
                i18n="vorsorge savedAtBeginning|Saved money at beginning of year@@vorsorgeSavedAtBeginning">Sparguthaben
              am Jahresanfang
            </td>
            <td class="td-spacing"></td>
            <td class="righttable">CHF {{retirementAssetYearBegin}}</td>
          </tr>
          <tr>
            <td class="lefttable"><span class="iconText2"
                                        i18n="vorsorge employeeCont|The employee's contribution@@vorsorgeEmployeeCont">Arbeitnehmerbeitrag</span>
              <button mat-icon-button disabled class="infoIcon black">
                <i
                  i18n-matTooltip="vorsorge tooltipAnnualEmployee|fourth obligatory tooltip in vorsorge@@vorsorgeTooltipAnnualEmployee"
                  matTooltip="Jährlicher Sparbeitrag des Arbeitnehmers in CHF. Die Arbeitnehmersätze werden in % des versicherten Lohns angegeben und sind im Vorsorgereglement definiert."
                  class="material-icons infoIcon rotated tableIcon"> error </i>
                <div class="iconTooltip secondTooltip"
                     i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeSavedEmployeeTooltip">
                  Jährlicher Sparbeitrag des Arbeitnehmers in CHF. Die Arbeitnehmersätze werden in % des versicherten
                  Lohns angegeben und sind im Vorsorgereglement definiert.
                </div>
              </button>
            </td>
            <td class="td-spacing"></td>
            <td class="righttable">CHF {{employeeContribution}}</td>
          </tr>
          <tr>
            <td class="lefttable"><span class="iconText2"
                                        i18n="vorsorge employerCont|The employer's contribution@@vorsorgeEmployerCont">Arbeitgeberbeitrag </span>
              <button mat-icon-button disabled class="infoIcon black">
                <i
                  i18n-matTooltip="vorsorge tooltipAnnualEmployer|fifth obligatory tooltip in vorsorge@@vorsorgeTooltipAnnualEmployer"
                  matTooltip="Jährlicher Sparbeitrag des Arbeitgebers in CHF. Die Arbeitgebersätze werden in % des versicherten Lohns angegeben und sind im Vorsorgereglement definiert."
                  class="material-icons infoIcon rotated tableIcon tableIcon">error</i>
                <div class="iconTooltip secondTooltip"
                     i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeSavedEmployerTooltip">
                  Jährlicher Sparbeitrag des Arbeitgebers in CHF. Die Arbeitgebersätze werden in % des versicherten
                  Lohns angegeben und sind im Vorsorgereglement definiert.
                </div>
              </button>
            </td>
            <td class="td-spacing"></td>
            <td class="righttable">CHF {{employerContribution}}</td>
          </tr>
          <tr>
            <td class="lefttable"><span class="iconText2"
                                        i18n="vorsorge interestCredit|The interest credit@@vorsorgeInterestCredit">Zinsgutschrift</span>
              <button mat-icon-button disabled class="infoIcon black">
                <i
                  i18n-matTooltip="vorsorge tooltipEstimateInterest|sixth obligatory tooltip in vorsorge@@vorsorgeTooltipEstimateInterest"
                  matTooltip="Annahme zur jährlichen Verzinsung des Sparguthabens bis zum Pensionierungsalter. Die effektive Verzinsung wird jährlich durch den Stiftungsrat festgelegt.
            " class="material-icons infoIcon black rotated tableIcon">
                  error
                </i>
                <div class="iconTooltip secondTooltip"
                     i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeSavedUntilPensionTooltip">
                  Annahme zur jährlichen Verzinsung des Sparguthabens bis zum Pensionierungsalter. Die effektive
                  Verzinsung wird jährlich durch den Stiftungsrat festgelegt.
                </div>
              </button>
            </td>
            <td class="td-spacing"></td>
            <td class="righttable">CHF {{interestContribution}}</td>
          </tr>
          <tr>
            <td class="lefttablelast"><span class="iconText2"
                                            i18n="vorsorge savedEnd|Money saved by the end of the year@@vorsorgeSavedEnd">Sparguthaben am Jahresende</span>
              <button mat-icon-button disabled class="infoIcon black">
                <i
                  i18n-matTooltip="vorsorge tooltipSavedEnd|seventh obligatory tooltip in vorsorge@@vorsorgeTooltipSavedEnd"
                  matTooltip="Das Sparguthaben am Jahresende ergibt sich aus der Summe des Sparguthabens am Jahresanfang, dem Arbeitnehmerbeitrag, dem Arbeitgeberbeitrag und der Zinsgutschrift.
            " class="material-icons infoIcon black rotated tableIcon">
                  error
                </i>
                <div class="iconTooltip secondTooltip"
                     i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeSavedEndTooltip">
                  Das Sparguthaben am Jahresende ergibt sich aus der Summe des Sparguthabens am Jahresanfang, dem
                  Arbeitnehmerbeitrag, dem Arbeitgeberbeitrag und der Zinsgutschrift.
                </div>
              </button>
            </td>
            <td class="td-spacing"></td>
            <td class="righttablelast">CHF {{retirementAssetYearEnd}}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th class="bold textLeft" i18n="vorsorge casePension65|If pension age is 65 then@@vorsorgeCasePension65">Bei
              Pensionierung im Alter 65
            </th>
            <th class="fillerTh"></th>
            <th class="righttable"></th>
          </tr>
          <tr>
            <td class="lefttable" i18n="vorsorge savedCredit|Saved credit@@vorsorgeSavedCredit">Sparguthaben</td>
            <td class="td-spacing"></td>
            <td class="righttable">CHF {{retirementAssetPension}}</td>
          </tr>
          <tr *ngIf="joiningBefore">
            <td class="lefttable" i18n="vorsorge singleIn|Single in@@vorsorgeSingleIn">Einmaleinlage</td>
            <td class="td-spacing"></td>
            <td class="righttable">CHF {{singlePremium}}</td>
          </tr>
          <tr>
            <td class="lefttable"><span class="icontext2 secondTable"
                                        i18n="vorsorge convRate|Conversion rate@@vorsorgeConvRate">Umwandlungssatz</span>
              <button mat-icon-button disabled class="infoIcon">
                <i
                  i18n-matTooltip="vorsorge tooltipExchange|eighth obligatory tooltip in vorsorge@@vorsorgeTooltipExchange"
                  matTooltip="Der Umwandlungssatz ist der Prozentsatz des Sparguthabens, der dem Altersrentner jährlich als Rente ausbezahlt wird.
                " class="material-icons infoIcon secondTableIcon rotated">
                  error
                </i>
                <div class="iconTooltip thirdTooltip"
                     i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeConvRateTooltip">
                  Der Umwandlungssatz ist der Prozentsatz des Sparguthabens, der dem Altersrentner jährlich als Rente
                  ausbezahlt wird.
                </div>
              </button>
            </td>
            <td class="td-spacing"></td>
            <td class="righttable">{{conversionRate}}%</td>
          </tr>
          <tr>
            <td class="lefttablelast"><span class="icontext2 secondTable"
                                            i18n="vorsorge oldAgeRent|The rent received by old age@@vorsorgeOldAgeRent">Al&shy;ters&shy;ren&shy;te</span>
              <button mat-icon-button disabled class="infoIcon">
                <i
                  i18n-matTooltip="vorsorge tooltipOldAgeRent|tenth obligatory tooltip in vorsorge@@vorsorgeTooltipOldAgeRent"
                  matTooltip="Die Altersrente berechnet sich aus der Multiplikation des Sparguthabens mit dem Umwandlungssatz."
                  class="material-icons secondTableIcon infoIcon rotated">
                  error
                </i>
                <div class="iconTooltip thirdTooltip"
                     i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeOldAgeRentTooltip">
                  Die Altersrente berechnet sich aus der Multiplikation des Sparguthabens mit dem Umwandlungssatz.
                </div>
              </button>
            </td>
            <td class="td-spacing"></td>
            <td class="righttablelast">CHF {{retirementPension}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="barChartContainer standard" #barChartContainer>
      <button mat-icon-button
              i18n-matTooltip="vorsorge tooltipExpectedServices|eleventh obligatory tooltip in vorsorge@@vorsorgeTooltipExpectedServices"
              matTooltip="Erwartete Leistungen in CHF pro Jahr: Die Grafik zeigt Ihre jährliche und bis zum Lebensende geschuldete ordentliche  Altersrente in CHF. Zudem werden die aktuellen Leistungen bei Invalidität (100%) und Tod dargestellt. "
              matTooltipClass="mat-tooltip-class"
              disabled class="infoIcon chartInfo barChartIcon">
        <i
          i18n-matTooltip="vorsorge tooltipExpectedServices|eleventh obligatory tooltip in vorsorge@@vorsorgeTooltipExpectedServices"
          matTooltip="Erwartete Leistungen in CHF pro Jahr: Die Grafik zeigt Ihre jährliche und bis zum Lebensende geschuldete ordentliche  Altersrente in CHF. Zudem werden die aktuellen Leistungen bei Invalidität (100%) und Tod dargestellt.
        " class="material-icons infoIcon rotated">
          error
        </i>
        <div class="iconTooltip firstTooltip"
             i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeExpectedServicesTooltip">
          Erwartete Leistungen in CHF pro Jahr: Die Grafik zeigt Ihre jährliche und bis zum Lebensende geschuldete
          ordentliche Altersrente in CHF. Zudem werden die aktuellen Leistungen bei Invalidität (100%) und Tod
          dargestellt.
        </div>
      </button>
      <h3 class="chartTitle" i18n="vorsorge expectedServices|The expected service amounts@@vorsorgeExpectedServices">
        Erwartete Leistungen in CHF pro Jahr
      </h3>
      <div class="chart" id="chart2" #chart2>
      </div>
    </div>
  </div>
  <button [style.display]="displayScenario1" mat-flat-button class="icon mini switchButton" (click)="switchContent(2)"
          id="buttonswitch2"
          i18n="vorsorge scenarioSwitch|Switch to Details from Charts in Scenario@@vorsorgeScenarioDetails">
    Detailansicht<i class="material-icons" id="detail-table">search</i></button>
  <button [style.display]="displayScenario2" mat-flat-button class="icon mini switchButton" (click)="switchContent(2)"
          id="buttonswitch2"
          i18n="vorsorge scenarioSwitch|Switch to Charts from Details in Scenario@@vorsorgeScenarioCharts">Chartansicht
    <i class="material-icons" id="line-chart">bar_chart</i></button>
  <div class="noWrapScenario" #noWrapScenario>
    <div class="lineChartContainer scenario" id="clickboxContainer" #clickboxContainer>
      <button id="info-disappear1" #refButton [style.display]="displayScenario1" mat-icon-button
              i18n-matTooltip="vorsorge tooltipExpectedEvolution|twelveth obligatory tooltip in vorsorge@@vorsorgeTooltipExpectedEvolution"
              matTooltip="Die erwartete Entwicklung Ihres Sparguthabens über den Zeitverlauf wird dargestellt.
    Das Sparguthaben entspricht dem zur Verrentung zu Verfügung stehenden Kapital eines Versicherten.
    Das Sparguthaben entspricht der Summe aus dem Total der jährlichen Sparbeiträge sowie der Einlagen und Einkaufszahlungen inkl. Verzinsung."
              disabled class="infoIcon">
        <i
          i18n-matTooltip="vorsorge tooltipExpectedEvolutionSaved|thirteenth obligatory tooltip in vorsorge@@vorsorgeTooltipExpectedEvolutionSaved"
          matTooltip="Die erwartete Entwicklung Ihres Sparguthabens über den Zeitverlauf wird dargestellt.
      Das Sparguthaben entspricht dem zur Verrentung zu Verfügung stehenden Kapital eines Versicherten.
      Das Sparguthaben entspricht der Summe aus dem Total der jährlichen Sparbeiträge sowie der Einlagen und Einkaufszahlungen inkl. Verzinsung."
          class="material-icons infoIcon rotated">
          error
        </i>
        <div class="iconTooltip firstTooltip"
             i18n="vorsorge tooltip|The tooltip for this icon@@vorsorgeSavedOverTimeTooltip">
          Die erwartete Entwicklung Ihres Sparguthabens über den Zeitverlauf wird dargestellt. Das Sparguthaben
          entspricht dem zur Verrentung zu Verfügung stehenden Kapital eines Versicherten. Das Sparguthaben entspricht
          der Summe aus dem Total der jährlichen Sparbeiträge sowie der Einlagen und Einkaufszahlungen inkl. Verzinsung.
        </div>
      </button>
      <h3 class="chartTitle" id="chartlegend3" [style.display]="displayScenario1"
          i18n="vorsorge expectedServicesScenarioTitle|Title for expected services in scenario@@vorsorgeExpectedServicesScenarioTitle">
        Entwicklung Ihres Sparguthabens
      </h3>
      <h3 class="chartTitle noDisplay" id="tablelegend1" [style.display]="displayScenario2"
          i18n="vorsorge serviceComparision|Comparing services@@vorsorgeServiceComparision">
        Leistungsvergleich
        <button id="info-leistungsvergleich" [style.display]="displayScenario2" mat-icon-button disabled
                class="infoIcon chartInfo">
          <i
            i18n-matTooltip="vorsorge tooltipServiceComparision|fourteenth obligatory tooltip in vorsorge@@vorsorgeTooltipServiceComparision"
            matTooltip="Ein Leistungsvergleich Ihrer aktuellen Vorsorgesituation mit dem eingegebenen Szenario ist aufgeführt.
        Die jährliche und bis zum Lebensende des Versicherten geschuldete Altersrente in CHF ab Pensionierung wird dargestellt sowie aktuelle Leistungen im Fall einer Invalidität (100%) und die Ehegattenrente im Todesfall."
            class="material-icons infoIcon rotated">
            error
          </i>
          <div class="iconTooltip fourthTooltip">
            Ein Leistungsvergleich Ihrer aktuellen Vorsorgesituation mit dem eingegebenen Szenario ist aufgeführt. Die
            jährliche und bis zum Lebensende des Versicherten geschuldete Altersrente in CHF ab Pensionierung wird
            dargestellt sowie aktuelle Leistungen im Fall einer Invalidität (100%) und die Ehegattenrente im Todesfall.
          </div>
        </button>
      </h3>
      <div class="chart" id="chart3" #chart3 [style.display]="displayScenario1">
      </div>
      <div #table3 id="table3" [style.display]="displayScenario2">
        <td class="lefttable2"></td>
        <td class="td-table-spacing importantHeight"></td>
        <td class="lefttable2 minus20MarginB"><b i18n="vorsorge curent|Current values@@vorsorgeCurrent">Aktuell</b></td>
        <td class="td-table-spacing importantHeight"></td>
        <td class="lefttable2 minus20MarginB"><b i18n="vorsorge scenario|Scenario values@@vorsorgeScenario">Szenario</b>
        </td>
        <td class="td-table-spacing importantHeight"></td>
        <td class="righttable2 minus20MarginB"><b i18n="vorsorge difference|Difference values@@vorsorgeDifference">Differenz</b>
        </td>
        <td class="td-table-spacing importantHeight"></td>
        <mat-accordion multi="true">
          <mat-expansion-panel hideToggle class="mat-elevation-z0 important0Margin fontInherit"
                               expanded="{{isExpanded1}}">
            <mat-expansion-panel-header (click)="changePanel(1)" class="tableheader tableheader1 fontInherit"
                                        collapsedHeight="80px"
                                        expandedHeight="80px">
              <mat-panel-description>
                <td class="lefttable2 black newGoodMargin fontInherit">
                  <div class="nameAndIcon fontInherit">
                    <div>
                      <p class="titleTable fontInherit">{{altersrenteAbPension}}</p></div>
                    <div>
                      <i class="material-icons otherButtonIcon" #button1>arrow_drop_down</i>
                    </div>
                  </div>
                </td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T altersrente fontInherit">CHF {{retirementPension}}</td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T altersrente fontInherit">CHF {{retirementPensionScenario}}</td>
                <td class="td-table-spacing"></td>
                <td class="righttable2 margin20T altersrente fontInherit" #differenzAltersrente><b>
                  {{differenzAltersrenteData}} </b></td>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge savedCredit|Saved credit@@vorsorgeSavedCreditSecond">Spar&shy;gut&shy;haben
              </td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{retirementAssetPension}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{retirementAssetPensionScenario}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit"></td>
            </tr>
            <tr *ngIf="joiningBefore">
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge singleIn|Single in@@vorsorgeSingleInSecond">Ein&shy;mal&shy;ein&shy;lage
              </td>
              <!--Changed messages.de-CH on line 2799-->
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{singlePremium}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{singlePremiumScenario}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit"></td>
            </tr>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge convRate|Conversion rate@@vorsorgeConvRateSecond">Um&shy;wand&shy;lungs&shy;satz
              </td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">{{conversionRate}}%</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">{{conversionRateScenario}}%</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit"></td>
            </tr>
          </mat-expansion-panel>

          <!--TP 2-->
          <mat-expansion-panel hideToggle *ngIf="dataService.tpCount > 1"
                               class="mat-elevation-z0 important0Margin fontInherit"
                               expanded="{{isExpandedTP2}}">
            <mat-expansion-panel-header (click)="changePanel('TP2')" class="tableheader tableheader1"
                                        collapsedHeight="80px" expandedHeight="80px">
              <mat-panel-description>
                <td class="lefttable2 black newGoodMargin fontInherit">
                  <div class="nameAndIcon">
                    <div>
                      <p class="titleTable fontInherit"
                         i18n="vorsorge altersrente ab alter|AlterstrenteAb pension@@vorsorgeAltersrenteAbAge">Al&shy;ters&shy;ren&shy;te
                        ab {{partPensionAge[1]}}</p></div>
                    <div>
                      <i class="material-icons otherButtonIcon" #buttonTP2>arrow_drop_down</i>
                    </div>
                  </div>
                </td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T">CHF 0</td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T fontInherit">CHF {{partPensionValues[1]}}</td>
                <td class="td-table-spacing"></td>
                <td class="righttable2 margin20T fontInherit" #partPension2><b> {{differenzTPData2}} </b></td>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit">Um&shy;wand&shy;lungs&shy;satz</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder"></td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">{{conversionRatePartPension[1]}}%</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder"></td>
            </tr>
          </mat-expansion-panel>

          <!--TP 1-->
          <mat-expansion-panel hideToggle *ngIf="dataService.tpCount > 0"
                               class="mat-elevation-z0 important0Margin fontInherit"
                               expanded="{{isExpandedTP1}}">
            <mat-expansion-panel-header (click)="changePanel('TP1')" class="tableheader tableheader1"
                                        collapsedHeight="80px" expandedHeight="80px">
              <mat-panel-description>
                <td class="lefttable2 black newGoodMargin">
                  <div class="nameAndIcon">
                    <div>
                      <p class="titleTable fontInherit"
                         i18n="vorsorge altersrente|Alterstrente pension@@vorsorgeAltersrente">Al&shy;ters&shy;ren&shy;te
                        ab {{partPensionAge[0]}}</p></div>
                    <div>
                      <i class="material-icons otherButtonIcon" #buttonTP1>arrow_drop_down</i>
                    </div>
                  </div>
                </td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T fontInherit">CHF 0</td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T fontInherit">CHF {{partPensionValues[0]}}</td>
                <td class="td-table-spacing"></td>
                <td class="righttable2 margin20T fontInherit" #partPension1><b> {{differenzTPData1}} </b></td>
                <td class="td-table-spacing"></td>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge convRate|Conversion rate@@vorsorgeConvRateThird">Um&shy;wand&shy;lungs&shy;satz
              </td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit"></td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">{{conversionRatePartPension[0]}}%</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder"></td>
            </tr>
          </mat-expansion-panel>


          <mat-expansion-panel class="mat-elevation-z0 important0Margin fontInherit" hideToggle
                               expanded="{{isExpanded2}}">
            <mat-expansion-panel-header (click)="changePanel(2)" class="tableheader tableheader2 fontInherit"
                                        collapsedHeight="80px"
                                        expandedHeight="80px">
              <mat-panel-description #panelHeader class="white">
                <td class="lefttable2 black newGoodMargin">
                  <div class="nameAndIcon">
                    <div>
                      <p class="titleTable fontInherit" i18n="Invalidenrente @@Invalidenrente">In&shy;va&shy;li&shy;den&shy;ren&shy;te</p>
                    </div>
                    <div>
                      <i class="material-icons otherButtonIcon" #button2>arrow_drop_down</i>
                    </div>
                  </div>
                </td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T fontInherit">CHF {{disabledPension}}</td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T fontInherit">CHF {{disabledPensionScenario}}</td>
                <td class="td-table-spacing"></td>
                <td class="righttable2 margin20T fontInherit" #differenzInvalidenrente>
                  <b>{{differenzInvalidenrenteData}}</b></td>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge ensuredSalary|Ensured Salary@@vorsorgeEnsuredSalary">Versich&shy;erter Lohn
              </td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{insuredSalary}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{insuredSalaryScenario}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder"></td>
            </tr>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge ivRent|IV Rent@@vorsorgeIVRent">IV-Renten&shy;satz
              </td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">60%</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">60%</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder"></td>
            </tr>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0 important0Margin borderBGrey fontInherit" hideToggle
                               expanded="{{isExpanded3}}">
            <mat-expansion-panel-header (click)="changePanel(3)" class="tableheader tableheader3 fontInherit"
                                        collapsedHeight="80px"
                                        expandedHeight="80px">
              <mat-panel-description>
                <td class="lefttable2 black newGoodMargin">
                  <div class="nameAndIcon">
                    <div>
                      <p class="titleTable fontInherit" i18n="vorsorge ehegattenrente @@Ehegattenrente">Ehe&shy;gat&shy;ten&shy;ren&shy;te</p>
                    </div>
                    <div>
                      <i class="material-icons otherButtonIcon" #button3>arrow_drop_down</i>
                    </div>
                  </div>
                </td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T fontInherit">CHF {{widowPension}}</td>
                <td class="td-table-spacing"></td>
                <td class="lefttable2 black margin20T fontInherit">CHF {{widowPensionScenario}}</td>
                <td class="td-table-spacing"></td>
                <td class="righttable2 margin20T fontInherit" #differenzWitwenrente><b>{{differenzWitwenrenteData}}</b>
                </td>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge ensuredSalary|Ensured Salary@@vorsorgeEnsuredSalaryTwo">Versich&shy;erter Lohn
              </td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{insuredSalary}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder fontInherit">CHF {{insuredSalaryScenario}}</td>
              <td class="td-spacing"></td>
              <td class="righttable2 text textBorder"></td>
            </tr>
            <tr>
              <td class="lefttable2 text borderLeft textBorder fontInherit"
                  i18n="vorsorge ivRent|IV Rent@@vorsorgeIVRentTwo">IV-Renten&shy;satz
              </td>
              <td class="td-spacing solidB"></td>
              <td class="righttable2 text textBorder fontInherit">40%</td>
              <td class="td-spacing solidB"></td>
              <td class="righttable2 text textBorder fontInherit">40%</td>
              <td class="td-spacing solidB"></td>
              <td class="righttable2 text textBorder"></td>
            </tr>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="barChartContainer scenario" #barChartAltContainer>
      <button id="info-disappear2" *ngIf="displayScenario1 === 'block'" mat-icon-button
              disabled class="infoIcon chartInfo barChartIcon">
        <i
          i18n-matTooltip="vorsorge tooltipOwingOldAgeRent|fifteenth obligatory tooltip in vorsorge@@vorsorgeTooltipOwingOldAgeRent"
          matTooltip="Die jährliche und bis zum Lebensende des Versicherten geschuldete Altersrente in CHF ab Pensionierung wird dargestellt.
         Aktuelle Leistungen im Fall einer Invalidität (100%) sowie die Ehegattenrente im Todesfall sind aufgeführt.
            " class="material-icons infoIcon rotated">
          error
        </i>
        <div class="iconTooltip fifthTooltip"
             i18n="vorsorge ensuredOwing|Ensured and owing old age rent@@vorsorgeEnsuredOwing">
          Die jährliche und bis zum Lebensende des Versicherten geschuldete Altersrente in CHF ab Pensionierung wird
          dargestellt. Aktuelle Leistungen im Fall einer Invalidität (100%) sowie die Ehegattenrente im Todesfall sind
          aufgeführt.
        </div>
      </button>
      <button id="info-disappear2" *ngIf="displayScenario2 === 'block'" mat-icon-button disabled
              class="infoIcon chartInfo barChartIcon">
        <i
          i18n-matTooltip="vorsorge tooltipAllScenarios|sixteenth obligatory tooltip in vorsorge@@vorsorgeTooltipAllScenarios"
          matTooltip="Alle von Ihnen erfassten BVG Massnahmen werden unten chronologisch aufgeführt. Detaillierte Informationen können entsprechend dargestellt werden."
          class="material-icons infoIcon rotated">
          error
        </i>
        <div class="iconTooltip fifthTooltip"
             i18n="vorsorge massnahmenText|Description of Massnahmen table@@vorsorgeMassnahmenText">
          Alle von Ihnen erfassten BVG Massnahmen werden unten chronologisch aufgeführt. Detaillierte Informationen
          können entsprechend dargestellt werden.
        </div>
      </button>
      <h3 class="chartTitle" id="chartlegend4" [style.display]="displayScenario1"
          i18n="vorsorge expectedServices|The expected service amounts@@vorsorgeExpectedServicesTwo">
        Erwartete Leistungen in CHF pro Jahr
      </h3>
      <h3 class="chartTitle noDisplay minusPercentMarginB" id="tablelegend2" [style.display]="displayScenario2"
          i18n="vorsorge bvgMassnahmen|All scenarios@@vorsorgeBVGMassnahmen">
        BVG Massnahmen
      </h3>
      <div class="chart" id="chart4" #chart4 [style.display]="displayScenario1">
      </div>
      <div id="table2" [style.display]="displayScenario2">
        <tr class="solidB lHeight56">
          <td class="solidB padding3 width30"><b
            i18n="vorsorge year|The year of a scenario@@vorsorgeScenarioYear">Jahr</b></td>
          <td class="solidB width10p"></td>
          <td class="solidB padding3 width70"><b
            i18n="vorsorge scenarioName|The name of a scenario@@vorsorgeScenarioName">BVG Massnahmen</b></td>
        </tr>
        <!--Dialog to select wich scenario to edit-->
        <tr *ngFor="let szenario of szenarien; last as isLastSzenario">
          <td class="padding3 width30">{{szenario.year}}</td>
          <td class="width10p bgColorWhite"></td>
          <td class="padding3 width70">
            <div *ngFor="let event of szenario.events; last as isLastEvent" class="action">
              <div *ngIf="event.icon === 'money'" class="iconAndName">
                <div>
                  <mat-icon class="customIcon buttonIcons smolIcon" svgIcon="newMoney"></mat-icon>
                </div>
                <div class="name" i18n="vorsorge buyIn|Buy in normal@@vorsorgeBuyIn">
                  <p class="massnahmeName">Einkauf</p>
                </div>
              </div>
              <div *ngIf="event.icon === 'attach_money'" class="iconAndName">
                <div>
                  <mat-icon class="customIcon buttonIcons smolIcon" svgIcon="newMoney"></mat-icon>
                </div>
                <div class="name" i18n="vorsorge buyInEarly|Early pension buy in@@vorsorgeBuyInEarly">
                  <p class="massnahmeName">Einkauf für vorzeitige Pensionierung</p>
                </div>
              </div>
              <div *ngIf="event.icon === 'directions_walk'" class="iconAndName">
                <div>
                  <mat-icon class="customIcon buttonIcons smolIcon" svgIcon="newOld"></mat-icon>
                </div>
                <div class="name" i18n="vorsorge pension|Pension normal@@vorsorgePension">
                  <p class="massnahmeName">Pensionierung</p>
                </div>
              </div>
              <div *ngIf="event.icon === 'directions_run'" class="iconAndName">
                <div>
                  <mat-icon class="customIcon buttonIcons smolIcon" svgIcon="newOld"></mat-icon>
                </div>
                <div class="name" i18n="vorsorge partPension|Partial pension@@vorsorgePartPension">
                  <p class="massnahmeName">Teilpensionierung</p>
                </div>
              </div>
              <div class="layout" *ngIf="event.icon === 'airline_seat_recline_normal'">
                <span><i class="listIcon material-icons massnahmenIcon">airline_seat_recline_normal</i>
                  <span class="name" i18n="vorsorge earlyPension|Early Pension@@vorsorgeEarlyPension">vorzeitige Pensionierung</span></span>
              </div>
              <div class="layout" *ngIf="event.icon === 'local_mall'">
                <span><i class="listIcon material-icons massnahmenIcon">local_mall</i>
                  <span class="name" i18n="vorsorge pensum|Working pensum@@vorsorgePensum">Arbeitspensum</span></span>
              </div>
              <div class="layout" *ngIf="event.icon === 'home'">
                <span><i class="listIcon material-icons massnahmenIcon">home</i>
                  <span class="name">WEF<span *ngIf="event.name == 'payback'"
                                              i18n="vorsorge payBack|WEF Payback@@vorsorgePayBack"> Rückzahlung</span></span></span>
              </div>
              <div class="layout" *ngIf="event.icon === 'transform'">
                <span><i class="listIcon material-icons massnahmenIcon">transform</i>
                  <span class="name" i18n="vorsorge plan|Planwechsel@@vorsorgePlan">Planwechsel</span></span>
              </div>


              <button mat-button *ngIf="isLastSzenario && isLastEvent" (click)="deleteLastEvent()">
                <i class="material-icons deleteIcon">delete</i>
              </button>
              <!--              <button class="hidden" mat-button *ngIf="!isLastSzenario || !isLastEvent">-->
              <!--                <i class="material-icons deleteIcon">delete</i>-->
              <!--              </button>-->
            </div>
          </td>
        </tr>
      </div>
    </div>
  </div>
</div>
