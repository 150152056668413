export enum FormIconNames {
  /*Home & Grunddaten*/
  dateOfBirth = 'Geburtsdatum',
  joiningBefore = 'Eintritt vor 01.07.2017',
  annualSalary = 'Jahreslohn',
  retirementAsset = 'Sparguthaben',
  pensionPlan = 'Sparplan',
  insuranceCardDate = 'Stichtag Vorsorgeausweis',
  projectionInterest = 'ProjektionsZins',
  salaryGrowth = 'Lohnkarriere',
  singlePremium = 'Einmaleinlage',

  /*Einkauf*/
  einkaufsDatum = 'Datum des Einkaufs',
  earlyRetirement = 'Fühpensionierung Ja oder Nein',
  pensionAge = 'Pensionierungsalter',

  /*Planwechsel*/
  selectedYear = 'Jahr des Wechsels',
  selectedPlan = 'Ausgewählter Plan',

  /*WEF*/
  vestedBenefit = 'Freizügigkeitsleistung',
  wefDatum = 'Datum WEF-Bezug/ -Rückzahlung',
  wefMode = 'WEF-Bezug oder WEF-Rückzahlung',

  /*Arbeitspensum*/
  arbeitspensumDate = 'Datum der Arbeitspensumänderung',
  newPensum = 'Neues Pensum',
  partialPensionierung = 'Teilpensionierung Ja oder Nein',
  advancedSettings = 'Erweiterte Eingaben',
  salaryIncrease = 'Gehaltserhögung Ja oder Nein',
  salaryChange = 'Wert der Gehaltserhöhung',

  /*Pensionierung*/
  /*pensionAge dasselwe wie bei Einkauf*/
  kapitalBezugbool = 'Kapitalbezug Ja oder Nein',
}

export enum FormIconNamesFR {
  /*Home & Grunddaten*/
  dateOfBirth = 'Date de naissance',
  joiningBefore = 'Entrée avant le 01.07.2017',
  annualSalary = 'Salaire annuel',
  retirementAsset = 'Avoir de prévoyance',
  pensionPlan = 'Plan d’épargne',
  insuranceCardDate = 'Date déterminante du certificat de prévoyance',
  projectionInterest = 'Taux de projection',
  salaryGrowth = 'Evolution salariale',
  singlePremium = 'Prime unique',

  /*Einkauf*/
  einkaufsDatum = 'date de rachat',
  earlyRetirement = 'retraite partielle Oui ou Non',
  pensionAge = 'âge de la retraite',

  /*Planwechsel*/
  selectedYear = 'date de changement',
  selectedPlan = 'plan sélectionné',

  /*WEF*/
  vestedBenefit = 'Prestation de libre passage',
  wefDatum = 'Date d\'EPL',
  wefMode = 'WEF-Bezug oder WEF-Rückzahlung',

  /*Arbeitspensum*/
  arbeitspensumDate = 'Date de changement de salaire',
  newPensum = 'nouveau taux d’occupation',
  partialPensionierung = 'nouveau taux d’occupation Oui ou Non',
  advancedSettings = 'Précisions additionnelles',
  salaryIncrease = 'changement de salaire Oui ou Non',
  salaryChange = 'changement de salaire ',

  /*Pensionierung*/
  /*pensionAge dasselwe wie bei Einkauf*/
  kapitalBezugbool = 'versement en capital Oui ou Non',
}
