import {DataService} from '../../service/data.service';
// Material Components Imports
import {AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {GrunddatenComponent} from '../../dialog/grunddaten/grunddaten.component';
import {ScenarioInput, ScenarioType} from '../../data-type/input/ScenarioInput';
import {ScenarioSelectionComponent} from '../../dialog/scenario-selection/scenario-selection.component';
// c3.js library
import * as c3 from 'c3/c3.min.js';
import * as d3 from 'd3/dist/d3.min.js';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {ScenarioInfoComponent} from '../../dialog/scenario-info/scenario-info.component';
import {PensionProjection} from '../../data-type/output/PensionProjection';
import {VideoComponent} from '../../dialog/video/video.component';
import {Router} from '@angular/router';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-vorsorge',
  templateUrl: './vorsorge.component.html',
  styleUrls: ['./vorsorge.component.css'],
})
export class VorsorgeComponent implements AfterViewInit {
  @ViewChild('panelHeader', {static: false}) panelHeader: ElementRef;
  @ViewChild('table1', {static: false}) table1: ElementRef;
  @ViewChild('table3', {static: false}) table3: ElementRef;
  @ViewChild('button1', {static: false}) button1: ElementRef;
  @ViewChild('button2', {static: false}) button2: ElementRef;
  @ViewChild('button3', {static: false}) button3: ElementRef;
  @ViewChild('buttonTP1', {static: false}) buttonTP1: ElementRef;
  @ViewChild('buttonTP2', {static: false}) buttonTP2: ElementRef;
  @ViewChild('refButton', {static: false}) refButton: ElementRef;
  @ViewChild('clickboxContainer', {static: false}) clickboxContainer: ElementRef;
  @ViewChild('differenzAltersrente', {static: false}) differenzAltersrente: ElementRef;
  @ViewChild('differenzInvalidenrente', {static: false}) differenzInvalidenrente: ElementRef;
  @ViewChild('differenzWitwenrente', {static: false}) differenzWitwenrente: ElementRef;
  @ViewChild('partPension1', {static: false}) partPension1: ElementRef;
  @ViewChild('partPension2', {static: false}) partPension2: ElementRef;
  @ViewChild('lineChartContainer', {static: false}) lineChartContainer: ElementRef;
  @ViewChild('barChartContainer', {static: false}) barChartContainer: ElementRef;
  @ViewChild('barChartAltContainer', {static: false}) barChartAltContainer: ElementRef;

  @ViewChild('noWrap', {static: false}) noWrap: ElementRef;
  @ViewChild('noWrapScenario', {static: false}) noWrapScenario: ElementRef;
  french = false;
  joiningBefore: Boolean = false;
  isExpanded1 = false;
  isExpanded2 = false;
  isExpanded3 = false;
  isExpandedTP1 = false;
  isExpandedTP2 = false;
  data: string[];
  data2: string[];
  retirementAssetYearBegin: string;
  employeeContribution: string;
  displayStandard1 = 'block';
  displayStandard2 = 'none';
  displayScenario1 = 'none';
  displayScenario2 = 'none';
  employerContribution: string;
  interestContribution: string;
  retirementAssetYearEnd: string;
  retirementAssetPension: string;
  retirementAssetPensionScenario: string;
  singlePremium: string;
  singlePremiumScenario: string;
  widowPension: any;
  disabledPension: any;
  conversionRate: string;
  conversionRateScenario: string;
  retirementPension: string;
  retirementPensionScenario: string;
  partPensionValues: string[] = [];
  conversionRatePartPension: string[] = [];
  partPensionAge: string[] = [];
  disabledCreatetext: String = '';
  insuredSalaryScenario: string;
  insuredSalary: string;
// table data + class for plus/minus/zero
  altersrenteAbPension: string;
  differenzAltersrenteData: string;
  differenzInvalidenrenteData: string;
  differenzWitwenrenteData: string;
  differenzTPData1: string;
  differenzTPData2: string;
// invalidenrente
  disabledPensionScenario: string;
// Witwenrente
  widowPensionScenario: string;
  event = {
    name: null,
    icon: null
  };
  szenarien: Array<{
    year: string, events: Array<{ icon: string, name: string, value?: any, half: number }>,
    scenario: Array<ScenarioInput>
  }> = Array();
  chart1: any;
  chart2: any;
  chart3: any;
  chart4: any;
  tpCount: number;
  output: any;

  constructor(
    public dialog: MatDialog, public dataService: DataService,
    private renderer: Renderer2, public dialogMethods: DialogMethodsService,
    public scenarioInfo: MatDialog,
    private router: Router,
    // @Inject(APP_BASE_HREF) href: string,
    private platformLocation: PlatformLocation
  ) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
  }

  ngAfterViewInit() {
    this.dataService.outputList.subscribe((output: any) => {
      this.joiningBefore = this.dataService.getData().pensionPlan.toString().endsWith('BEFORE')
        && this.dialogMethods.dateBefore1966(this.dataService.getData().dateOfBirth);
      this.output = output;
      if (output != null && output.pensionProjections != null) {
        this.renderCharts(output.pensionProjections);
      }

      this.tpCount = this.dataService.tpCount;
    });

    setTimeout(() => {
      if (!this.dataService.getData()) {
        this.router.navigate(['home']).catch(console.error);
      }
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const target = '.c3-target-datascenario1';
    const elem = d3.selectAll(target).selectAll('.c3-text');
    /*Flags and Clickboxed are deleted, so they aren't multiplied*/
    const flags = document.getElementsByClassName('flag');
    const clickboxes = document.getElementsByClassName('clickbox');
    for (let i = 0; i < flags.length; i++) {
      this.renderer.removeChild(flags.item(i).parentElement, flags.item(i));
    }
    for (let i = 0; i < clickboxes.length; i++) {
      this.renderer.removeChild(clickboxes.item(i).parentElement, clickboxes.item(i));
    }

    const dimensions = {};
    const dimensionsBar = {};
    const lineChartContainer = this.lineChartContainer.nativeElement;
    const barChartContainer = this.barChartContainer.nativeElement;
    const clickboxContainer = this.clickboxContainer.nativeElement;
    const barChartAltContainer = this.barChartAltContainer.nativeElement;
    if (flags.length > 0) {
      // sizing scenario
      dimensions['height'] = (clickboxContainer.clientHeight - 110);
      dimensionsBar['height'] = (clickboxContainer.clientHeight - 90);
      dimensions['width'] = 0.92 * clickboxContainer.clientWidth;
      dimensionsBar['width'] = 0.915 * barChartAltContainer.clientWidth;
      if (dimensions['height'] < 380) {
        dimensions['height'] = 370;
        dimensionsBar['height'] = 380;
      }
      this.chart3.resize(dimensions);
      this.chart4.resize(dimensionsBar);
    } else {
      // sizing standort
      dimensions['height'] = (lineChartContainer.clientHeight - 100);
      dimensionsBar['height'] = (barChartContainer.clientHeight - 80);
      dimensions['width'] = 0.92 * lineChartContainer.clientWidth;
      if (dimensions['height'] < 380) {
        dimensions['height'] = 380;
        dimensionsBar['height'] = 380;
      }
      this.chart1.resize(dimensions);
      this.chart2.resize(dimensionsBar);
    }
  }

  renderCharts(input: PensionProjection[]) {
    const arrayInput = input;
    let i: number;
    // size of charts
    const dimensions = {};
    const dimensionsBar = {};
    const lineChartContainer = this.lineChartContainer.nativeElement;
    const barChartContainer = this.barChartContainer.nativeElement;
    if (lineChartContainer.clientHeight > 0) {
      dimensions['height'] = 0.88 * lineChartContainer.clientHeight;
      dimensions['width'] = 0.92 * lineChartContainer.clientWidth;
      dimensionsBar['height'] = 0.915 * barChartContainer.clientHeight;
      dimensionsBar['width'] = 0.915 * barChartContainer.clientWidth;
    } else {
      dimensions['height'] = 0.88 * this.clickboxContainer.nativeElement.clientHeight;
      dimensionsBar['height'] = 0.915 * this.clickboxContainer.nativeElement.clientHeight;
    }
    if (dimensions['height'] < 380) {
      dimensions['height'] = 380;
      dimensionsBar['height'] = 380;
    }
    // so the module(and functions...) can be accessed in loops/chart...
    const that = this;
    // initialize data arrays (ausserhalb von Loop)
    const data = ['data1'];
    let data2 = [];
    let data2scenario0 = [];
    let standardData = [];

// TABLE DATA
    if (arrayInput.length === 1) {
      // if input.length 1 there is only 1 array --> no scenario entered
      standardData = input[0].timestamps;
    } else {
      // scenario entered, standarddata shifts to 1
      standardData = input[1].timestamps;
    }


    // table data
    this.altersrenteAbPension = 'Al\xADters\xADren\xADte';
    if (this.french) {
      this.altersrenteAbPension = 'Rente de vieillesse';
    }
    if (arrayInput.length > 1) {
      this.setPartPensionValues(input[0].timestamps);
      if (this.dataService.tpCount === 1 && this.partPension1 !== undefined) {
        this.differenzTPData1 = this.calculateDifference(
          0,
          this.dialogMethods.toNumber(this.partPensionValues[0]),
          this.partPension1.nativeElement
        );
        if (!this.french) {
          this.altersrenteAbPension += ' ab Pen\xADsi\xADo\xADnie\xADrung';
        }
      }
      if (this.dataService.tpCount === 2 && this.partPension2 !== undefined) {
        this.differenzTPData1 = this.calculateDifference(
          0,
          this.dialogMethods.toNumber(this.partPensionValues[0]),
          this.partPension1.nativeElement
        );
        this.differenzTPData2 = this.calculateDifference(
          0,
          this.dialogMethods.toNumber(this.partPensionValues[1]),
          this.partPension2.nativeElement
        );
        this.altersrenteAbPension += this.french ? ' de la re\xADtraite' : ' ab Pen\xADsi\xADo\xADnie\xADrung';
      }
      for (i = 0; i < document.getElementsByClassName('dropDownIcon').length; i++) {
        document.getElementsByClassName('dropDownIcon')[i].setAttribute('style', 'margin-right: -20px !important');
      }

      let k = 0;
      // tslint:disable-next-line
      let j = 0;
      while (input[1].timestamps[j].timestampType.toLocaleString() !== 'Pension') {
        j++;
      }
      while (input[0].timestamps[k].timestampType.toLocaleString() !== 'Pension') {
        k++;
      }
      // calculatedifference function in this component, because DOM needs to be changed
      this.retirementPension = this.dialogMethods.formatTable(input[1].timestamps[j + 2].projectedPension);
      this.retirementPensionScenario = this.dialogMethods.formatTable(input[0].timestamps[k + 2].projectedPension);
      this.disabledPensionScenario = this.dialogMethods.formatTable(input[0].timestamps[0].disabledPension);
      this.widowPensionScenario = this.dialogMethods.formatTable(input[0].timestamps[0].widowPension);
      this.widowPension = this.dialogMethods.formatTable(input[1].timestamps[0].widowPension);
      // table Data
      this.retirementAssetPensionScenario = this.dialogMethods.formatTable(input[0].timestamps[k].retirementAsset);
      this.conversionRate = (this.dialogMethods.formatPercent(input[1].timestamps[j].conversionRate * 100)).toString();
      this.conversionRateScenario = (this.dialogMethods.formatPercent(input[0].timestamps[k].conversionRate * 100)).toString();
      this.singlePremiumScenario = this.dialogMethods.formatTable(input[0].timestamps[k + 1].singlePremium);
      // calculatedifference function in this component, because DOM needs to be changed
      this.differenzAltersrenteData = this.calculateDifference(
        input[1].timestamps[j + 2].projectedPension, // standarddata
        input[0].timestamps[k + 2].projectedPension, // scenariodata
        this.differenzAltersrente.nativeElement // Element to be styled, depending on differenz
      );
      this.differenzInvalidenrenteData = this.calculateDifference(
        input[1].timestamps[0].disabledPension, // standarddata
        input[0].timestamps[0].disabledPension,  // scenariodata
        this.differenzInvalidenrente.nativeElement // Element to be styled, depending on differenz
      );
      this.differenzWitwenrenteData = this.calculateDifference(
        input[1].timestamps[0].widowPension, // standarddata
        input[0].timestamps[0].widowPension,  // scenariodata
        this.differenzWitwenrente.nativeElement // Element to be styled, depending on differenz
      );

    }

    // data for tables
    const szenarien = this.dataService.getScenarios();
    this.szenarien = [];
    for (i = 0; i < szenarien.length; i++) {
      // tslint:disable-next-line
      const currentYear = this.dialogMethods.correctDate(szenarien[i].cashflows[0].date).split('-')[0];
      this.getEvent(i);
      if ((this.szenarien[this.szenarien.length - 1] && parseInt(this.szenarien[this.szenarien.length - 1].year, 10) ===
        parseInt(currentYear, 10))) {
        this.szenarien[this.szenarien.length - 1].events.push({
          icon: that.event.icon, name: that.event.name,
          value: szenarien[i].cashflows[0].value, half: 1
        });
        this.szenarien[this.szenarien.length - 1].scenario.push(szenarien[i]);
        // if first szenario
      } else {
        this.szenarien.push({
          year: currentYear, events: [{
            icon: that.event.icon, name: that.event.name,
            value: szenarien[i].cashflows[0].value, half: 1
          }], scenario: [szenarien[i]]
        });
      }
    }
    this.dataService.setList(this.szenarien);
    // data gets formatted and saved for table
    this.disabledPension = this.dialogMethods.formatTable(standardData[0].disabledPension);
    this.retirementAssetYearBegin = this.dialogMethods.formatTable(this.dataService.getData().currentRetirementAsset);
    this.employeeContribution = this.dialogMethods.formatTable(standardData[0].employeeContribution);
    this.employerContribution = this.dialogMethods.formatTable(standardData[0].employerContribution);
    this.interestContribution = this.dialogMethods.formatTable(standardData[0].interestContribution);
    this.retirementAssetYearEnd = this.dialogMethods.formatTable(standardData[0].retirementAsset);
    // used for foor loop --> # of years
    let yearsDisplayed = standardData.length;
    // cycle through all years (datapoints) to create chartData and tableData
    for (i = 0; i < 100; i++) {

      if (standardData[i].timestampType === 'Pension') {
        // if Data is from Pensionyear
        // pension Age
        // if there is no scenario entered
        yearsDisplayed = i;
        this.retirementAssetPension = this.dialogMethods.formatTable(standardData[i].retirementAsset);
        this.conversionRate = (standardData[i].conversionRate * 100).toString();
        this.retirementPension = this.dialogMethods.formatTable(standardData[i + 2].projectedPension);
        this.widowPension = this.dialogMethods.formatTable(standardData[0].widowPension);
        this.disabledPension = this.dialogMethods.formatTable(standardData[0].disabledPension);
      }
      if (standardData[i].timestampType === 'EndOfPensionYear') {
        this.singlePremium = this.dialogMethods.formatTable(standardData[i].singlePremium);
        break;
      }
    }
    // set data for Leistungsvergleich
    this.insuredSalary = this.dialogMethods.formatTable(standardData[0].insuredSalary);
    if (arrayInput.length === 2) {
      this.insuredSalaryScenario = this.dialogMethods.formatTable(input[0].timestamps[0].insuredSalary);
    }

// CHART DATA
    for (i = 0; i < yearsDisplayed + 1; i++) {
      if (standardData[i].timestampType === 'Pension') {
        data2 = ['data2', standardData[i + 2].projectedPension,
          standardData[0].disabledPension, standardData[0].widowPension];
      }
    }
    // create data1
    let j = 0;
    // for every normal year, add new data to linechartdata
    while (standardData[j].timestampType !== 'Pension') {
      const dataimp: number = standardData[j].retirementAsset;
      if (dataimp) {
        data.push(dataimp.toString());
      }
      j++;
    }
    data.push(standardData[j].retirementAsset.toString()); // add retirementAsset at Retirement
    if (arrayInput.length === 1) {
      const standardList: any = document.getElementsByClassName('standard');
      this.noWrapScenario.nativeElement.style.display = 'none';
      this.noWrap.nativeElement.style.display = 'flex';
      for (i = 0; i < standardList.length; i++) {
        standardList.item(i).style.display = 'block';
      }
      const container: any = document.getElementsByClassName('container')[0];
      this.disabledCreatetext = '';
      this.displayStandard1 = 'block'; // TODO: xxxxx
      this.displayStandard2 = 'none';
      this.displayScenario1 = 'none';
      this.displayScenario2 = 'none';
      // when no scenario entered --> generate chart1 and 2
      const dataStandard = input[0].timestamps;
      let index;
      this.chart1 = c3.generate({
        bindto: '#chart1',
        size: dimensions,
        data: {
          columns: [
            data,
          ],
          labels: {
            // show should be sufficent
            format: function (v, id) {
              return '';
            }
          },
          colors: {
            data1: '#155A49'
          }
        },
        axis: {
          x: {
            label: {
              text: this.french ? 'Votre âge au 31 décembre' : 'Ihr Alter per 31.12',
              position: 'outer-center'
            },
            tick: {
              // tslint:disable-next-line
              format: function (i) {
                return dataStandard[i].age;
              },
              culling: false
            }
          },
          y: {
            tick: {
              format: function (value) {
                let numberAsString = value.toString();
                for (i = 0; i < Math.floor((numberAsString.length - 1) / 3); i++) {
                  const point = -(4 * i + 3);
                  if (Math.abs(point) < numberAsString.length) {
                    numberAsString = numberAsString.slice(0, point) + '\'' + numberAsString.slice(point, numberAsString.length);
                  }
                }
                return numberAsString;
              }
            }
          }
        },
        legend: {
          hide: true
        },
        grid: {
          y: {
            show: true
          }
        },
        point: {
          r: 4,
        },
        tooltip: {
          position: function (chartData) {        // line chart tooltips
            index = chartData[0].x;
            const xPos = parseInt(d3.select(d3.selectAll('.c3-target-data1').selectAll('.c3-circle').nodes()[index]).attr('cx'), 10);
            const yPos = parseInt(d3.select(d3.selectAll('.c3-target-data1').selectAll('.c3-circle').nodes()[index]).attr('cy'), 10) + 50;
            return {top: yPos, left: xPos};
          },
          contents: function (d) {
            let fixednumber = Math.round(d[0].value).toString();
            for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
              const spot = -(4 * i + 3);
              if (Math.abs(spot) < fixednumber.length) {
                fixednumber = fixednumber.slice(0, spot) + '\'' + fixednumber.slice(spot, fixednumber.length);
              }
            }
            return '<div style=\'background-color: #155A49; padding:0.25em 0.5em;'
              + 'white-space: nowrap; color: white; text-align: center; vertical-align: bottom !important;\'>' +
              'CHF ' + fixednumber + '</div>';
          }
        },
        onrendered: function () {
          index += 1;
          d3.selectAll('.c3-line').style('stroke-width', 3);
        }
      });
      this.chart2 = c3.generate({
        bindto: '#chart2',
        size: dimensionsBar,
        data: {
          columns: [
            data2,
          ],
          type: 'bar',
          groups: [
            ['data2']
          ],
          colors: {
            data2: '#155A49'
          }
        },
        bar: {
          width: {
            ratio: 0.2
          }
        },
        legend: {
          hide: true,
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'category',
            categories: this.french ? ['Age', 'Invalidité', 'Décès'] : ['Alter', 'Invalidität', 'Tod']
          },
          y: {
            tick: {
              format: function (value) {
                let numberAsString = value.toString();
                for (i = 0; i < Math.floor((numberAsString.length - 1) / 3); i++) {
                  const point = -(4 * i + 3);
                  if (Math.abs(point) < numberAsString.length) {
                    numberAsString = numberAsString.slice(0, point) + '\'' + numberAsString.slice(point, numberAsString.length);
                  }
                }
                return numberAsString;
              }
            }
          }
        },
        tooltip: {
          position: function (tooltipData) {            // first bar chart

            index = tooltipData[0].x;

            const valueArray = d3.select(d3.selectAll('.c3-target-data2').selectAll('.c3-bar').nodes()[index]).attr('d').split('L');
            const startCoordinates = parseInt(valueArray[1].split(','), 10);
            const endCoordinates = parseInt(valueArray[2].split(','), 10);
            const xPos = (endCoordinates - startCoordinates) / 2 + startCoordinates;

            return {
              left: xPos,
              top: 0
            };
          },
          contents: function (d) {
            let fixednumber = Math.round(d[0].value).toString();
            for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
              const spot = -(4 * i + 3);
              if (Math.abs(spot) < fixednumber.length) {
                fixednumber = fixednumber.slice(0, spot) + '\'' + fixednumber.slice(spot, fixednumber.length);
              }
            }
            return '<div style=\'background-color: #155A49; color: white; padding:5px 10px;' +
              ' white-space: nowrap; text-align: center; vertical-align: bottom !important;' +
              ' font-size: 16px; width: 112px; height: 16px;\'>' + 'CHF ' + fixednumber + '</div>';
          }
        }
      });
    } else {
      this.displayStandard1 = 'none';
      this.displayStandard2 = 'none';
      this.displayScenario1 = 'block';
      this.displayScenario2 = 'none';
      // when scenario entered --> generate chart3 and 4
      // size/height of chart, because size can be buggy
      // change standard GUI to scenario GUI
      const standardList: any = document.getElementsByClassName('standard');
      const scenarioList: any = document.getElementsByClassName('scenario');
      this.noWrap.nativeElement.style.display = 'none';
      for (i = 0; i < standardList.length; i++) {
        standardList.item(i).style.display = 'none';
      }
      this.noWrapScenario.nativeElement.style.display = 'flex';
      for (i = 0; i < scenarioList.length; i++) {
        scenarioList.item(i).style.display = 'block';
      }
      // declare data and names for all scenarien
      const datascenarien = [];
      let namescenarien;
      if (this.french) {
        namescenarien = {'data1': 'Actuel'};
      } else {
        namescenarien = {'data1': 'Aktuell'};
      }
      const colors = {};
      const classesscenarien = {data1: 'data1'};
      colors['data1'] = '#155A49';
      const datastandard = ['data1'];
      let datascenario: string[];
      let name: string;
      name = 'datascenario' + (0).toString();
      datascenario = [name];
      if (this.french) {
        namescenarien[name] = 'Scénario';
      } else {
        namescenarien[name] = 'Szenario';
      }

      classesscenarien[name] = name;
      colors[name] = '#9A8322';
      let index = null;
      let datapointscenario = 0;
      let datapointstandard = 0;

      // create data1 datalet j = 0;
      j = 0;
      let k = 0;
      if (arrayInput.length === 2) {
        while (input[1].timestamps[j].timestampType.toLocaleString() !== 'Pension') {
          // for every normal year, add new data to linechartdata
          datapointstandard = input[1].timestamps[j].retirementAsset;
          if (datapointstandard && datapointstandard !== 0) {
            datastandard.push(datapointstandard.toString());
          }
          j++;
        }
        datastandard.push(input[1].timestamps[j].retirementAsset.toString());
        while (input[0].timestamps[k].timestampType.toLocaleString() !== 'Pension') {
          if (input[0].timestamps[k].age !== input[0].timestamps[k + 1].age) {
            datapointscenario = input[0].timestamps[k].retirementAsset;
            if (datapointscenario && datapointscenario !== 0) {
              datascenario.push(datapointscenario.toString());
            }
          }
          k++;
        }
        datascenario.push(input[0].timestamps[k].retirementAsset.toString());
        datascenarien.push(datascenario);
        datascenarien.push(datastandard);
        // data 2 Scenario
        data2scenario0 =
          [
            'data2scenario0', input[0].timestamps[k + 2].projectedPension,
            input[0].timestamps[0].disabledPension,
            input[0].timestamps[0].widowPension
          ];
      }
      // render chart when needed
      const clickboxContainer = this.clickboxContainer.nativeElement;
      dimensions['height'] = 0.85 * clickboxContainer.clientHeight;
      dimensionsBar['height'] = 0.88 * clickboxContainer.clientHeight;
      if (dimensions['height'] < 380) {
        dimensions['height'] = 380;
        dimensionsBar['height'] = 380;
      }
      this.chart3 = c3.generate({
        bindto: '#chart3',
        size: dimensions,
        data: {
          columns: datascenarien,
          names: namescenarien,
          classes: classesscenarien,
          labels: {
            format: function () {
              return '';
            },
          },
          colors: colors
        },
        point: {
          r: 4,
        },
        legend: {
          item: {
            onmouseover: function () {
            },
            onclick: function () {
            }
          },
          position: 'inset',
          inset: {
            anchor: 'bottom-right',
            x: 100,
            y: 80
          }
        },
        axis: {
          x: {
            label: {
              text: this.french ? 'Votre âge au 31 décembre' : 'Ihr Alter per 31.12',
              position: 'outer-center'
            },
            tick: {
              // tslint:disable-next-line:no-shadowed-variable
              format: function (i: number) {
                /*See issue 92*/
                if (input[1].timestamps[i].age >= 65 && input[1].timestamps[i].age !== input[1].timestamps[i + 1].age) {
                  return parseInt(String(input[1].timestamps[i].age), 10) + 1;
                } else {
                  return input[1].timestamps[i].age;
                }
              },
              culling: false,
            }
          },
          y: {
            tick: {
              format: function (value) {
                let numberAsString = value.toString();
                for (i = 0; i < Math.floor((numberAsString.length - 1) / 3); i++) {
                  const point = -(4 * i + 3);
                  if (Math.abs(point) < numberAsString.length) {
                    numberAsString = numberAsString.slice(0, point) + '\'' + numberAsString.slice(point, numberAsString.length);
                  }
                }
                return numberAsString;
              }
            }
          }
        },
        grid: {
          y: {
            show: true
          }
        },
        tooltip: this.graph3object(),
        onrendered: function () {
          /*Was moved from onResized to here, delets current flags to make room for new flags*/
          /*Was moved because it was executed too late in onResized*/
          const flags = document.getElementsByClassName('flag');
          const clickboxes = document.getElementsByClassName('clickbox');
          for (let e = 0; e < flags.length; e++) {
            that.renderer.removeChild(flags.item(e).parentElement, flags.item(e));
          }
          for (let e = 0; e < clickboxes.length; e++) {
            that.renderer.removeChild(clickboxes.item(e).parentElement, clickboxes.item(e));
          }
          d3.selectAll('.c3-line').style('stroke-width', 3);
          d3.selectAll('.c3-text').attr('position', 'absolute');
          d3.selectAll('.c3-text').style('transform', 'translate(0px,46px)');
          for (i = 1; i < arrayInput.length; i++) {
            const target = '.c3-target-datascenario' + (i - 1).toString();
            d3.selectAll(target).attr('stroke-dasharray', '3,3');
            // for i < this.szenarien.length loop through szenarien, and for all set Flag and clickbox and clickboxtooltip
            const innerElement: any = document.getElementsByClassName('c3-event-rect');
            const outerElement: any = document.getElementsByTagName('svg');
            const innerWidth = innerElement.item(0).width.baseVal.value;
            const outerWidth = outerElement.item(0).width.baseVal.value;
            const margin = outerWidth - innerWidth;
            k = 0;
            const currentYear = new Date(that.dataService.dataList.insuranceCardDate);
            const cashFlows = that.dataService.getData().cashFlows;
            // remove old tooltips
            const tooltips = document.getElementsByClassName('clickboxtooltip');
            for (let d = 0; d < tooltips.length; d++) {
              that.renderer.removeChild(that.clickboxContainer.nativeElement, tooltips.item(d));
            }
            for (j = 0; j < that.szenarien.length; j++) {
              // is current szenario on chart ?
              index = (Number(that.szenarien[j].year) - currentYear.getFullYear()) - j;
              const items = d3.selectAll(target).selectAll('.c3-text');
              if (index >= 0 && index < datascenarien[0].length) {
                const elem = d3.select(items.nodes()[index]);
                that.setFlag(elem);
                k = that.setTooltip(elem, margin, k);
              }
            }
          }
        }
      });
      this.chart4 = c3.generate({
        bindto: '#chart4',
        size: dimensionsBar,
        data: {
          columns: [
            data2,
            data2scenario0
          ],
          type: 'bar',
          colors: {
            data2: '#155A49',
            data2scenario0: '#9A8322'
          },
          names: {
            data2: (this.french ? 'Actuel' : 'Aktuell'),
            data2scenario0: (this.french ? 'Scénario' : 'Szenario')
          }
        },
        bar: {
          width: {
            ratio: 0.3
          },
          space: 0.1
        },
        legend: {
          item: {
            onmouseover: function () {
            },
            onclick: function () {
            }
          },
          position: 'inset',
          inset: {
            anchor: 'top-right'
          }
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'category',
            categories: this.french ? ['Age', 'Invalidité', 'Décès'] : ['Alter', 'Invalidität', 'Tod']
          },
          y: {
            tick: {
              format: function (value) {
                let numberAsString = value.toString();
                for (i = 0; i < Math.floor((numberAsString.length - 1) / 3); i++) {
                  const point = -(4 * i + 3);
                  if (Math.abs(point) < numberAsString.length) {
                    numberAsString = numberAsString.slice(0, point) + '\'' + numberAsString.slice(point, numberAsString.length);
                  }
                }
                return numberAsString;
              }
            }
          }
        },
        tooltip: this.graph4tooltip()
      });
    }
    this.onResize();
  }

  graph3object() {
    const object = {contents: null};
    object.contents = this.graph3tooltip();
    return object;
  }

  graph3tooltip() {
    if (this.french) {
      return function (d) {
        let i = 0;
        let fixednumber: string;
        let fixednumberScenario: string;
        if (d[1]) {
          fixednumber = Math.round(d[1].value).toString();
          for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
            const spot = -(4 * i + 3);
            if (Math.abs(spot) < fixednumber.length) {
              fixednumber = fixednumber.slice(0, spot) + '\'' + fixednumber.slice(spot, fixednumber.length);
            }
          }
        } else {
          fixednumber = Math.round(d[0].value).toString();
          for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
            const spot = -(4 * i + 3);
            if (Math.abs(spot) < fixednumber.length) {
              fixednumber = fixednumber.slice(0, spot) +
                '\'' + fixednumber.slice(spot, fixednumber.length);
            }
          }
          if (d[0].id === 'datascenario0') { // TODO: was ist das? übersetzung
            return '<div style=\'background-color: #9A8322; padding:0.25em 0.5em;' +
              'white-space: nowrap; text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>' +
              'Scénario' + ': CHF ' + fixednumber + '</br>' + '</div>';
          } else {
            return '<div style=\'background-color: #155A49; color: white; padding:0.25em 0.5em;' +
              'white-space: nowrap; text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>' +
              '&nbsp; ' + 'Actuel' + ': CHF ' + fixednumber + '</br>' + '</div>';
          }
        }
        if (d[0]) {
          fixednumberScenario = Math.round(d[0].value).toString();
          for (i = 0; i < Math.floor((fixednumberScenario.length - 1) / 3); i++) {
            const spot = -(4 * i + 3);
            if (Math.abs(spot) < fixednumberScenario.length) {
              fixednumberScenario = fixednumberScenario.slice(0, spot) +
                '\'' + fixednumberScenario.slice(spot, fixednumberScenario.length);
            }
          }
        }
        const divContent = [];
        if (d[0].value > d[1].value) {
          divContent[0] = {content: 'Scénario' + ': CHF ' + fixednumberScenario, color: '9A8322; '};
          divContent[1] = {
            content: '&nbsp;' + 'Actuel' + ': CHF '
              + fixednumber, color: '155A49; color: white; '
          };
        } else {
          divContent[0] = {
            content: '&nbsp;' + 'Actuel' + ': CHF '
              + fixednumber, color: '155A49; color: white; '
          };
          divContent[1] = {content: 'Scénario' + ': CHF ' + fixednumberScenario, color: '9A8322; '};
        }
        return '<div style=\'background-color: #' + divContent[0].color + 'padding:0.25em 0.5em;' +
          'white-space: nowrap; text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
          + divContent[0].content + '</br>' + '</div>' + '<div style=\'background-color: #' + divContent[1].color
          + 'padding:0.25em 0.5em; white-space: nowrap; text-align: center;'
          + 'vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
          + divContent[1].content + '</div>';
      };
    }
    return function (d) {
      let i = 0;
      let fixednumber: string;
      let fixednumberScenario: string;
      if (d[1]) {
        fixednumber = Math.round(d[1].value).toString();
        for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
          const spot = -(4 * i + 3);
          if (Math.abs(spot) < fixednumber.length) {
            fixednumber = fixednumber.slice(0, spot) + '\'' + fixednumber.slice(spot, fixednumber.length);
          }
        }
      } else {
        fixednumber = Math.round(d[0].value).toString();
        for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
          const spot = -(4 * i + 3);
          if (Math.abs(spot) < fixednumber.length) {
            fixednumber = fixednumber.slice(0, spot) +
              '\'' + fixednumber.slice(spot, fixednumber.length);
          }
        }
        if (d[0].id === 'datascenario0') {
          return '<div style=\'background-color: #9A8322; padding:0.25em 0.5em;' +
            'white-space: nowrap; text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>' +
            'Szenario' + ': CHF ' + fixednumber + '</br>' + '</div>';
        } else {
          return '<div style=\'background-color: #155A49; color: white; padding:0.25em 0.5em;' +
            'white-space: nowrap; text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>' +
            '&nbsp; ' + 'Aktuell' + ': CHF ' + fixednumber + '</br>' + '</div>';
        }
      }
      if (d[0]) {
        fixednumberScenario = Math.round(d[0].value).toString();
        for (i = 0; i < Math.floor((fixednumberScenario.length - 1) / 3); i++) {
          const spot = -(4 * i + 3);
          if (Math.abs(spot) < fixednumberScenario.length) {
            fixednumberScenario = fixednumberScenario.slice(0, spot) +
              '\'' + fixednumberScenario.slice(spot, fixednumberScenario.length);
          }
        }
      }
      const divContent = [];
      if (d[0].value > d[1].value) {
        divContent[0] = {content: 'Szenario' + ': CHF ' + fixednumberScenario, color: '9A8322; '};
        divContent[1] = {
          content: '&nbsp;' + 'Aktuell' + ': CHF '
            + fixednumber, color: '155A49; color: white; '
        };
      } else {
        divContent[0] = {
          content: '&nbsp;' + 'Aktuell' + ': CHF '
            + fixednumber, color: '155A49; color: white; '
        };
        divContent[1] = {content: 'Szenario' + ': CHF ' + fixednumberScenario, color: '9A8322; '};
      }
      return '<div style=\'background-color: #' + divContent[0].color + 'padding:0.25em 0.5em;' +
        'white-space: nowrap; text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
        + divContent[0].content + '</br>' + '</div>' + '<div style=\'background-color: #' + divContent[1].color
        + 'padding:0.25em 0.5em; white-space: nowrap; text-align: center;'
        + 'vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
        + divContent[1].content + '</div>';
    };
  }

  graph4tooltip() {
    const object = {position: null, contents: null};
    object.position = function (tooltipData) {
      const point = tooltipData[0].x;

      const valueArray = d3.select(d3.selectAll('.c3-target-data2scenario0')
        .selectAll('.c3-bar').nodes()[point]).attr('d').split('L');
      const startCoordinates = parseInt(valueArray[1].split(','), 10);
      const endCoordinates = parseInt(valueArray[2].split(','), 10);
      const xPos = (endCoordinates - startCoordinates) / 2 + startCoordinates - 30;

      return {left: xPos, top: 0};
    };
    if (this.french) {
      object.contents = function (d) {
        let i;
        let fixednumber = Math.round(d[0].value).toString();
        for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
          const spot = -(4 * i + 3);
          if (Math.abs(spot) < fixednumber.length) {
            fixednumber = fixednumber.slice(0, spot) + '\'' + fixednumber.slice(spot, fixednumber.length);
          }
        }
        let fixednumberScenario = Math.round(d[1].value).toString();
        for (i = 0; i < Math.floor((fixednumberScenario.length - 1) / 3); i++) {
          const spot = -(4 * i + 3);
          if (Math.abs(spot) < fixednumberScenario.length) {
            fixednumberScenario = fixednumberScenario.slice(0, spot) + '\''
              + fixednumberScenario.slice(spot, fixednumberScenario.length);
          }
        }
        return '<div style=\'background-color: #155a49; color: white; padding:0.25em 0.5em; white-space: nowrap;' +
          'text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
          + '&nbsp;' + 'Actuel' + ': CHF ' + fixednumber + '</br>' + '</div>' +
          '<div style=\'background-color: #9A8322; padding:0.25em 0.5em; white-space: nowrap;' +
          'text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
          + 'Scénario' + ': CHF ' + fixednumberScenario + '</div>';
      };
    } else {
      object.contents = function (d) {
        let i;
        let fixednumber = Math.round(d[0].value).toString();
        for (i = 0; i < Math.floor((fixednumber.length - 1) / 3); i++) {
          const spot = -(4 * i + 3);
          if (Math.abs(spot) < fixednumber.length) {
            fixednumber = fixednumber.slice(0, spot) + '\'' + fixednumber.slice(spot, fixednumber.length);
          }
        }
        let fixednumberScenario = Math.round(d[1].value).toString();
        for (i = 0; i < Math.floor((fixednumberScenario.length - 1) / 3); i++) {
          const spot = -(4 * i + 3);
          if (Math.abs(spot) < fixednumberScenario.length) {
            fixednumberScenario = fixednumberScenario.slice(0, spot) + '\''
              + fixednumberScenario.slice(spot, fixednumberScenario.length);
          }
        }
        return '<div style=\'background-color: #155a49; color: white; padding:0.25em 0.5em; white-space: nowrap;' +
          'text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
          + '&nbsp;' + 'Aktuell' + ': CHF ' + fixednumber + '</br>' + '</div>' +
          '<div style=\'background-color: #9A8322; padding:0.25em 0.5em; white-space: nowrap;' +
          'text-align: center; vertical-align: bottom !important; font-size: 16px; height: 16px;\'>'
          + 'Szenario' + ': CHF ' + fixednumberScenario + '</div>';
      };
    }
    return object;
  }

  calculateDifference(standard, scenario, domElement) {
    const difference = Number(scenario) - Number(standard);
    let returnElement = 'CHF ' + this.dialogMethods.formatTable(Math.abs(Number(difference)));
    if (difference < 0) {
      returnElement = '- ' + returnElement;
      this.renderer.removeClass(domElement, 'zero');
      this.renderer.removeClass(domElement, 'plus');
      this.renderer.addClass(domElement, 'minus');
    } else if (difference === 0) {
      this.renderer.removeClass(domElement, 'minus');
      this.renderer.removeClass(domElement, 'plus');
      this.renderer.addClass(domElement, 'zero');
    } else {
      returnElement = '+ ' + returnElement;
      this.renderer.removeClass(domElement, 'zero');
      this.renderer.removeClass(domElement, 'minus');
      this.renderer.addClass(domElement, 'plus');
    }
    return returnElement;
  }

  getEvent(index) {
    const currentSzenario = this.dataService.getScenarios()[index].type;
    if (!this.french) {
      switch (ScenarioType[currentSzenario]) {
        // icon images
        case 'WEF':
          this.event.name = 'WEF';
          this.event.icon = 'home';
          break;
        case 'EarlyRetirementBuyIn':
          this.event.name = 'Einkauf frühzeitige Pensionierung';
          this.event.icon = 'attach_money';
          break;
        case 'PartRetirement':
          this.event.name = 'Teilpensionierung';
          this.event.icon = 'directions_run';
          break;
        case 'PensumReduction':
          this.event.name = 'Pensumveränderung';
          this.event.icon = 'local_mall';
          break;
        case 'EarlyRetirement':
          this.event.name = 'Frühpensionierung';
          this.event.icon = 'airline_seat_recline_normal';
          break;
        case 'Retirement':
          this.event.name = 'Pensionierung';
          this.event.icon = 'directions_walk';
          break;
        case 'BuyIn':
          this.event.name = 'Einkauf';
          this.event.icon = 'money';
          break;
        case 'PartRetirementBezug':
          this.event.name = 'Teilpensionierung mit Bezug';
          this.event.icon = 'directions_run';
          break;
        case 'WEFpayback':
          this.event.name = 'WEF Rückzahlung';
          this.event.icon = 'home';
          break;
        case 'PlanChange':
          this.event.name = 'Planwechsel';
          this.event.icon = 'transform';
          break;
      }
    } else {
      switch (ScenarioType[currentSzenario]) {
        // icon images
        case 'WEF':
          this.event.name = 'Rapport APL';
          this.event.icon = 'home';
          break;
        case 'EarlyRetirementBuyIn':
          this.event.name = 'Rachat retraite anticipée';
          this.event.icon = 'attach_money';
          break;
        case 'PartRetirement':
          this.event.name = 'Retraite partielle';
          this.event.icon = 'directions_run';
          break;
        case 'PensumReduction':
          this.event.name = 'Taux d’occupation';
          this.event.icon = 'local_mall';
          break;
        case 'EarlyRetirement':
          this.event.name = 'Retraite anticipée';
          this.event.icon = 'airline_seat_recline_normal';
          break;
        case 'Retirement':
          this.event.name = 'Retraite';
          this.event.icon = 'directions_walk';
          break;
        case 'BuyIn':
          this.event.name = 'Rachat';
          this.event.icon = 'money';
          break;
        case 'PartRetirementBezug':
          this.event.name = 'Retraite partielle';
          this.event.icon = 'directions_run';
          break;
        case 'WEFpayback':
          this.event.name = 'Remboursement APL';
          this.event.icon = 'home';
          break;
        case 'PlanChange':
          this.event.name = 'Changement de plan';
          this.event.icon = 'transform'; // TODO: adapt icon
          break;
      }
    }


  }

  setFlag(elem) {
    const fontSize = 66;
    elem.attr('font-size', fontSize);
    elem.style('fill', 'red');
    elem.attr('class', 'flag');
    elem.attr('id', 'flag');
    elem.style('transform', 'translate(-2px, 50px;)');
    if (this.dataService.IE === true) {
      elem.attr('x', (+elem.attr('x')).toString());
      elem.attr('y', (+elem.attr('y') + 46).toString());
    }
    // nbsp for spacing
    elem.text('°');
  }

  setTooltip(elem, margin, k) {
    if (elem._groups && elem._groups[0] && elem._groups[0][0] && this.clickboxContainer.nativeElement) {
      // create Clickbox(box to hover over)
      const clickbox = this.renderer.createElement('div');
      // create clickboxtooltip
      const clickboxtooltip = this.renderer.createElement('div');
      // needed?
      this.renderer.setStyle(clickboxtooltip, 'left', '10px');
      this.renderer.setStyle(clickboxtooltip, 'top', '10px');
      // outerparagraph (year)
      const outerparagraph = this.renderer.createElement('p');
      const currentSzenarien = this.szenarien[k];
      if (this.french) {
        outerparagraph.innerHTML = 'Mesure dans l\'année ' + currentSzenarien.year;
      } else {
        outerparagraph.innerHTML = 'Massnahmen im Jahr ' + currentSzenarien.year;
      }
      for (let forIndex = 0; forIndex < currentSzenarien.events.length; forIndex++) {
        const innerparagraph = this.renderer.createElement('p');
        innerparagraph.innerHTML = currentSzenarien.events[forIndex].name;
        this.renderer.appendChild(clickboxtooltip, innerparagraph);
      }
      this.renderer.insertBefore(clickboxtooltip, outerparagraph, clickboxtooltip.firstChild);
      const x = elem._groups[0][0].getAttribute('x');
      const y = elem._groups[0][0].getAttribute('y');

      const left: number = margin + 10 + parseInt(x, 10);
      let top: number = y - 25 + (3.17 * 16);

      /*eventRect-width is needed to stop the tooltip from being cut off by the eventRect */
      const eventRect: Element = document.getElementById('chart3').getElementsByClassName('c3-event-rect').item(0);
      let tooltipTop: number = top + 80;
      const tooltipLeft: number = (left + 28 - (173 * left / +eventRect.getAttribute('width')));

      /*IE needs some further adjustment*/
      if (this.dataService.IE === true) {
        top -= 45;
        tooltipTop -= 45;
      }

      this.renderer.setStyle(clickboxtooltip, 'display', 'none');
      this.renderer.setStyle(clickboxtooltip, 'left', this.makeString(tooltipLeft));
      this.renderer.setStyle(clickboxtooltip, 'top', this.makeString(tooltipTop));

      this.renderer.setStyle(clickbox, 'left', this.makeString(left));
      this.renderer.setStyle(clickbox, 'top', this.makeString(top));

      // add the two to the documens use Renderer
      this.renderer.insertBefore(this.clickboxContainer.nativeElement, clickboxtooltip, this.refButton.nativeElement);
      this.renderer.insertBefore(this.clickboxContainer.nativeElement, clickbox, this.refButton.nativeElement);
      // add classes
      this.renderer.addClass(clickboxtooltip, 'clickboxtooltip');
      this.renderer.addClass(clickbox, 'clickbox');
      this.renderer.addClass(outerparagraph, 'titletooltip');
      // add mouseleave/enter
      this.renderer.setAttribute(clickbox, 'id', k);

      this.renderer.listen(clickbox, 'mouseenter', (event) => {
        this.showTooltip(event);
      });
      this.renderer.listen(clickbox, 'mouseleave', (event) => {
        this.hideTooltip(event);
      });
      if (k + 1 === this.szenarien.length) {
        this.renderer.listen(clickbox, 'click', (event) => {
          this.openDialogFlag(event, currentSzenarien);
        });
      }
      k++;
      return k;
    }
  }

  openDialogFlag(event, currentSzenario) {
    // year of Szenario
    const year = currentSzenario.year;
    // names of events (example "WEF")
    const scenarios = currentSzenario.scenario;
    const names: string[] = [];
    for (let i = 0; i < currentSzenario.events.length; i++) {
      names.push(currentSzenario.events[i].name);
    }
    // value
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.id = 'BVGDialog';
    dialogConfig.data = {
      year: year,
      names: names,
      scenarios: scenarios,
    };
    this.scenarioInfo.open(ScenarioInfoComponent, dialogConfig);
  }

  deleteLastEvent(): void {
    // copy paste from scenario-info
    this.dataService.deleteScenario();
    setTimeout(() => {                                // FOR GRAPH RESIZING
      window.dispatchEvent(new Event('resize'));
    }, 60);
    return;
  }

  makeString(item): string {
    return (Math.abs(item)).toString() + 'px';
  }

  showTooltip(e) {
    const id = e.srcElement.id;
    const tooltip: Element = document.getElementsByClassName('clickboxtooltip')[id];
    this.renderer.setStyle(tooltip, 'display', 'block');
  }

  hideTooltip(e) {
    const id = e.srcElement.id;
    const tooltip: Element = document.getElementsByClassName('clickboxtooltip')[id];
    this.renderer.setStyle(tooltip, 'display', 'none');
  }

  switchContent(j) {
    if (j === 1) {
      if (this.table1.nativeElement.style.display === 'block') {
        this.displayStandard1 = 'block';
        this.displayStandard2 = 'none';
        this.lineChartContainer.nativeElement.style.overflowY = 'hidden';
      } else {
        this.displayStandard2 = 'block';
        this.displayStandard1 = 'none';
        this.lineChartContainer.nativeElement.style.overflowY = 'scroll';
      }
    } else {
      /*the for loops are there to hide the clickboxes in "Detailansicht"*/
      let clickboxes = document.getElementsByClassName('clickbox');
      if (this.table3.nativeElement.style.display === 'block') {
        this.displayScenario1 = 'block';
        this.displayScenario2 = 'none';
        this.clickboxContainer.nativeElement.style.overflowY = 'hidden';
        document.getElementsByClassName('barChartContainer')[1].setAttribute('style', 'overflow-y: hidden');
        this.onResize();
      } else {
        this.displayScenario1 = 'none';
        this.displayScenario2 = 'block';
        this.clickboxContainer.nativeElement.style.overflowY = 'scroll';
        document.getElementsByClassName('barChartContainer')[1].setAttribute('style', 'overflow-y: scroll');
        for (let i = 0; i < clickboxes.length; i++) {
          clickboxes[i].setAttribute('hidden', 'true');
        }
      }
      clickboxes = null;
    }
  }

  changePanel(id) {
    // clean up
    switch (id) {
      case 1:
        if (this.isExpanded1) {
          this.isExpanded1 = false;
          this.button1.nativeElement.style.transform = 'rotate(0deg)';
          this.button1.nativeElement.style.color = '#8F7C50';
        } else {
          this.isExpanded1 = true;
          this.button1.nativeElement.style.transform = 'rotate(-180deg)';
          this.button1.nativeElement.style.color = 'grey';
        }
        break;
      case 2:
        if (this.isExpanded2) {
          this.isExpanded2 = false;
          this.button2.nativeElement.style.color = '#8F7C50';
          this.button2.nativeElement.style.transform = 'rotate(0deg)';
        } else {
          this.isExpanded2 = true;
          this.button2.nativeElement.style.transform = 'rotate(-180deg)';
          this.button2.nativeElement.style.color = 'grey';
        }
        break;
      case 3:
        if (this.isExpanded3) {
          this.isExpanded3 = false;
          this.button3.nativeElement.style.transform = 'rotate(0deg)';
          this.button3.nativeElement.style.color = '#8F7C50';
        } else {
          this.isExpanded3 = true;
          this.button3.nativeElement.style.transform = 'rotate(-180deg)';
          this.button3.nativeElement.style.color = 'grey';
        }
        break;
      case 'TP1':
        if (this.isExpandedTP1) {
          this.isExpandedTP1 = false;
          this.buttonTP1.nativeElement.style.color = '#8F7C50';
          this.buttonTP1.nativeElement.style.transform = 'rotate(0deg)';
        } else {
          this.isExpandedTP1 = true;
          this.buttonTP1.nativeElement.style.transform = 'rotate(-180deg)';
          this.buttonTP1.nativeElement.style.color = 'grey';
        }
        break;
      case 'TP2':
        if (this.isExpandedTP2) {
          this.isExpandedTP2 = false;
          this.buttonTP2.nativeElement.style.color = '#8F7C50';
          this.buttonTP2.nativeElement.style.transform = 'rotate(0deg)';
        } else {
          this.isExpandedTP2 = true;
          this.buttonTP2.nativeElement.style.transform = 'rotate(-180deg)';
          this.buttonTP2.nativeElement.style.color = 'grey';
        }
        break;
    }
    if (this.isExpanded2 || this.isExpanded1 || this.isExpanded3 && !this.isExpanded2) {
      this.renderer.addClass(this.panelHeader.nativeElement, 'grey');
      this.renderer.removeClass(this.panelHeader.nativeElement, 'white');
    } else {
      this.renderer.addClass(this.panelHeader.nativeElement, 'white');
      this.renderer.removeClass(this.panelHeader.nativeElement, 'grey');
    }
  }

  openScenarioSelection() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.dialog.open(ScenarioSelectionComponent, dialogConfig);
  }

  openGrunddaten() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.dialog.open(GrunddatenComponent, dialogConfig);
  }

  getReport() {
    this.dataService.getReportOutput(this.french);
  }

  setPartPensionValues(pensionProjection) {
    this.conversionRatePartPension = [];
    this.partPensionValues = [];
    this.partPensionAge = [];
    for (let i = 0; pensionProjection[i].timestampType !== 'Pension'; i++) {
      if (pensionProjection[i].timestampType === 'PartPension'
        && pensionProjection[i].pensum !== pensionProjection[i + 1].pensum && pensionProjection[i].conversionRate) {
        this.conversionRatePartPension.push(this.dialogMethods.formatPercentForDisplay(pensionProjection[i].conversionRate * 100));
        if (pensionProjection[i + 1].timestampType === 'Pension') {
          this.partPensionValues.push(this.dialogMethods.formatTable(
            pensionProjection[i + 1].projectedPension
          ));
        } else {
          this.partPensionValues.push(this.dialogMethods.formatTable(pensionProjection[i + 2].projectedPension));
        }
        this.partPensionAge.push(String(this.dialogMethods.calculateAgeAtDate(this.dataService.getData().dateOfBirth,
          pensionProjection[i].date)));
      }
    }
  }

  openVideo() {
    const dialogConfig = new MatDialogConfig();
    this.dialog.open(VideoComponent, dialogConfig);
  }
}
