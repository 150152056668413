import {CashFlow} from './CashFlow';


export interface ScenarioInput {
  type: ScenarioType;
  date: any;
  id: number;
  cashflows: CashFlow[];
}

export enum ScenarioType {
  WEF,
  EarlyRetirementBuyIn,
  PartRetirement,
  PensumReduction,
  EarlyRetirement,
  Retirement,
  BuyIn,
  PartRetirementBezug,
  WEFpayback,
  PlanChange
}
