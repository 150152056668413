import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {BackendInput} from '../data-type/input/BackendInput';
import {environment} from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic dXNlcjpjYWxtMDlzZzIwMTc='
  })
};

const httpOptionsPdf = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic dXNlcjpjYWxtMDlzZzIwMTc=',
    Accept: 'application/pdf'
  }),
  observe: 'response',
  responseType: 'blob'
};

@Injectable({
  providedIn: 'root'
})

export class BackendConnectorService {

  private serviceUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  requestPensionCalculatorService(dataList: BackendInput): Observable<any> {
    return this.http.post<any>(this.serviceUrl + '/calculatePension', dataList, httpOptions);
  }

  requestMaximumBuyInService(dataList: BackendInput): Observable<any> {
    return this.http.post<any>(this.serviceUrl + '/dataInput', dataList, httpOptions);
  }

  requestReportService(dataList: BackendInput): Observable<HttpResponse<Blob>> {
    return this.http.post(this.serviceUrl + '/report', dataList, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic dXNlcjpjYWxtMDlzZzIwMTc=',
        Accept: 'application/pdf'
      }),
      observe: 'response',
      responseType: 'blob'
    });
  }

}
