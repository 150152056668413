<div class="dialog">
  <h2 mat-dialog-title i18n="dialog title|The title for this dialog@@scenarioTitle">Szenario
    <button mat-flat-button mat-dialog-close class="rightBound">
      <i class="material-icons closeDialogIcon">
        clear
      </i>
    </button>
  </h2>
  <hr>
  <mat-dialog-content i18n="dialog description|The description for this dialog@@scenarioDescriptionPartOne">
    Die PK Siemens bietet Ihnen verschiedene Handlungsoptionen, um Ihre Vorsorge optimal zu planen. Wählen Sie ein
    Szenario aus und vergleichen Sie die
    Leistungen mit Ihrer aktuellen Vorsorgesituation.
  </mat-dialog-content>
  <mat-dialog-content i18n="dialog description|The description for this dialog@@scenarioDescriptionPartTwo">
    <br>
    Da Sie mehrere Szenarien kumulativ simulieren können, müssen diese in <b>chronologischer</b> Reihenfolge simuliert
    werden.
  </mat-dialog-content>
  <div class="contentContainer">
    <div class="divD" matTooltip="{{disabledEinkaufText}}">
      <button #einkaufButton (click)="openEinkauf()" [mat-dialog-close] [disabled]="disabledEinkauf"
              i18n-matTooltip="scenario tooltipOptionalBuy|second obligatory tooltip in scenario@@scenarioTooltipOptionalBuy"
              matTooltip="Mit einem freiwilligen Einkauf in die Pensionskasse können Sie Vorsorgelücken schliessen und so von höheren Rentenleistungen profitieren. Zudem sind Einkäufe von der Steuer absetzbar. Prüfen Sie hier, wie sich ein Einkauf auf Ihre Vorsorgesituation auswirkt."
              matTooltipClass="mat-tooltip" class="buttonD">
        <div class="inlineBlock">
          <mat-icon class="customIcon buttonIcons iconD align-icon" svgIcon="newMoney"></mat-icon>
        </div>
        <span class="fontInherit" i18n="dialog scenarioSelection|first option in scenario dialog@@scenarioEinkauf">
          Einkauf
        </span>
      </button>
    </div>
    <div class="divD" matTooltip="{{disabledPlanText}}">
      <button #planButton (click)="openPlan()" [disabled]="disabledPlan" [mat-dialog-close]
              i18n-matTooltip="scenario tooltipPlan|tooltip for plan @@scenarioTooltipPlan"
              matTooltip="Bei der Pensionskasse Siemens können Sie zwischen drei Sparplänen wählen und so Ihren Lebensstandard im Ruhestand eigenverantwortlich beeinflussen.
               Simulieren Sie die Auswirkungen eines Planwechsels auf Ihre Vorsorgesituation."
              matTooltipClass="mat-tooltip" class="floatL buttonD">
        <i class="material-icons buttonIcons iconD align-icon">
          transform
        </i>
        <span class="fontInherit lineHeight" i18n="dialog scenarioPlan|plan option in scenario dialog@@scenarioPlan">
          Planwechsel
        </span>
      </button>
    </div>
    <div class="divD" matTooltip="{{disabledWEFText}}">
      <button #wefButton (click)="openWEF()" [mat-dialog-close] [disabled]="disabledWEF"
              i18n-matTooltip="scenario tooltipSaved|third obligatory tooltip in scenario@@scenarioTooltipSaved"
              matTooltip="Sie können Ihr Sparguthaben zur Finanzierung von Wohneigentum vorbeziehen oder verpfänden. Prüfen Sie hier, wie sich ein WEF-Vorbezug oder eine WEF-Rückzahlung auf Ihre Vorsorgesituation auswirken. "
              matTooltipClass="mat-tooltip" class="buttonD">
        <i class="material-icons buttonIcons iconD align-icon">
          home
        </i>
        <span class="fontInherit" i18n="dialog scenarioSelection|second option in scenario dialog@@scenarioWEF">
          WEF
        </span>
      </button>
    </div>
    <div class="divD" matTooltip="{{disabledArbeitText}}">
      <button #arbeitButton (click)="openArbeit()" [mat-dialog-close] [disabled]="disabledArbeit"
              i18n-matTooltip="scenario tooltipPensum|fourth obligatory tooltip in scenario@@scenarioTooltipPensum"
              matTooltip="Ändert sich Ihr Jahreslohn oder der Beschäftigungsgrad, wirkt sich das auf Ihre Vorsorgeleistungen aus. Simulieren Sie hier die Auswirkungen."
              matTooltipClass="mat-tooltip" class="buttonD">
        <i class="material-icons buttonIcons iconD align-icon">
          work
        </i>
        <span class="fontInherit"
              i18n="dialog scenarioSelection|third option in scenario dialog@@scenarioArbeitspensum">
          Arbeitspensum
        </span>
      </button>
    </div>
    <div class="divD" matTooltip="{{disabledPensionierungText}}">
      <button #pensionierungButton (click)="openPensionierung()" [disabled]="disabledPensionierung" [mat-dialog-close]
              i18n-matTooltip="scenario tooltipSimulate|fifth obligatory tooltip in scenario@@scenarioTooltipSimulate"
              matTooltip="Simulieren Sie hier, wie sich der Zeitpunkt der Pensionierung auf Ihre Altersleistung auswirkt – oder ein Kapitalbezug."
              matTooltipClass="mat-tooltip" class="floatL buttonD">
        <div class="inlineBlockMargin">
          <mat-icon class="customIcon buttonIcons iconD align-icon" svgIcon="newOld"></mat-icon>
        </div>
        <span class="fontInherit"
              i18n="dialog scenarioSelection|fourth option in scenario dialog@@scenarioPensionierung">
          Pensionierung
        </span>
      </button>
    </div>

  </div>
</div>
