<div class="elementContainer">
  <div class="description">
    <p i18n="home video|The text about the video@@homeVideo">
      Mit dem Rentenrechner der Pensionskasse Siemens können Sie verschiedene Vorsorgeszenarien simulieren und die
      Auswirkungen auf Ihre persönlichen Vorsorgeleistungen analysieren.
      Im <b class="link" (click)="openVideo()">Demovideo</b> zeigen wir Ihnen auf, wie Sie den Rentenrechner bedienen
      können.
    </p>
  </div>
  <div class="pageTitle">
    <h2 i18n="grunddaten title|The title for this subpage@@homeTitle">
      Grunddaten
    </h2>
  </div>
  <div class="description">
    <p i18n="grunddaten description|The description for this subpage@@homeDescription">
      Damit wir Ihre Vorsorgeleistungen berechnen können, brauchen wir Ihre persönlichen Daten. Sie finden diese auf
      Ihrem aktuellen Vorsorgeausweis. Bitte füllen Sie alle Felder aus und klicken Sie auf «Weiter».
    </p>
  </div>
  <div class="form">
    <form #createForm="ngForm">

      <mat-dialog-content class="entryField" [ngSwitch]="errorInformationDate">
        <div class="fixedWidth" i18n="grunddaten birthDate|first question in home subpage@@homeBDate">
          Geburtsdatum
        </div>
        <div class="iconDiv">
          <div class="secondIcon">
          </div>
          <div class="firstIcon">
          </div>
        </div>
        <div class="layoutHelper"></div>
        <i *ngSwitchCase="'minDate'" #warning
           i18n-matTooltip="home tooltipMinEighteen|first optional tooltip in home@@homeTooltipMinEighteen"
           matTooltip="Sie müssen mindestens 18 Jahre alt sein um diese Simulation benützen zu können."
           class="material-icons warning">
          warning
        </i>
        <i *ngSwitchCase="'maxDate'" #warning
           i18n-matTooltip="home tooltipMaxSixtyfive|second optional tooltip in home@@homeTooltipMaxSixtyfive"
           matTooltip="Sie dürfen maximal 65 Jahre alt sein um diese simulation bezützen zu können."
           class="material-icons warning">
          warning
        </i>
        <mat-form-field>
          <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                 i18n-placeholder="datepicker|datepicker in home@@datePicker1Home" placeholder="dd.mm.yyyy"
                 name="dateOfBirth" (dateChange)='dateCheck(backendInput.dateOfBirth)'
                 [(ngModel)]="backendInput.dateOfBirth" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="multi-year" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
      </mat-dialog-content>
      <hr>

      <mat-dialog-content class="entryField">
        <div class="fixedWidth" i18n="grunddaten entry|second question in home subpage@@homeEntry">
          Eintritt vor 01.07.2017
        </div>
        <div class="iconDiv radioButtonIcon">
          <div class="firstIcon">
            <i i18n="EintrittIcon" class="rotated material-icons"
               i18n-matTooltip="home tooltipBefore2017|first obligatory tooltip in home@@homeTooltipBefore2017"
               matTooltip="Waren Sie schon vor dem 01.07.2017 bei der PK Siemens versichert?">error</i>
          </div>
        </div>
        <div class="layoutHelper"></div>
        <div class="secondIcon"></div>
        <mat-radio-group class="balancingRadio" name="joiningBefore" [(ngModel)]="joiningBefore" ngModel required>
          <mat-radio-button class="yes" color="primary" value="true" i18n="ja|ja@@homeJa">Ja</mat-radio-button>
          <mat-radio-button color="primary" value="false" i18n="nein|nein@@homeNein">Nein</mat-radio-button>
        </mat-radio-group>
      </mat-dialog-content>
      <hr>

      <mat-dialog-content class="entryField">
        <div class="fixedWidth" i18n="grunddaten employmentRate|third question in home subpage@@homeEmploymentRate">
          Beschäftigungs&shy;grad
        </div>
        <div class="iconDiv sliderIcon">
          <div class="firstIcon">
            <i i18n="BeschGradIcon" class="rotated material-icons"
               i18n-matTooltip="home tooltipPensum|second obligatory tooltip in home@@homeTooltipPensum"
               matTooltip="Bitte geben Sie hier Ihren Beschäftigungsgrad ein.">error</i>
          </div>
        </div>
        <div class="layoutHelper"></div>
        <div *ngIf="!percentcheckerror" class="secondIcon"></div>
        <i *ngIf="percentcheckerror" #warning
           i18n-matTooltip="home tooltipNumbersOnly|third optional tooltip in home@@homeTooltipNumbersOnly"
           matTooltip="Bitte geben Sie nur Zahlen in dieses Eingabefeld ein." class="material-icons warning">
          warning
        </i>

        <!--Roger-->
        <app-slider [min]="0" [max]="100" [(value)]="backendInput.pensum" [isPercentage]="true"></app-slider>
      </mat-dialog-content>

      <hr>

      <mat-dialog-content class="entryField">
        <div class="fixedWidth" i18n="grunddaten deadLine|ninth question in home subpage@@homeDeadLine">
          Stichtag Vorsorge&shy;ausweis
        </div>
        <div class="iconDiv">
          <div class="firstIcon">
            <i i18n="StichtagIcon" class="datePickIcon rotated material-icons"
               i18n-matTooltip="home tooltipWhatDate|eighth obligatory tooltip in home@@homeTooltipWhatDate"
               matTooltip="An welchem Datum wurde Ihr aktuellster Vorsorgeausweis ausgestellt? ">error</i>
          </div>
        </div>
        <div class="layoutHelper"></div>
        <div class="secondIcon"></div>
        <mat-form-field class="title miniLayout">
          <input [(ngModel)]="backendInput.insuranceCardDate" matInput [matDatepicker]="bleb"
                 i18n-placeholder="datepicker|datepicker in home@@datePicker2Home"
                 placeholder="dd.mm.yyyy" name="insuranceCardDate" ngModel required>
          <mat-datepicker-toggle matSuffix [for]="bleb"></mat-datepicker-toggle>
          <mat-datepicker #bleb startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </mat-dialog-content>

      <hr>

      <mat-dialog-content class="entryField">
        <div class="fixedWidth" i18n="grunddaten salary|fourth question in home subpage@@homeSalary">
          Jahreslohn
        </div>
        <div class="iconDiv">
          <div class="firstIcon">
            <i i18n="JahreslohnIcon" class="rotated material-icons"
               i18n-matTooltip="home tooltipSalary|third obligatory tooltip in home@@homeTooltipSalary"
               matTooltip="Als Jahreslohn gilt der durch den Arbeitgeber gemeldete Jahreslohn (= Jahresgehalt und Jahreszielbonus).">error</i>
          </div>
        </div>
        <div class="layoutHelper"></div>
        <div *ngIf="!salarycheckerror" class="secondIcon"></div>
        <i *ngIf="salarycheckerror" #warning
           i18n-matTooltip="home tooltipBiggerThan|fourth optional tooltip in home@@homeTooltipBiggerThan"
           matTooltip="Ihr anrechenbarer muss grösser als 21'330 CHF sein." class="material-icons warning">
          warning
        </i>
        <mat-form-field>
          <div class="alignRight">
            <input matInput placeholder="CHF" name="annualSalary" required (change)='salaryCheck($event)'
                   (input)='dataService.IE ? salaryCheck($event): null'
                   (blur)="this.dialogMethods.thousandSeparator($event)"
                   (keyup)="this.dialogMethods.thousandSeparator($event)"
                   [(ngModel)]="backendInput.annualSalary">
          </div>
        </mat-form-field>
      </mat-dialog-content>
      <hr>

      <mat-dialog-content class="entryField">
        <div class="fixedWidth" i18n="grunddaten saved|fifth question in home subpage@@homeSaved">
          Sparguthaben
        </div>
        <div class="iconDiv">
          <div class="firstIcon">
            <i i18n="SparguthabenIcon" class="rotated material-icons"
               i18n-matTooltip="home tooltipFindSaved|fourth obligatory tooltip in home@@homeTooltipFindSaved"
               matTooltip="Sie finden Ihr aktuelles Sparguthaben auf Ihrem persönlichen Vorsorgeausweis unter «Freizügigkeitsleistung».">error</i>
          </div>
          <div class="secondIcon">
          </div>
        </div>
        <div class="layoutHelper"></div>
        <div class="secondIcon"></div>
        <mat-form-field>
          <div class="alignRight">
            <input matInput placeholder="CHF" name="retirementAsset" required
                   (blur)="this.dialogMethods.thousandSeparator($event)"
                   (keyup)="this.dialogMethods.thousandSeparator($event)"
                   [(ngModel)]="backendInput.currentRetirementAsset">
          </div>
        </mat-form-field>
      </mat-dialog-content>
      <hr>
      <mat-dialog-content class="entryField">
        <div class="fixedWidth" i18n="grunddaten plan|sixth question in home subpage@@homePlan">
          Sparplan
        </div>
        <div class="iconDiv">
          <i i18n="SparplanIcon" class="rotated material-icons"
             i18n-matTooltip="home tooltipSeePDF|fifth obligatory tooltip in home@@homeTooltipSeePDF"
             matTooltip="Sie finden Ihren aktuellen Sparplan auf Ihrem persönlichen Vorsorgeausweis. Zur Wahl stehen die drei Sparpläne Standard, Plus und Surplus.">error</i>
        </div>
        <div class="secondIcon"></div>
        <div class="layoutHelper"></div>
        <mat-form-field>
          <div class="alignRight">
            <mat-select [(ngModel)]="pensionPlan" name="pensionPlan"
                        i18n-placeholder="home placeholderOptions|placeholder with three options@@homePlaceholderOptions"
                        placeholder="Standard/Plus/Surplus" ngModel
                        disableRipple required>
              <mat-option value="STANDARD" i18n="grunddaten firstPlan|plan option@@homeFirstPlan">Standard</mat-option>
              <mat-option value="PLUS" i18n="grunddaten secondPlan|plan option@@homeSecondPlan">Standard Plus
              </mat-option>
              <mat-option value="SURPLUS" i18n="grunddaten thirdPlan|plan option@@homeThirdPlan">Standard Surplus
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>
      </mat-dialog-content>

      <hr>


      <mat-dialog-content class="entryField"
                          *ngIf="joiningBefore != null && joiningBefore == 'true' && dialogMethods.dateBefore1966(backendInput.dateOfBirth)">
        <div class="fixedWidth" i18n="grunddaten singleIn|seventh question in home subpage@@homeSingleIn">
          Ungekürzte Einmaleinlage
        </div>
        <div class="iconDiv">
          <div class="firstIcon">
            <i i18n="EinmaleinlageIcon" class="rotated material-icons"
               i18n-matTooltip="home tooltipInfosSingleIn|sixth obligatory tooltip in home@@homeTooltipInfosSingleIn"
               matTooltip="Dieser Wert kann bei der Geschäftsstelle erfragt werden.">error</i>
          </div>
          <div class="secondIcon">
          </div>
        </div>
        <div class="layoutHelper"></div>
        <div class="secondIcon"></div>
        <mat-form-field>
          <div class="alignRight">
            <input matInput placeholder="CHF" name="singlePremium" required
                   (blur)="this.dialogMethods.thousandSeparator($event)"
                   (keyup)="this.dialogMethods.thousandSeparator($event)"
                   [(ngModel)]="backendInput.singlePremium">
          </div>
        </mat-form-field>
      </mat-dialog-content>

      <div
        *ngIf="joiningBefore != null && joiningBefore == 'true' && dialogMethods.dateBefore1966(backendInput.dateOfBirth)">
        <hr>
      </div>


      <mat-dialog-content class="entryField furtherDescription" required
                          i18n="additionalInformation|additional information in grunddaten@@homeAdditional">
        Erweiterte Eingaben
        <mat-checkbox color="primary" class="marginLP" (change)="showDialog($event)"></mat-checkbox>
      </mat-dialog-content>

      <mat-accordion id="bonusInfo" flat>
        <mat-expansion-panel class="mat-elevation-z0" id="bonusInfoInsides" expanded="{{isExpanded}}" hideToggle>

          <div class="entryFieldCollapse">
            <div class="title" i18n="grunddaten projection|tenth question in home subpage@@homeProjection">
              Projektionszins
            </div>
            <i i18n="ProjektionszinsIcon" class="rotated material-icons"
               i18n-matTooltip="home tooltipEstimatedInterest|ninth obligatory tooltip in home@@homeTooltipEstimatedInterest"
               matTooltip="Mit diesem (angenommenen) Zinssatz wird das vorhandene Sparguthaben mit den Sparbeiträgen auf das (ordentliche) Pensionierungsalter hochgerechnet. Die Höhe des effektiven Zinssatzes wird jährlich durch den Stiftungsrat festgelegt.">error</i>
            <div class="layoutHelper"></div>
            <i *ngIf="projectionError" #warning
               i18n-matTooltip="home tooltipProjection|fifth obligatory tooltip in home@@homeTooltipProjection"
               matTooltip="Der Projektionszins kann nicht grösser als 10% sein."
               class="material-icons warning">
              warning
            </i>
            <div *ngIf="!projectionError" class="secondIcon"></div>
            <mat-form-field class="title miniLayout">
              <input matInput percentage-field [(ngModel)]="backendInput.projectionInterest" name="projectionInterest"
                     (keyup)="projectionInterestCorrection($event)" ngModel [required]="isExpanded"
                     pattern="[0-9.%]{1,}">
              <span matSuffix>%</span>
            </mat-form-field>
          </div>
          <mat-divider></mat-divider>

          <div class="entryFieldCollapse">
            <div class="title" i18n="Lohnkarriere">
              Lohnkarriere
            </div>
            <i i18n="LohnkarriereIcon" class="rotated material-icons"
               i18n-matTooltip="home tooltipSalaryInterest|tenth obligatory tooltip in home@@homeTooltipSalaryInterest"
               matTooltip="Angenommene Lohnkarriere zur Fortschreibung des Lohneinkommens. 1% p.a. entspricht einer jährlichen Erhöhung des Einkommens um 1%.">error</i>
            <div class="layoutHelper"></div>
            <div class="secondIcon"></div>
            <mat-form-field class="title miniLayout">
              <mat-select [(ngModel)]="salaryGrowthString" ngModel id="salaryGrowth" name="salaryGrowth"
                          placeholder="0-3%" disableRipple [required]="isExpanded">
                <mat-option value="0.0">0%</mat-option>
                <mat-option value="0.01">1%</mat-option>
                <mat-option value="0.02">2%</mat-option>
                <mat-option value="0.03">3%</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-divider></mat-divider>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
    <app-save-button
      [disabledCondition]="!createForm.valid || salarycheckerror || datecheckerror || projectionError || percentcheckerror && isExpanded || backendInput.pensum == 0"
      [form]="createForm"
      (firedEvent)="logdata()"
      [dialog]="false"
    ></app-save-button>

  </div>
  <img class="placeholder" src="assets/images/20181210_Bild Rechner.png" alt="Calculator">
</div>
