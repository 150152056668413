<div #slider class="slider-wrapper">
  <span>{{dialogMethods.thousandSeparatorWithReturn((isPercentage) ? minimumPercentageValue : min)}}<span
    *ngIf="isPercentage">%</span> <span *ngIf="!isPercentage"> CHF</span></span>
  <div class="inline-block">
    <div>
      <mat-form-field class="input">
        <input name="SliderValue" [(ngModel)]="value" matInput placeholder="CHF"
               (blur)="validateInput($event.srcElement.value)" (keyup)="onInputChange($event)"
               [placeholder]="(isPercentage) ? '' : 'CHF'" required>
      </mat-form-field>
    </div>
    <div>
      <mat-slider color="primary" [min]="(isPercentage) ? minimumPercentageValue : min"
                  [max]="(isPercentage) ? 100 : max" [value]="this.dataService.correctNumbers(value)"
                  (input)="onSliderChange($event)">
      </mat-slider>
    </div>
  </div>
  <span>{{dialogMethods.thousandSeparatorWithReturn((isPercentage) ? 100 : max)}}<span
    *ngIf="isPercentage">%</span> <span *ngIf="!isPercentage"> CHF</span></span>
</div>
