import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-invalid',
  templateUrl: './invalid.component.html',
  styleUrls: ['./invalid.component.css']
})
export class InvalidComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
    setTimeout(function () {
      window.location.href = '';
    }, 5000);
  }
}
