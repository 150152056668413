<div class="dialog">
  <h1 mat-dialog-title i18n="dialog title|The title for this dialog@@scenarioInfoTitle">
    BVG Massnahmen
  </h1>
  <form>
    <div class="titleContainer">
      <div>
        <h4 i18n="dialog scenarioInfoYear|The year in which a scenario takes place@@scenarioInfoYear">Jahr</h4>
      </div>
      <div></div>
      <div>
        <h4 i18n-matTooltip="scenarioinfo tooltip|first obligatory tooltip in scenarioinfo@@scenarioinfoTooltip"
            matTooltip="Älteste zuerst" matTooltipPosition="right"
            i18n="dialog scenarioInfoScenario|The scenario which takes place@@scenarioInfoScenario">BVG Massnahmen</h4>
      </div>
    </div>
    <mat-expansion-panel class="expansionPanelSeparation"
                         *ngFor="let currentEvent of latestEvents, last as isLast" expanded="{{true}}"
                         [hideToggle]>

      <div class="listContainer">

        <div>
          <p>{{eventList[eventList.length - 1].year}}</p>
        </div>

        <div *ngIf="currentEvent.icon === 'money'" class="holderI">
          <div class="negativMarginBottom">
            <mat-icon class="customIcon buttonIcons" svgIcon="newMoney"></mat-icon>
          </div>
          <p class="name specialScenarioName" i18n="vorsorge buyIn|Buy in normal@@vorsorgeBuyIn">Einkauf</p>
        </div>
        <div *ngIf="currentEvent.icon === 'attach_money'" class="holderI">
          <div class="negativMarginBottom">
            <mat-icon class="customIcon buttonIcons" svgIcon="newMoney"></mat-icon>
          </div>
          <p class="name specialScenarioName" i18n="vorsorge buyInEarly|Early pension buy in@@vorsorgeBuyInEarly">
            Einkauf für vorzeitige Pensionierung</p>
        </div>
        <div *ngIf="currentEvent.icon === 'directions_walk'" class="holderI">
          <div class="negativMarginBottom">
            <mat-icon class="customIcon buttonIcons" svgIcon="newOld"></mat-icon>
          </div>
          <p class="name specialScenarioName" i18n="vorsorge pension|Pension normal@@vorsorgePension">Pensionierung</p>
        </div>
        <div *ngIf="currentEvent.icon === 'directions_run'" class="holderI">
          <div class="negativMarginBottom">
            <mat-icon class="customIcon buttonIcons" svgIcon="newMoney"></mat-icon>
          </div>
          <p class="name specialScenarioName" i18n="vorsorge partPension|Partial pension@@vorsorgePartPension">
            Teilpensionierung</p>
        </div>
        <div class="layout" *ngIf="currentEvent.icon === 'airline_seat_recline_normal'">
          <div class="holderI"><i class="listIcon material-icons icon">airline_seat_recline_normal</i>
            <p class="name" i18n="vorsorge earlyPension|Early Pension@@vorsorgeEarlyPension">vorzeitige
              Pensionierung</p></div>
        </div>
        <div class="layout" *ngIf="currentEvent.icon === 'local_mall'">
          <div class="holderI"><i class="listIcon material-icons icon">local_mall</i>
            <p class="name" i18n="vorsorge pensum|Working pensum@@vorsorgePensum">Arbeitspensum</p></div>
        </div>
        <div class="layout" *ngIf="currentEvent.icon === 'home'">
          <div class="holderI"><i class="listIcon material-icons icon">home</i>
            <p class="name">WEF<span *ngIf="currentEvent.name == 'payback'"
                                     i18n="vorsorge payBack|WEF Payback@@vorsorgePayBack"> Rückzahlung</span></p></div>
        </div>
        <div class="layout" *ngIf="currentEvent.icon === 'transform'">
          <div class="holderI"><i class="listIcon material-icons icon">transform</i>
            <p class="name" i18n="vorsorge plan|Planwechsel@@vorsorgePlan">Planwechsel</p></div>
        </div>

        <div class="buttonDiv">
          <button *ngIf="isLast" title="Dieses Szenario löschen" mat-button mat-dialog-close
                  (click)="deleteLastEvent()">
            <i class="material-icons deleteIcon"
               i18n="dialog scenarioInfoDelete|Delete specific scenario@@scenarioInfoDelete">delete</i>
          </button>
        </div>

      </div>

    </mat-expansion-panel>

  </form>
</div>
