<div (click)="disabledCondition ? dataService.formIcon(): null ">
  <button (click)="fireEvent()" mat-stroked-button [mat-dialog-close]='true' [disabled]="disabledCondition"
          *ngIf="dialog">
    <mat-icon *ngIf="!disabledCondition && dialog">arrow_forward</mat-icon>
    <span i18n="save|save changes@@pensionierungSave"> Speichern</span>
  </button>
  <button (click)="fireEvent()" mat-stroked-button [disabled]="disabledCondition" *ngIf="!dialog">
    <mat-icon *ngIf="!disabledCondition">arrow_forward</mat-icon>
    <span i18n="save|save changes@@pensionierungSave"> Speichern</span>
  </button>
  <app-form-icon [form]="form"></app-form-icon>
</div>
