import {NativeDateAdapter} from '@angular/material';
import {Injectable} from '@angular/core';

const SUPPORTS_INTL_API = typeof Intl !== 'undefined';

@Injectable({
  providedIn: 'root'
})
export class DateAdapterService extends NativeDateAdapter {
  useUtcForDisplay = true;

  parse(value: any): Date | null {
    // if formatted with '-'
    if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
      const str = value.split('-');
      let year: number;
      let date: number;
      // determine if yyyy.mm.dd or dd.mm.yyyy or else it is wrong
      if (str[2].length === 4) {
        year = Number(str[2]);
        date = Number(str[0]);
      } else if (str[2].length === 2) {
        year = Number(str[0]);
        date = Number(str[2]);
      }
      const month = Number(str[1]) - 1;
      if (!this.validateDate(year, month, date)) {
        return null;
      }
      return new Date(year, month, date);
      // if formatted with .
    } else if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
      const str = value.split('.');
      let year: number;
      let date: number;
      // determine if yyyy.mm.dd or dd.mm.yyyy or else it is wrong
      if (str[2]) {
        if (str[2].length === 4) {
          year = Number(str[2]);
          date = Number(str[0]);
        } else if (str[2].length === 2) {
          year = Number(str[0]);
          date = Number(str[2]);
        } else {
          return null;
        }
      }
      const month = Number(str[1]) - 1;
      if (!this.validateDate(year, month, date)) {
        return null;
      }
      return new Date(year, month, date);
      // if enter year only
    } else if (typeof value === 'string' && value.length === 4) {
      return new Date(value);
      // if enter 2 digits (year)
    } else if (typeof value === 'string' && value.length === 2) {
      // if year > 50 compare with next hundred year e.g. 2078 and enter 50, compare 2100 and 2000 instead of 2000 and 1900
      const currentYear = new Date().getFullYear();
      let currentCentury = Number(currentYear.toString().substring(0, 2));
      let closeCentury: number;
      if (Number(currentYear.toString().substring(2, 4)) < 50) {
        closeCentury = Number(currentCentury) - 1;
      } else {
        closeCentury = Number(currentCentury) + 1;
      }
      if (Math.abs(currentYear - (currentCentury * 100 + Number(value)))
        < Math.abs(currentYear - (closeCentury * 100 + Number(value)))) {
        // todo make dynamic (not 1900)
        currentCentury = currentCentury * 100;
        value = currentCentury + Number(value);
        return new Date(value, 1, 1);
      } else {
        closeCentury = closeCentury * 100;
        value = closeCentury + Number(value);
        return new Date(value, 1, 1);
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  private validateDate(year, month, day) {
    const date = new Date(year, month, day);
    if (date.getFullYear() !== year) {
      return false;
    }
    if (date.getMonth() !== month) {
      return false;
    }
    if (date.getDate() !== day) {
      return false;
    }
    if (month < 0 || month > 12) {
      return false;
    } else if (day < 0 || day > 31) {
      return false;
    }
    return true;
  }
}

